
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import "../cssfiles/Aboutus.css";
const Aboutus = () =>{
    return(
        <>     
{/* pagte titile section start  */}
<section className="page_title_mkp_section">
  <div className="page_ti_overly_mkp_div">
    <div className="container">
      <div className="row">
        {/* col start */}
        <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
          <div className="page_title_tax_mkp_main_div">
            <div className="page_title_tax_mkp_div">
              <h1>About Us</h1>
              <ul>
                <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
                <li><img src="../icons/aboutus.png" alt="home" className='img-fluid headericonimg'/>  About Us</li>
              </ul>
            </div>
          </div>
        </div>
        {/* col end */}
      </div>
    </div>
  </div>
</section>
{/* pagte titile section end */}

        
   {/* about section start */}
   <section className="aboutus_section mt-3">
   
   {/* container start */}
   <div className="container">
    
     {/* row start */}
     <div className="row">
       {/* col start */}
       <div className="col-lg-5 col-md-6 col-sm-12 col-12">
         <div className="about_page_luxry_img_box">
           <img src="../images/aboutpageimg.jpg" alt="image" className='img-fluid'/>
         </div>
       </div>
       {/* col end */}

         {/* col start */}
         <div className="col-lg-7 col-md-6 col-sm-12 col-12">
         <div className="about_luxry_room_txt">
          <h3>About Us</h3>
          <p>Welcome to the most beautiful stay in Darien Resort- the essence of the name is collected from the Old Persian roots. With the help of our resort and rest, you can find hospitality and the most excellent guide when you assure your stay at the wilderness of the Corbett National Park. With the help of our expert staff, you can feel the warmth that is welcomed from our side to our guests because they are our top priority. Darien is highly rated for its uber quality and is a hidden gem nestled with the finest of services from only the top. Blended with the wilderness of the Jim Corbett National Park, Darien Resort presents an aesthetical landscape for the stayers.</p>
          <p>The breathtaking view that you can engage in within Darien Resort is fantastic. We offer the finest of service and rooms to our customers so that they can make the most of their stay.</p>
          <p>At Darien Resort, we don’t compromise with the leisure and the fun-filled activities provided from our end. Our services are accommodated for our customers to have the maximum of experience that they need to obtain. We believe in the idea of wellness tourism and hospitality to help and enhance the quality of stay for our customers.</p>
         </div>
       
       </div>
       {/* col end */}

     </div>
     {/* row end */}

       {/* nested row start */}
       <div className="row mt-3">
           {/* nested col start */}
           <div className="col-lg-4 col-md-4 col-sm-12 col-12">
             <div className="about_count_div_main">
               <h4>100%</h4>
               <p>Client Satisfaction</p>
             </div>
           </div>
           {/* nested col end */}

           {/* nested col start */}
           <div className="col-lg-4 col-md-4 col-sm-12 col-12">
             <div className="about_count_div_main">
               <h4>03+ </h4>
               <p>Wining Awards</p>
             </div>
           </div>
           {/* nested col end */}


           {/* nested col start */}
           <div className="col-lg-4 col-md-4 col-sm-12 col-12">
             <div className="about_count_div_main">
               <h4>25+</h4>
               <p>Pro Team Members</p>
             </div>
           </div>
           {/* nested col end */}
         </div>
         {/* nested row end */}

   </div>
   {/* container end  */}
  </section>
  {/* about section end */}
     


   {/* main div start hotel services start */}
   <section className="_page">
    {/* container start */}
    <div className="container">
      {/* hotel service title start */}
      <div className="service_title_hotel_div">
        <h1>Rave Reviews  <span>from Our  Satisfied Guests</span></h1>
      </div>
      {/* hotel service title end */}
      {/* row start */}
      <div className="row">

        {/* col start */}
        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
          {/* main div hotel services div start */}
          <div className="main_div_hotel_services main_div_hotel_services2">
            <div className="hotel_service_icon_box">
              <img src="../icons/review01.png" alt="icon" className='img-fluid'/>
            </div>
            <h2>Unmatched Coastal Beauty </h2>
            <p>Located in a picturesque setting, Seapearl offers breathtaking views and direct beach access, providing guests with a serene and rejuvenating escape.</p>
          </div>
          {/* main div hotel services div end */}
        </div>
        {/* col end */}

         {/* col start */}
         <div className="col-lg-4 col-md-6 col-sm-12 col-12">
          {/* main div hotel services div start */}
          <div className="main_div_hotel_services main_div_hotel_services2">
            <div className="hotel_service_icon_box">
              <img src="../icons/review02.png" alt="icon" className='img-fluid'/>
            </div>
            <h2>Luxurious Accommodations</h2>
            <p>Our resort boasts opulent rooms and suites, meticulously designed to provide comfort, style, and relaxation, ensuring a memorable stay for every guest.</p>
          </div>
          {/* main div hotel services div end */}
        </div>
        {/* col end */}

         {/* col start */}
         <div className="col-lg-4 col-md-6 col-sm-12 col-12">
          {/* main div hotel services div start */}
          <div className="main_div_hotel_services main_div_hotel_services2">
            <div className="hotel_service_icon_box">
              <img src="../icons/review03.png" alt="icon" className='img-fluid'/>
            </div>
            <h2>World-Class Amenities</h2>
            <p>Seapearl offers a range of amenities for every guest, including a spa, fitness center, infinity pool, and conference facilities, ensuring a well-rounded experience.</p>
          </div>
          {/* main div hotel services div end */}
        </div>
        {/* col end */}


         {/* col start */}
         <div className="col-lg-4 col-md-6 col-sm-12 col-12">
          {/* main div hotel services div start */}
          <div className="main_div_hotel_services main_div_hotel_services2">
            <div className="hotel_service_icon_box">
              <img src="../icons/review04.png" alt="icon" className='img-fluid'/>
            </div>
            <h2>Impeccable Service</h2>
            <p>Our dedicated staff is committed to providing personalized service and ensuring that every aspect of your stay exceeds expectations, from check-in to check-out.</p>
          </div>
          {/* main div hotel services div end */}
        </div>
        {/* col end */}


         {/* col start */}
         <div className="col-lg-4 col-md-6 col-sm-12 col-12">
          {/* main div hotel services div start */}
          <div className="main_div_hotel_services main_div_hotel_services2">
            <div className="hotel_service_icon_box">
              <img src="../icons/review05.png" alt="icon" className='img-fluid'/>
            </div>
            <h2>Seamless Event Planning</h2>
            <p>Whether you're hosting a corporate event, wedding, or special celebration, our experienced event planning team will work closely with you to create a flawless and unforgettable experience.</p>
          </div>
          {/* main div hotel services div end */}
        </div>
        {/* col end */}


         {/* col start */}
         <div className="col-lg-4 col-md-6 col-sm-12 col-12">
          {/* main div hotel services div start */}
          <div className="main_div_hotel_services main_div_hotel_services2">
            <div className="hotel_service_icon_box">
              <img src="../icons/review06.png" alt="icon" className='img-fluid'/>
            </div>
            <h2>Exceptional Value</h2>
            <p>With competitive rates and special packages, Seapearl provides exceptional value for guests seeking luxury accommodations and unparalleled experiences.</p>
          </div>
          {/* main div hotel services div end */}
        </div>
        {/* col end */}
      </div>
      {/* row end */}
    </div>
    {/* container end */}
   </section>
   {/* main div start hotel services end */}


        </>
    )
}
export default Aboutus;

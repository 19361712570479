import React from 'react'
import { Link } from 'react-router-dom';

const Poolparty = () => {
  return (
    <>
      {/* pagte titile section start  */}
<section className="page_title_mkp_section page_title_mkp_section3">
  <div className="page_ti_overly_mkp_div">
    <div className="container">
      <div className="row">
        {/* col start */}
        <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
          <div className="page_title_tax_mkp_main_div">
            <div className="page_title_tax_mkp_div">
              <h1>Pool Party</h1>
              <ul>
                <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
                <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/>  Pool Party</li>
              </ul>
            </div>
          </div>
        </div>
        {/* col end */}
      </div>
    </div>
  </div>
</section>
{/* pagte titile section end */}


{/* accommodation start section */}
<section className="accommodation_section">
  {/* container start */}
  <div className="container">
    {/* row start */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_img_box_main">
          <img src="../images/pool01.avif" alt="accommodation" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

        {/* col start */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_text_box_main">
          <h1>Pool Party</h1>
          <p>A pool party is a fun and casual social event that typically takes place around or in a pool. It's a popular gathering for friends, family, or colleagues, offering a chance to relax, enjoy the water, and have a good time in a vibrant, festive atmosphere. Whether it's for a birthday, a holiday, or just a summer get-together, pool parties offer plenty of opportunities for fun, games, and socializing.</p>

          <h2>Key Elements of a Successful Pool Party:</h2>
          <p><b>1. Invitations:</b></p>
          <ul>
            <li>The guest list can range from a few close friends to a larger group depending on the size of the pool and venue.</li>
            <li>Invitations can be sent digitally (via email or social media) or as physical invites if you're looking for something more creative.</li>
          </ul>

         <p><b>2. Theme and Decorations:</b></p>
         <p>You can have a theme to make the event more fun and cohesive. Some popular pool party themes include:  </p>

        
        </div>
      </div>
      {/* col end */}

      
        {/* col start */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_text_box_main">
        <ul>
            <li><b>Tropical Luau:</b> Think Hawaiian shirts, tiki torches, leis, and coconuts.</li>
            <li><b>Hollywood Glam:</b> Red carpet, gold decorations, and elegant cocktails.            </li>
            <li><b>Under the Sea:</b> Mermaid or ocean-themed decorations, with blues and greens.</li>
         </ul>
         <p>Decorations can include floating pool toys, balloons, string lights, banners, and colorful tablecloths. </p>

         <p><b>3. Food and Drinks:</b></p>

         <ul>
            <li><b>Snacks:</b> Light snacks such as chips, fruits, and finger foods work well. Consider a snack table with easy-to-eat items like sliders, guacamole, and veggie platters.</li>
            <li><b>BBQ or Grilled Foods:</b> A grill can be used for burgers, hot dogs, or kebabs, making for a relaxed and tasty outdoor meal.</li>
            <li><b>Drinks:</b> Offer refreshing drinks like lemonade, iced tea, and cocktails (for adults). Have water readily available to keep everyone hydrated. </li>
            <li><b>Poolside Bar:</b> Set up a poolside bar area with fun cocktails or mocktails, especially if you have a themed event.</li>
         </ul>
        </div>
      </div>
      {/* col end */}

      {/* col start */}
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_img_box_main">
          <img src="../images/pool02.jpg" alt="accommodation" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

    </div>
    {/* row end */}
  </div>
  {/* container end */}
</section>
{/* accommodation end section */}

    </>
  )
}

export default Poolparty;

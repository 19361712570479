import React from 'react'
import { Link } from 'react-router-dom';

const Yogameditation = () => {
  return (
    <>
    {/* pagte titile section start  */}
<section className="page_title_mkp_section page_title_mkp_section3">
<div className="page_ti_overly_mkp_div">
 <div className="container">
   <div className="row">
     {/* col start */}
     <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
       <div className="page_title_tax_mkp_main_div">
         <div className="page_title_tax_mkp_div">
           <h1>Yoga Meditation</h1>
           <ul>
             <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
             <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/> Yoga Meditation</li>
           </ul>
         </div>
       </div>
     </div>
     {/* col end */}
   </div>
 </div>
</div>
</section>
{/* pagte titile section end */}


{/* accommodation start section */}
<section className="accommodation_section">
{/* container start */}
<div className="container">
 {/* row start */}
 <div className="row">
   {/* col start */}
   <div className="col-lg-6 col-md-12 col-sm-12 col-12">
     <div className="accommodation_img_box_main">
       <img src="../images/bigsizepool.jpg" alt="accommodation" className="img-fluid"/>
     </div>
   </div>
   {/* col end */}

     {/* col start */}
     <div className="col-lg-6 col-md-12 col-sm-12 col-12">
     <div className="accommodation_text_box_main">
       <h1>Yoga Meditation</h1>
       <p>Yoga meditation is a practice that blends physical postures and movements of yoga with the focused, calming techniques of meditation. It aims to achieve a balanced connection between the mind, body, and spirit, fostering relaxation, mindfulness, and inner peace. The practice often involves a combination of breathing exercises (pranayama), physical poses (asanas), and mental concentration, leading to an enhanced sense of well-being.</p>
       
       <p>Yoga meditation helps reduce stress and anxiety, promotes mental clarity, and improves physical health by increasing flexibility, strength, and posture. The mindfulness cultivated during this practice enhances self-awareness and emotional balance. Typically, yoga meditation sessions begin with physical asanas to prepare the body and calm the mind, followed by a period of meditation where practitioners focus on their breath or a specific mantra.</p>
       <p>The practice often concludes with a period of deep relaxation, such as Savasana, to fully absorb the benefits of the session. Overall, yoga meditation is a holistic approach to enhancing mental, physical, and emotional health, promoting a deep sense of tranquility and balance.</p>

     </div>
   </div>
   {/* col end */}


 </div>
 {/* row end */}
</div>
{/* container end */}
</section>
{/* accommodation end section */}

 </>
 
  )
}

export default Yogameditation;

import React from 'react'
import { Link } from 'react-router-dom';

const Baratirauwaterfall = () => {
  return (
    <>
               {/* pagte titile section start  */}
        <section className="page_title_mkp_section page_title_mkp_section3">
          <div className="page_ti_overly_mkp_div">
            <div className="container">
              <div className="row">
                {/* col start */}
                <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
                  <div className="page_title_tax_mkp_main_div">
                    <div className="page_title_tax_mkp_div">
                      <h1>Barati Rau Waterfall</h1>
                      <ul>
                        <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
                        <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/> Barati Rau Waterfall</li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* col end */}
              </div>
            </div>
          </div>
        </section>
        {/* pagte titile section end */}
        
        
        {/* accommodation start section */}
        <section className="accommodation_section">
          {/* container start */}
          <div className="container">
            {/* row start */}
            <div className="row">
              {/* col start */}
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="accommodation_img_box_main">
                  <img src="../images/barati01.jpg" alt="accommodation" className="img-fluid"/>
                </div>
              </div>
              {/* col end */}
        
                {/* col start */}
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="accommodation_text_box_main">
                  <h1>Barati Rau Waterfall</h1>
                  <p>The Barati Rau Waterfall is relatively less known compared to some of the more famous waterfalls in India, making it a great spot for those who enjoy offbeat destinations. The waterfall is nestled in a remote location, which contributes to its pristine and untouched charm.</p>
        
                 <p>The journey to the waterfall can be a bit challenging due to the rugged terrain and dense forests, so it’s ideal for adventure lovers who are up for a little exploration. While trekking through the forest, you may come across various species of flora and fauna, including local wildlife, which adds to the experience. The waterfall itself has a tranquil ambiance, making it an excellent spot for picnics or simply soaking in the beauty of nature.</p>

                 <p>For those planning a trip, the best time to visit would be during the monsoon (June to September) when the waterfall is in full flow, though the area may be a bit slippery. However, the lush green surroundings during the monsoon make it even more picturesque. If you prefer a quieter experience, visiting post-monsoon (October to December) can offer clear skies and a peaceful atmosphere.</p>
        
                </div>
              </div>
              {/* col end */}
        
              
               
        
            </div>
            {/* row end */}
          </div>
          {/* container end */}
        </section>
        {/* accommodation end section */}
        
            </>
  )
}

export default Baratirauwaterfall;

import React from 'react'
import { Link } from 'react-router-dom'

const Riverside = () => {
  return (
    <>
        {/* pagte titile section start  */}
<section className="page_title_mkp_section page_title_mkp_section3">
  <div className="page_ti_overly_mkp_div">
    <div className="container">
      <div className="row">
        {/* col start */}
        <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
          <div className="page_title_tax_mkp_main_div">
            <div className="page_title_tax_mkp_div">
              <h1>River Side</h1>
              <ul>
                <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
                <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/> River Side</li>
              </ul>
            </div>
          </div>
        </div>
        {/* col end */}
      </div>
    </div>
  </div>
</section>
{/* pagte titile section end */}


{/* accommodation start section */}
<section className="accommodation_section">
  {/* container start */}
  <div className="container">
    {/* row start */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_img_box_main">
          <img src="../images/river01.jpg" alt="accommodation" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

        {/* col start */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_text_box_main">
          <h1>River Side</h1>
          <p>A river side destination refers to an area situated along the banks of a river, offering picturesque views, serene environments, and opportunities for outdoor activities. River-side locations are known for their tranquility, natural beauty, and the soothing sound of flowing water, making them perfect for vacations, relaxation, and adventure. These places often attract nature lovers, photographers, and those looking for a peaceful retreat.</p>

          <h2>Key Attractions and Activities at a River Side Destination: </h2>
          <p><b>1. Boating and Rafting:</b></p>
          <ul>
           <li>Rivers are often ideal spots for boating and rafting. Whether it’s a peaceful canoe ride, kayaking, or adrenaline-pumping white-water rafting, these activities offer an exciting way to explore the river and its surroundings.           </li>
           <li>Popular locations for river rafting include Rishikesh (Ganga River), Alaknanda River, and Tungabhadra River.           </li>
          
          </ul>

        </div>
      </div>
      {/* col end */}

      
        {/* col start */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_text_box_main">
     
      <p><b>2. Nature Walks and Treks:</b></p>
      <ul>
        <li>Walking or hiking along the riverbanks is a great way to connect with nature. The peaceful ambiance of the river, combined with lush greenery and wildlife, makes it perfect for a nature walk or a photographic trek.        </li>
        <li>Some riversides offer trekking trails that lead to scenic viewpoints or remote areas of the river, providing opportunities to explore the landscape more intimately.        </li>
      </ul>

      <p><b>3. Picnics and Relaxation:</b></p>
      <ul>
        <li>A riverbank is a great location for picnics and relaxation. Many people visit riversides to enjoy a peaceful day with family or friends, sitting by the river, reading a book, or simply enjoying the sound of water.        </li>
        <li>Lakeside picnics by rivers can be an enchanting experience, often complemented by local wildlife and plant life.        </li>
      </ul>

      <p><b>4. Wildlife Watching:</b></p>
      <ul>
        <li>Riversides often support diverse ecosystems and are home to various species of animals and birds. Depending on the location, you can spot river otters, crocodiles, water buffaloes, and a wide variety of birds, including migratory species.        </li>
        <li>Birdwatching is particularly popular at riversides, where species like kingfishers, herons, and swans are commonly found.        </li>
      </ul>
        </div>
      </div>
      {/* col end */}

      {/* col start */}
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_img_box_main">
          <img src="../images/river02.webp" alt="accommodation" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

    </div>
    {/* row end */}
  </div>
  {/* container end */}
</section>
{/* accommodation end section */}

    </>
  )
}

export default Riverside

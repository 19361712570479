import React from 'react'
import { Link } from 'react-router-dom';

const Sidescenes = () => {
  return (
    <>
      {/* pagte titile section start  */}
<section className="page_title_mkp_section page_title_mkp_section3">
  <div className="page_ti_overly_mkp_div">
    <div className="container">
      <div className="row">
        {/* col start */}
        <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
          <div className="page_title_tax_mkp_main_div">
            <div className="page_title_tax_mkp_div">
              <h1>Side Scene</h1>
              <ul>
                <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
                <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/> Side Scene</li>
              </ul>
            </div>
          </div>
        </div>
        {/* col end */}
      </div>
    </div>
  </div>
</section>
{/* pagte titile section end */}


{/* accommodation start section */}
<section className="accommodation_section">
  {/* container start */}
  <div className="container">
    {/* row start */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_img_box_main">
          <img src="../images/side01.jpg" alt="accommodation" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

        {/* col start */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_text_box_main">
          <h1>Side Scene</h1>
          <p>A side scene typically refers to a view or landscape seen from the side, often providing a different or complementary perspective to the main view or subject. It can be a subtle yet significant visual aspect that enhances the overall experience or understanding of a location, object, or event. Side scenes often showcase the natural or architectural details that aren't always immediately in focus but contribute to the overall setting, offering a broader, more layered view.</p>
          <p>In terms of landscapes, a side scene might reveal elements like rolling hills in the distance, the edges of a forest, or a glimpse of the sky framing the horizon. For example, standing on the side of a mountain trail, you might see a cascading waterfall from the corner of your eye or a small patch of colorful wildflowers beside you, adding richness to the primary view.</p>
          <p>Side scenes can also be important in urban environments, offering perspectives of buildings, streets, and surroundings that are not at the center of attention but still contribute to the city’s atmosphere. These scenes can provide context, depth, and texture to the main image or experience, creating a fuller, more dynamic representation of a place or moment. Whether it's the side of a cliff, a quiet corner of a bustling city, or the edge of a field, side scenes invite observers to look closer and discover hidden or overlooked details.</p>

        </div>
      </div>
      {/* col end */}

      
    </div>
    {/* row end */}
  </div>
  {/* container end */}
</section>
{/* accommodation end section */}
  
    </>
  )
}

export default Sidescenes;

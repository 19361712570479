import React from 'react'
import { Link } from 'react-router-dom';

const Sunraystherapy = () => {
  return (
    <>
    {/* pagte titile section start  */}
<section className="page_title_mkp_section page_title_mkp_section3">
<div className="page_ti_overly_mkp_div">
 <div className="container">
   <div className="row">
     {/* col start */}
     <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
       <div className="page_title_tax_mkp_main_div">
         <div className="page_title_tax_mkp_div">
           <h1>Sun Rays Therapy</h1>
           <ul>
             <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
             <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/> Sun Rays Therapy</li>
           </ul>
         </div>
       </div>
     </div>
     {/* col end */}
   </div>
 </div>
</div>
</section>
{/* pagte titile section end */}


{/* accommodation start section */}
<section className="accommodation_section">
{/* container start */}
<div className="container">
 {/* row start */}
 <div className="row">
   {/* col start */}
   <div className="col-lg-6 col-md-12 col-sm-12 col-12">
     <div className="accommodation_img_box_main">
       <img src="../images/bigsizepool.jpg" alt="accommodation" className="img-fluid"/>
     </div>
   </div>
   {/* col end */}

     {/* col start */}
     <div className="col-lg-6 col-md-12 col-sm-12 col-12">
     <div className="accommodation_text_box_main">
       <h1>Sun Rays Therapy</h1>
     <p>Sun Rays Therapy is a natural treatment method that involves exposure to sunlight to promote healing and improve physical and mental health. It leverages the sun's rays to stimulate the body's production of vitamin D, which is vital for bone health, immune system function, and overall well-being. Sunlight exposure has been shown to enhance mood by increasing serotonin levels in the brain, which can help alleviate conditions like depression, anxiety, and Seasonal Affective Disorder (SAD).</p>
     <p>This therapy is also known to regulate sleep patterns by influencing the circadian rhythm, promoting better sleep quality. Controlled sun exposure has been used to treat certain skin conditions such as psoriasis and eczema, as sunlight can reduce inflammation and promote healing. Additionally, it supports the immune system by increasing the production of white blood cells that help defend against infections.</p>
     <p>While sun rays therapy offers numerous benefits, it is crucial to practice it in moderation, as excessive sun exposure can lead to skin damage, premature aging, and an increased risk of skin cancer. Using sun protection such as sunscreen or protective clothing is essential to safely reap the therapeutic benefits of sunlight.</p>
     </div>
   </div>
   {/* col end */}


 </div>
 {/* row end */}
</div>
{/* container end */}
</section>
{/* accommodation end section */}

 </>
 
  )
}

export default Sunraystherapy;

import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
import videoSrc from "../assets/banner/thedarien.mp4";
import videoSrc2 from "../assets/banner/darienvideo.mp4";
import { eventsslider } from '../data/Owlcarouseloptions';
const Home = () => {

  return (
    <>
     {/* main wrapper div start */}
     <div className="main_div_wrapper">
      {/* === slider section start ==== */}
      <section className="heroslider_section">
      <video width="100%" loop muted autoPlay>
     <source src={videoSrc} type="video/mp4"/>
     </video>
      </section>
      {/* === slider section end ==== */}
     </div>
   {/* main wrapper div end */}


   {/* Where Luxury Meets Responsibility div start */}
   <section className="luxury_meets_responsibility_div">
    {/* container start */}
    <div className="container">
      {/* row start */}
      <div className="row justify-content-center">
        {/* col start */}
        <div className="col-lg-8 col-md-12 col-sm-12 col-12">
          {/* main div where luxury div start */}
          <div className="main_div_where_luxury_meets_responsibility">
            <h2>Where Luxury Meets Responsibility</h2>
            <p>Welcome to The Darien Resort, a haven of luxury for those who cherish nature and wildlife. Situated on 13.5 acres of vibrant greenery, with a boundary adjoining the Bijrani Zone of Jim Corbett National Park, we are proud to be home to over 200 bird species. With our organic farm-to-table dining experience and a strong commitment to eco-friendly practices, which have garnered us multiple accolades, we proudly stand as a true advocate for sustainable tourism.</p>
            


          </div>
          {/* main div where luxury div end */}
        </div>
        {/* col end */}
      </div>
      {/* row end */}
    </div>
    {/* container end */}
   </section>
   {/* Where Luxury Meets Responsibility div end */}

   {/* Family pool villa section start */}
   <section className="family_pool_villa_section">
    {/* container start */}
    <div className="container">
      {/* row start */}
      <div className="row justify-content-center align-items-center">
{/* col start */}
<div className="col-lg-5 col-md-6 col-sm-12 col-12">
  {/* family pool villa main div start */}
  <div className="family_pool_villa_main_div">
    <h2>Family pool villa </h2>
    <p>Step into the world of luxury living at our new Private Pool Villas, nestled within the lush green beauty of Jim Corbett National Park.</p>
    <div className="explor_btn_div">
      <Link to="#">View More</Link>
    </div>
  </div>
  {/* family pool villa main div end */}
</div>
{/* col end */}


{/* col start */}
<div className="col-lg-5 col-md-6 col-sm-12 col-12">
  {/* family pool villa video main div start */}
  <div className="family_pool_villa_video_main_div">
  <video width="100%" loop muted autoPlay>
     <source src={videoSrc2} type="video/mp4"/>
     </video>
  </div>
  {/* family pool villa video main div end */}
</div>
{/* col end */}

      </div>
      {/* row end */}
    </div>
    {/* container end */}
   </section>
   {/* Family pool villa section end */}

   {/* Commitment to Sustainability section start */}
   <section className="commitment_to_sustainability_section">
    {/* container start */}
    <div className="container">
      {/* commitment tilte start */}
      <div className="commitment_title_div">
        <h3>Commitment to Sustainability</h3>
      </div>
      {/* commitment tilte end */}
      {/* row start */}
      <div className="row">

        {/* col start */}
        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
          {/* commitment box main div start */}
          <div className="commitment_box_main_div">
            {/* commit img box div start */}
            <div className="commit_img_box">
              <img src="../images/cleanliness.png" alt="" className='img-fluid'/>
            </div>
            {/* commit img box div end */}
            <div className="commit_content_div">
            <h4>cleanliness Drives in Savaldeh </h4>
            <p>For the last 8 years, we have worked alongside the people, especially, the children in the village to lead cleanliness drives. Every month, together, we learn the dignity.</p>
            <div className="commit_btn_div">
              <Link to="">Learn More  <span className='fa fa-angle-double-right'></span></Link>
            </div>
          </div>
          </div>
          {/* commitment box main div end */}
        </div>
        {/* col end */}

        
        {/* col start */}
        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
          {/* commitment box main div start */}
          <div className="commitment_box_main_div">
            {/* commit img box div start */}
            <div className="commit_img_box">
              <img src="../images/staff.jpg" alt="" className='img-fluid'/>
            </div>
            {/* commit img box div end */}
            <div className="commit_content_div">
            <h4>Local Staff </h4>
            <p>Most of us at The Darien Resort come from nearby villages or from the region-- 95% of the staff is local. For us growth is intertwined with the flourishing of the entire community.</p>
            <div className="commit_btn_div">
              <Link to="">Learn More  <span className='fa fa-angle-double-right'></span></Link>
            </div>
          </div>
          </div>
          {/* commitment box main div end */}
        </div>
        {/* col end */}


        
        {/* col start */}
        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
          {/* commitment box main div start */}
          <div className="commitment_box_main_div">
            {/* commit img box div start */}
            <div className="commit_img_box">
              <img src="../images/organic.avif" alt="" className='img-fluid'/>
            </div>
            {/* commit img box div end */}
             {/* commit content div start */}
           <div className="commit_content_div">
            <h4>Organic Sewage Treatment </h4>
            <p>We recycle 100% waste water through a natural process called Root Zone Treatment, using Canna Plant. Our method is free of chemical use, and requires no energy to operate.</p>
            <div className="commit_btn_div">
              <Link to="">Learn More  <span className='fa fa-angle-double-right'></span></Link>
            </div>
            </div>
          </div>
          {/* commitment box main div end */}
        </div>
        {/* col end */}

      </div>
      {/* row end */}
    </div>
    {/* container end */}
   </section>
   {/* Commitment to Sustainability section end */}


   
{/* events slider main div start */}
<section className="events_slider_main_div">
  
  {/* title div start */}
  <div className="events_slider_title_div d-none">
    <h2>Events</h2>
  </div>
  {/* title div end */}

  {/* owl carusol start */}
<OwlCarousel className="owl-theme" {...eventsslider}>
          {/* item start */}
        <div className="item">
           {/* events slider img main div start */}
           <div className="events_slider_img_main_div">
            {/* slider img box start */}
            <div className="events_slider_img_box">
              <img src="../images/event01.jpg" alt="" className='img-fluid'/>
            </div>
            {/* slider img box end */}
           <div className="evnets_name_div_start">
           <h3>Destination Wedding</h3>
           </div>
           </div>
           {/* events slider img main div end */}
          </div>
           {/* item end */}

            {/* item start */}
        <div className="item">
           {/* events slider img main div start */}
           <div className="events_slider_img_main_div">
            {/* slider img box start */}
            <div className="events_slider_img_box">
              <img src="../images/event02.jpg" alt="" className='img-fluid'/>
            </div>
            {/* slider img box end */}
            <div className="evnets_name_div_start">
            <h3>Corporate Meeting or Events </h3>
           </div>
           </div>
           {/* events slider img main div end */}
          </div>
           {/* item end */}


            {/* item start */}
        <div className="item">
           {/* events slider img main div start */}
           <div className="events_slider_img_main_div">
            {/* slider img box start */}
            <div className="events_slider_img_box">
              <img src="../images/aboutpageimg.jpg" alt="" className='img-fluid'/>
            </div>
            {/* slider img box end */}
            <div className="evnets_name_div_start">
            <h3>Pool Party</h3>
           </div>
           </div>
           {/* events slider img main div end */}
          </div>
           {/* item end */}

            {/* item start */}
        <div className="item">
           {/* events slider img main div start */}
           <div className="events_slider_img_main_div">
            {/* slider img box start */}
            <div className="events_slider_img_box">
              <img src="../images/event03.jpg" alt="" className='img-fluid'/>
            </div>
            {/* slider img box end */}
            <div className="evnets_name_div_start">
            <h3>College Group Events</h3>
           </div>
           </div>
           {/* events slider img main div end */}
          </div>
           {/* item end */}


            {/* item start */}
        <div className="item">
           {/* events slider img main div start */}
           <div className="events_slider_img_main_div">
            {/* slider img box start */}
            <div className="events_slider_img_box">
              <img src="../images/event04.avif" alt="" className='img-fluid'/>
            </div>
            {/* slider img box end */}
            <div className="evnets_name_div_start">
            <h3>School Group Events</h3>
           </div>
           </div>
           {/* events slider img main div end */}
          </div>
           {/* item end */}


        </OwlCarousel>
        {/* owl carusol end */}
</section>

{/* events slider end */}


{/* middle section start */}
<section className="middle_section">
  {/* container start */}
  <div className="container">
    {/* row start */}
    <div className="row">
      


       {/* col start */}
       <div className="col-lg-6 col-md-6 col-sm-12 col-12">
        {/* middle main div start */}
        <div className="middle_main_div">
          {/* middle img box div start */}
          <div className="middle_img_box_div">
            <img src="../images/accomodation.jpg" alt="" className='img-fluid'/>
          </div>
          {/* middle img box div end */}
          {/* middle content div start */}
          <div className="middle_content_div">
            <h2>Luxury Accommodation</h2>
            <p>Find your perfect retreat, curated to deliver the bespoke experience you desire. Immerse yourself in our luxurious rooms, each infused with the timeless charm of Victorian inspiration, ranging from 500 to 6000 square feet.We offer various personalized experiences.</p>

            <div className="middle_btn_div">
              <Link to="">Explore More  <span className='fa fa-angle-double-right'></span></Link>
            </div>
          </div>
          {/* middle content div end */}
        </div>
        {/* middle main div end */}
      </div>
      {/* col end */}


       {/* col start */}
       <div className="col-lg-6 col-md-6 col-sm-12 col-12">
        {/* middle main div start */}
        <div className="middle_main_div">
          {/* middle img box div start */}
          <div className="middle_img_box_div">
            <img src="../images/wedd-en.jpg" alt="" className='img-fluid'/>
          </div>
          {/* middle img box div end */}
          {/* middle content div start */}
          <div className="middle_content_div">
            <h2>Destination Wedding & Events</h2>
            <p>We offer a perfect destination for an exceptional and luxurious wedding experience. Nestled in the heart of Corbett National Park, The Darien Resort provides a picturesque setting for couples to exchange vows and start their new life together amidst the natural splendor. </p>

            <div className="middle_btn_div">
              <Link to="">Explore More  <span className='fa fa-angle-double-right'></span></Link>
            </div>
          </div>
          {/* middle content div end */}
        </div>
        {/* middle main div end */}
      </div>
      {/* col end */}


       {/* col start */}
       <div className="col-lg-6 col-md-6 col-sm-12 col-12">
        {/* middle main div start */}
        <div className="middle_main_div">
          {/* middle img box div start */}
          <div className="middle_img_box_div">
            <img src="../images/exp.jpg" alt="" className='img-fluid'/>
          </div>
          {/* middle img box div end */}
          {/* middle content div start */}
          <div className="middle_content_div">
            <h2>Experiences at The Darien Resort</h2>
            <p>Explore a myriad of experiences both within and beyond the resort. From buggy rides through nearby villages to scenic picnics or birding tours, our tailor-made activities engage guests of all ages. We believe in crafting experiences that are most meaningful to you.</p>

            <div className="middle_btn_div">
              <Link to="">Explore More  <span className='fa fa-angle-double-right'></span></Link>
            </div>
          </div>
          {/* middle content div end */}
        </div>
        {/* middle main div end */}
      </div>
      {/* col end */}

      {/* col start */}
      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
        {/* middle main div start */}
        <div className="middle_main_div">
          {/* middle img box div start */}
          <div className="middle_img_box_div">
            <img src="../images/jim.jpg" alt="" className='img-fluid'/>
          </div>
          {/* middle img box div end */}
          {/* middle content div start */}
          <div className="middle_content_div">
            <h2>Jim Corbett National Park</h2>
            <p>Experience one of India's most renowned and historic wildlife sanctuaries, celebrated for its Bengal tigers, diverse wildlife, and over 600 bird species. Allow us to assist in planning an exciting jungle safari across any of the seven zones of Jim Corbett Park.</p>

            <div className="middle_btn_div">
              <Link to="">Explore More  <span className='fa fa-angle-double-right'></span></Link>
            </div>
          </div>
          {/* middle content div end */}
        </div>
        {/* middle main div end */}
      </div>
      {/* col end */}


    </div>
    {/* row end */}
  </div>
  {/* container end */}
</section>
{/* middle section end */}



   {/* about section start */}
   <section className="aboutus_section">
   
    {/* container start */}
    <div className="container">
       {/* overlay text start */}
    <div className="hotel_name_watermark_div">
      <h4>The Darien Resort</h4>
    </div>
    {/* overlay text end */}
      {/* row start */}
      <div className="row">
        {/* col start */}
        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
          <div className="about_luxry_img_box">
            <img src="../images/luxryimg.jpg" alt="image" className='img-fluid'/>
          </div>
        </div>
        {/* col end */}

         {/* col start */}
         <div className="col-lg-4 col-md-6 col-sm-12 col-12">
          <div className="about_luxry_room_txt">
           <h3>Luxury Room</h3>
           <p>Indulge in the ultimate comfort and sophistication with our Luxury Room at The Darien Resort. Designed for those who appreciate the finer things in life, this exquisite room offers a perfect blend of elegance, space, and modern amenities.</p>
           <p>The Luxury Room features a king-sized bed with premium linens, ensuring a restful night's sleep. A spacious seating area with stylish furnishings provides a tranquil space to unwind, while large windows allow natural light to flood the room and offer stunning views of the surrounding area.</p>
          </div>
        </div>
        {/* col end */}

          {/* col start */}
          <div className="col-lg-5 col-md-12 col-sm-12 col-12">
          <div className="about_luxry_room_txt">
           <h3>About Us</h3>
           <p>Welcome to the most beautiful stay in Darien Resort the essence of the name is collected from the Old Persian roots. With the help of our resort and rest, you can find hospitality and the most excellent guide when you ensure your stay in the wilderness of the Corbett National Park.</p>
          </div>
          {/* nested row start */}
          <div className="row">
            {/* nested col start */}
            <div className="col-lg-4 col-md-4 col-sm-4 col-4">
              <div className="about_count_div_main">
                <h4>100%</h4>
                <p>Client Satisfaction</p>
              </div>
            </div>
            {/* nested col end */}

            {/* nested col start */}
            <div className="col-lg-4 col-md-4 col-sm-4 col-4">
              <div className="about_count_div_main">
                <h4>03+ </h4>
                <p>Wining Awards</p>
              </div>
            </div>
            {/* nested col end */}


            {/* nested col start */}
            <div className="col-lg-4 col-md-4 col-sm-4 col-4">
              <div className="about_count_div_main">
                <h4>25+</h4>
                <p>Pro Team Members</p>
              </div>
            </div>
            {/* nested col end */}
          </div>
          {/* nested row end */}
        </div>
        {/* col end */}

      </div>
      {/* row end */}
    </div>
    {/* container end  */}
   </section>
   {/* about section end */}


   {/* Sightseeing places section start */}
   <section className="sightseeing_places_section">
    {/* container start */}
    <div className="container">

      {/* title start */}
      <div className="sightseeing_title">
        <h2>Sightseeing Places</h2>
      </div>
      {/* title end */}

      {/* row start */}
      <div className="row">
        {/* col start */}
        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
          {/* sightseeing place card main div start */}
          <div className="sightseeing_places_card_div">
            {/*sightseeing images start  */}
            <div className="sightseeing_img_box_div">
              <img src="../images/homeimg01.jpg" alt="" className='img-fluid'/>
            </div>
            {/*sightseeing images end  */}

           {/* sightseeing content div start */}
           <div className="sightseeing_content_div">
           <h4>Garjiya Devi temple</h4>
            <p>Garjiya Devi temple is a renowned Hindu temple located in the Nainital district of Uttarakhand, India. It is dedicated to Goddess Garjiya, a form of Goddess Parvati, who is worshipped here by many devotees.</p>
            <div className="sightseeing_btn_div">
              <Link to="/garjiya-devi-temple">Read More <span className='fa fa-angle-double-right'></span></Link>
            </div>
           </div>
           {/* sightseeing content div end */}

          </div>
          {/* sightseeing place card main div end */}

        </div>
        {/* col end */}

         {/* col start */}
         <div className="col-lg-4 col-md-6 col-sm-12 col-12">
          {/* sightseeing place card main div start */}
          <div className="sightseeing_places_card_div">
            {/*sightseeing images start  */}
            <div className="sightseeing_img_box_div">
              <img src="../images/homeimg02.jpg" alt="" className='img-fluid'/>
            </div>
            {/*sightseeing images end  */}
             {/* sightseeing content div start */}
           <div className="sightseeing_content_div">
            <h4>Hanuman Dham</h4>
            <p>Hanuman Dham is a prominent Hindu temple dedicated to Lord Hanuman, located in Nainital district, Uttarakhand, India. The temple is a significant religious site for devotees of Lord Hanuman.</p>
            <div className="sightseeing_btn_div">
              <Link to="/hanuman-dham">Read More <span className='fa fa-angle-double-right'></span></Link>
            </div>
            </div>
           {/* sightseeing content div end */}
          </div>
          {/* sightseeing place card main div end */}

        </div>
        {/* col end */}


         {/* col start */}
         <div className="col-lg-4 col-md-6 col-sm-12 col-12">
          {/* sightseeing place card main div start */}
          <div className="sightseeing_places_card_div">
            {/*sightseeing images start  */}
            <div className="sightseeing_img_box_div">
              <img src="../images/homeimg03.jpg" alt="" className='img-fluid'/>
            </div>
            {/*sightseeing images end  */}
             {/* sightseeing content div start */}
           <div className="sightseeing_content_div">
            <h4>Corbett Museum</h4>
            <p>Corbett Museum is a prominent museum located in Kaladhungi, a small town near Jim Corbett National Park in Uttarakhand, India. The museum is dedicated to Jim Corbett, one of India’s most famous.</p>
            <div className="sightseeing_btn_div">
              <Link to="/corbett-museum">Read More <span className='fa fa-angle-double-right'></span></Link>
            </div>
            </div>
            {/* sightseeing content div end */}
          </div>
          {/* sightseeing place card main div end */}

        </div>
        {/* col end */}

        
         {/* col start */}
         <div className="col-lg-4 col-md-6 col-sm-12 col-12">
          {/* sightseeing place card main div start */}
          <div className="sightseeing_places_card_div">
            {/*sightseeing images start  */}
            <div className="sightseeing_img_box_div">
              <img src="../images/homeimg04.jpg" alt="" className='img-fluid'/>
            </div>
            {/*sightseeing images end  */}
             {/* sightseeing content div start */}
           <div className="sightseeing_content_div">
            <h4>Corbett Falls</h4>
            <p>Corbett Falls is a picturesque waterfall located in the Jim Corbett National Park in Uttarakhand, India. Situated around 25 km from Ramnagar, the falls are nestled amidst the beautiful sal forests. </p>
            <div className="sightseeing_btn_div">
              <Link to="/corbett-falls">Read More <span className='fa fa-angle-double-right'></span></Link>
            </div>
            </div>
            {/* sightseeing content div end */}
          </div>
          {/* sightseeing place card main div end */}

        </div>
        {/* col end */}


        
         {/* col start */}
         <div className="col-lg-4 col-md-6 col-sm-12 col-12">
          {/* sightseeing place card main div start */}
          <div className="sightseeing_places_card_div">
            {/*sightseeing images start  */}
            <div className="sightseeing_img_box_div">
              <img src="../images/homeimg05.jpg" alt="" className='img-fluid'/>
            </div>
            {/*sightseeing images end  */}
             {/* sightseeing content div start */}
           <div className="sightseeing_content_div">
            <h4>Barati Rau Waterfall</h4>
            <p>The Barati Rau Waterfall is relatively less known compared to some of the more famous waterfalls in India, making it a great spot for those who enjoy offbeat destinations. The waterfall is nestled.</p>
            <div className="sightseeing_btn_div">
              <Link to="/barati-rau-waterfall">Read More <span className='fa fa-angle-double-right'></span></Link>
            </div>
            </div>
            {/* sightseeing content div end */}
          </div>
          {/* sightseeing place card main div end */}

        </div>
        {/* col end */}


        
         {/* col start */}
         <div className="col-lg-4 col-md-6 col-sm-12 col-12">
          {/* sightseeing place card main div start */}
          <div className="sightseeing_places_card_div">
            {/*sightseeing images start  */}
            <div className="sightseeing_img_box_div">
              <img src="../images/homeimg06.jpg" alt="" className='img-fluid'/>
            </div>
            {/*sightseeing images end  */}
             {/* sightseeing content div start */}
           <div className="sightseeing_content_div">
            <h4>Neem Karoli Baba</h4>
            <p>Neem Karoli Baba, also known as Maharaj-ji, was a revered Indian saint and spiritual leader, who gained widespread recognition both in India and the West. He is most famous for his simple yet profound.</p>
            <div className="sightseeing_btn_div">
              <Link to="/neem-karoli-baba">Read More <span className='fa fa-angle-double-right'></span></Link>
            </div>
            </div>
            {/* sightseeing content div end */}
          </div>
          {/* sightseeing place card main div end */}

        </div>
        {/* col end */}


      </div>
      {/* row end */}
    </div>
    {/* container end */}
   </section>
   {/* Sightseeing places section end */}


{/* our room section start */}
<section className="our_rooms_section">
   {/* overlay text start */}
   <div className="hotel_name_fixed_div">
      <h4>The Darien Resort</h4>
    </div>
    {/* overlay text end */}
  {/* container start */}
  <div className="container">
    {/*  */}
    <div className="mobile_our_room_left_text_div">
          <h3>Our Rooms at <span>The Darien</span> Resort</h3>
          <p>At The Darien Resort, we offer a variety of beautifully appointed rooms designed to meet the needs of every guest. Whether you’re here for business, leisure, or a special getaway, our accommodations provide the perfect blend of comfort, style, and convenience.</p>
        </div>
    {/*  */}
    {/* row start */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-4 col-md-12 col-sm-12 col-12 mobileorder02">
        <div className="our_room_left_text_div">
          <h3>Our Rooms <span>at The Darien Resort</span></h3>
          <p>At The Darien Resort, we offer a variety of beautifully appointed rooms designed to meet the needs of every guest. Whether you’re here for business, leisure, or a special getaway, our accommodations provide the perfect blend of comfort, style, and convenience.</p>
        </div>
        {/* why choose us div start */}
        <div className="why_choose_us_main_div">
          <h3>Why Choose Us?</h3>
          <h4>our facilities and hospality</h4>


      <div className="why_choose_us_media_main_div">
        {/* media start */}
      <div class="d-flex">
  <div class="flex-shrink-0">
    <img src="../images/whyicon01.png" alt="icons" className='img-fluid'/>
  </div>
  <div class="flex-grow-1 ms-3">
   <div className="why_choose_us_media_heading">
    <h4>Restaurant</h4>
    <p>Exceptional dining awaits at our resort. Immerse yourself in exquisite  flavors with view to match. Pure indulgence, effortlessly delivered.</p>
   </div>
  </div>
</div>
        {/* media end */}

          {/* media start */}
      <div class="d-flex">
  <div class="flex-shrink-0">
    <img src="../images/whyicon02.png" alt="icons" className='img-fluid'/>
  </div>
  <div class="flex-grow-1 ms-3">
   <div className="why_choose_us_media_heading">
    <h4>Conference Center</h4>
    <p>Stay productive with our well-equipped business event center, offering essential services for business travelers.</p>
   </div>
  </div>
</div>
        {/* media end */}


          {/* media start */}
      <div class="d-flex">
  <div class="flex-shrink-0">
    <img src="../images/whyicon03.png" alt="icons" className='img-fluid'/>
  </div>
  <div class="flex-grow-1 ms-3">
   <div className="why_choose_us_media_heading">
    <h4>Swimming Pool</h4>
    <p>Stay productive with our well-equipped business event center, offering essential services for business travelers.</p>
   </div>
  </div>
</div>
        {/* media end */}

        
          {/* media start */}
      <div class="d-flex">
  <div class="flex-shrink-0">
    <img src="../images/whyicon04.png" alt="icons" className='img-fluid'/>
  </div>
  <div class="flex-grow-1 ms-3">
   <div className="why_choose_us_media_heading">
    <h4>24/7 Room Service</h4>
    <p>Delight in the convenience of round-the-clock room service, catering, Laundry everything to your needs at any hour.</p>
   </div>
  </div>
</div>
        {/* media end */}


      </div>

        </div>
        {/* why choose us div end */}
      </div>
      {/* col end */}


       {/* col start */}
       <div className="col-lg-8 col-md-12 col-sm-12 col-12 mobileorder01">
       {/* nested row start */}
       <div className="row justify-content-centerR">

        {/* col start */}
        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
         <Link to="/executive-room">
         <div className="nested_rooms_main_div">
            <div className="nested_room_img_box">
              <img src="../images/room01.jpg" alt="room" className='img-fluid'/>
            </div>
            
            <div className="nested_room_heading_div">
            <h4>Executive Room</h4>
            <Link to="/contact-us">Book Now</Link>

            </div>
            <p>Designed with the discerning business traveler in mind, our Executive Room offers an elevated level of comfort, style, and functionality.  <Link to="/executive-room">Read More</Link></p>
            <div className="room_icons_nested_div">
              <p><img src="../icons/bed.png" alt="" className='img-fluid'/> 1 bed</p>
              <p><img src="../icons/sleep.png" alt="" className='img-fluid'/> 2 sleeps</p>
              <p><img src="../icons/roomservice01.png" alt="" className='img-fluid'/> room service</p>
            </div>
          </div>
         </Link>
        </div>
        {/* col end */}

        
        {/* col start */}
        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
         <Link to="/superior-room">
         <div className="nested_rooms_main_div">
            <div className="nested_room_img_box">
              <img src="../images/room02.jpg" alt="room" className='img-fluid'/>
            </div>
           
            <div className="nested_room_heading_div">
            <h4>Superior Room</h4>
            <Link to="/contact-us">Book Now</Link>

            </div>
            <p>Elevate your stay with our Superior Room, designed for guests who appreciate extra comfort, space, and style. Offering an enhanced experience.  <Link to="/superior-room">Read More</Link></p>
            <div className="room_icons_nested_div">
              <p><img src="../icons/bed.png" alt="" className='img-fluid'/> 1 bed</p>
              <p><img src="../icons/sleep.png" alt="" className='img-fluid'/> 2 sleeps</p>
              <p><img src="../icons/roomservice01.png" alt="" className='img-fluid'/> room service</p>
            </div>
          </div>
         </Link>
        </div>
        {/* col end */}


        
        {/* col start */}
        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
        <Link to="/presential-room">
        <div className="nested_rooms_main_div">
            <div className="nested_room_img_box">
              <img src="../images/room03.jpg" alt="room" className='img-fluid'/>
            </div>
            <div className="nested_room_heading_div">
            <h4>Presidential Room</h4>
            <Link to="/contact-us">Book Now</Link>

            </div>

            <p>Experience the height of luxury and sophistication in our Presidential Suite, designed for those who seek the finest in comfort, style, and exclusivity.  <Link to="/presential-room">Read More</Link></p>
            <div className="room_icons_nested_div">
              <p><img src="../icons/bed.png" alt="" className='img-fluid'/> 2 bed</p>
              <p><img src="../icons/sleep.png" alt="" className='img-fluid'/> 4 sleeps</p>
              <p><img src="../icons/roomservice01.png" alt="" className='img-fluid'/> room service</p>
            </div>
          </div>
        </Link>
        </div>
        {/* col end */}

         {/* col start */}
         <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-none d-lg-block">
        <Link to="/">
        <div className="nested_rooms_main_divv">
            <div className="nested_room_img_boxx">
              <img src="../images/rm001.avif" alt="room" className='img-fluid'/>
            </div>
           

          </div>
        </Link>
        </div>
        {/* col end */}


        
      

       </div>
       {/* nested row end  */}
      </div>
      {/* col end */}


    </div>
    {/* row end */}
  </div>
  {/* container end */}
</section>
{/* our room section end */}



{/* the darien experince section start */}
<section className="darien_experience_section">
  {/* container start */}
  <div className="container">
    {/* darien experince title start */}
<div className="darien_experince_title">
  <h3>The <span>Darien</span> Experiences</h3>
</div>
    {/* darien experince title end */}
    {/* row start */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-4 col-md-4 col-sm-12 col-12">
        {/* main div start */}
        <div className="darien_ex_main_div">
          <div className="darien_experince_img_box">
            <img src="../images/comfirt.jpg" alt="image" className='img-fluid'/>
          </div>

          <div className="darien_flex_div">
          <h4> <Link to="">Comfortable Stay</Link></h4>
          </div>

          <p>Welcome to the most beautiful stay in Darien Resort - the essence of the name is collected from the Old Persian roots. With the help of our resort and rest, you can find hospitality and the most excellent guide when you assure your stay at the wilderness of the Corbett National Park.  With the help of our expert staff, you can feel the warmth that is welcomed from our side to our guests because they are our top priority.</p>

          <div className="darien_flex_div">
          <h4> <Link to="">Cuisines</Link></h4>
          </div>
          <p>Accommodation of the most beautiful spaces to have a great dining experience, Darien Resort only presents you with the best run. Our top chefs are always improving their skills so that they can get the best experience for the customers we have. Kumaon and Garhwal</p>

       
        </div>
        {/* main div start */}

      </div>
      {/* col end */}

{/* col start */}
<div className="col-lg-8 col-md-8 col-sm-12 col-12">
  {/* nested row start */}
  <div className="row">
{/*nested col start */}
<div className="col-lg-6 col-md-6 col-sm-12 col-12">
        {/* main div start */}
        <div className="darien_ex_main_div darien_ex_main_div2">
        <div className="darien_flex_div">
        <h4> <Link to="/">Jungle Safari</Link></h4>
        </div>
        <p>Have a generous jungle safari with your friends into the wilderness of the Jim Corbett National Park. Jungle safaris are fun because they are filled with the thrill to make sure that you are being pushed towards the unknown. These safaris will not only make your day but at the same time ensure that you have a good and compelling time out.</p>

          <div className="darien_jungle_img_box">
    <img src="../images/jungle02.avif" alt="" className='img-fluid'/>
  </div>

             <div className="darien_flex_div">
          <h5> <Link to="/">Leisure and activities</Link></h5>
  </div>

          <p>At Darien Resort, we don’t compromise with the leisure and the fun-filled activities provided from our end. Our services are accommodated for our customers to have the maximum of experience that they need to obtain. We believe in the idea of wellness tourism and hospitality to help and enhance the quality of stay for our customers.</p>
        </div>
        {/* main div start */}

      </div>
      {/* nested col end */}

      {/*nested col start */}
<div className="col-lg-6 col-md-6 col-sm-12 col-12">
        {/* main div start */}
        <div className="darien_ex_main_div">
        <div className="darien_flex_div">
        <h4> <Link to="/">The Corbett Wilderness Experience</Link></h4>
        </div>
          <p>The Corbett park wilderness is something that can peak your mind and it will help you to relieve and relax since the view is breathtakingly amazing. There are amnesties which are present for our guests so that they can have it all and in the leading way.</p>

          <div className="darien_flex_div">
          <h5> <Link to="/">Explore Uttarakhand</Link></h5>
        </div>

          <p>Exploring Uttarakhand with your special ones will help you to get the zest of this place. This place forms the basic part of North India and is one of the topmost place for your visit in the bucket list. the rich cultural heritage of Uttarakhand is what makes it special and diverts it separately from the said places and this is why you need to visit this place once in your lifetime to check how culturally rich this place is.</p>
          <div className="darien_jungle_img_box">
    <img src="../images/jungle04.avif" alt="" className='img-fluid'/>
  </div>
        </div>
        {/* main div start */}

      </div>
      {/* nested col end */}

         </div>
  {/* nested row start */}

      </div>
      {/* col end */}

    </div>
    {/* row end */}
  </div>
  {/* container end */}
</section>
{/* the darien experince section end */}
  

    </>
  )
}

export default Home;

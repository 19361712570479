import React from 'react'
import { Link } from 'react-router-dom';

const Executiveroom = () => {
  return (
    <>
      {/* pagte titile section start  */}
 <section className="page_title_mkp_section page_title_mkp_section3">
   <div className="page_ti_overly_mkp_div">
     <div className="container">
       <div className="row">
         {/* col start */}
         <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
           <div className="page_title_tax_mkp_main_div">
             <div className="page_title_tax_mkp_div">
               <h1>Execitive Rooms</h1>
               <ul>
                 <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
                 <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/>  Execitive Rooms</li>
               </ul>
             </div>
           </div>
         </div>
         {/* col end */}
       </div>
     </div>
   </div>
 </section>
 {/* pagte titile section end */}


{/* roominner start section */}
<section className="roominner_section">
  {/* container start */}
  <div className="container">
    {/* row start */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-5 col-md-6 col-sm-12 col-12">
        <div className="roominner_img_box_main">
          <img src="../images/room01.jpg" alt="roominner" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

        {/* col start */}
        <div className="col-lg-7 col-md-6 col-sm-12 col-12">
        <div className="roominner_table_box_main">
        <table className="table table-striped table-bordered table-responsive">
            <thead>
              <tr>
                <th colSpan={3}>Execitive Rooms</th>
              </tr>
            </thead>
            <tbody>

              <tr>
              <th>Executive Room (Exc. GST)</th>
                <td>Rs. 5,000/-</td>
                <td><Link to="/contact-us">Book Now</Link></td>
              </tr>


              
             
            </tbody>
          </table>
        </div>

<div className="accommodation_text_box_main">
    <p>Executive Rooms are premium accommodations typically found in hotels, designed to offer a higher level of comfort, privacy, and service to business travelers, executives, and individuals seeking more luxurious amenities. These rooms are generally more spacious than standard hotel rooms and are equipped with advanced facilities that cater to the needs of professionals.</p>
    <p>Executive rooms often feature a range of high-end amenities, such as a comfortable work desk, ergonomic chairs, high-speed internet access, and additional electrical outlets to accommodate work needs. Many executive rooms offer enhanced comfort with features like plush bedding, upgraded toiletries, a minibar, and a flat-screen TV. They may also have a separate sitting area for meetings or relaxation.</p>
</div>

      </div>
      {/* col end */}

      

    </div>
    {/* row end */}
  </div>
  {/* container end */}
</section>
{/* roominner end section */}


    </>
  )
}

export default Executiveroom;

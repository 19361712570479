import { Link } from "react-router-dom";

const Discover = () =>{
    return(
        <>
        {/* pagte titile section start  */}
 <section className="page_title_mkp_section page_title_mkp_section3">
   <div className="page_ti_overly_mkp_div">
     <div className="container">
       <div className="row">
         {/* col start */}
         <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
           <div className="page_title_tax_mkp_main_div">
             <div className="page_title_tax_mkp_div">
               <h1>Discover The Darien Resort</h1>
               <ul>
                 <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
                 <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/>  Discover The Darien Resort</li>
               </ul>
             </div>
           </div>
         </div>
         {/* col end */}
       </div>
     </div>
   </div>
 </section>
 {/* pagte titile section end */}
 
 {/* accommodation start section */}
 <section className="accommodation_section">
   {/* container start */}
   <div className="container">
     {/* row start */}
     <div className="row">
       {/* col start */}
       <div className="col-lg-6 col-md-12 col-sm-12 col-12">
         <div className="accommodation_img_box_main">
           <img src="../images/service02.avif" alt="accommodation" className="img-fluid"/>
         </div>
       </div>
       {/* col end */}
 
         {/* col start */}
         <div className="col-lg-6 col-md-12 col-sm-12 col-12">
         <div className="accommodation_text_box_main">
           <h1>Discover</h1>
           <p><b>The Darien Resort</b> is not just a destination; it’s an invitation to immerse yourself in unparalleled luxury and nature’s beauty. Whether you’re seeking tranquility, adventure, or an escape from the ordinary, this hidden gem offers an exceptional experience for all types of travelers.</p>
 
           <h2>A Journey Into Nature</h2>
          <p>Nestled in an idyllic location, <b>The Darien Resort</b> is surrounded by lush landscapes, serene beaches, or rolling mountains, depending on your choice of retreat. The resort brings you closer to nature without sacrificing the comfort of modern luxury. From vibrant tropical gardens to pristine coastlines, every corner of the resort is designed to harmonize with its natural surroundings.</p>

            
          
         </div>
       </div>
       {/* col end */}
 
       
         {/* col start */}
         <div className="col-lg-6 col-md-12 col-sm-12 col-12">
         <div className="accommodation_text_box_main">
           
         
         <h3>Discover World-Class Accommodation</h3>
          <p>Every guest can indulge in bespoke accommodations, offering a range of private villas, suites, or treehouses with stunning views. Whether you are waking up to the gentle sound of the ocean, surrounded by the quiet majesty of the mountains, or nestled in the middle of a tropical rainforest, The Darien Resort offers something extraordinary.</p>

          <ul>
            <li><b>Oceanfront Villas:</b> Open your eyes to the breathtaking sight of the horizon with your very own private terrace and plunge pool.</li>
            <li><b>Garden Suites:</b> For those who seek a peaceful retreat, these rooms bring guests closer to nature with vibrant garden views.</li>
            <li><b>Luxury Treehouses:</b> A unique option for the adventurous, these elevated rooms offer immersive views of the surrounding forest, bringing nature right to your doorstep.            </li>
          </ul>

          <h3>Unrivaled Experiences Await</h3>
          <p>From the moment you arrive, The Darien Resort takes you on an unforgettable journey:</p>
          <ul>
            <li><b>Private Beach Access:</b> Relax or explore the ocean with direct access to pristine beaches, perfect for swimming, snorkeling, or simply sunbathing in peace.</li>
           
          </ul>
         </div>
       </div>
       {/* col end */}
 
       {/* col start */}
       <div className="col-lg-6 col-md-12 col-sm-12 col-12">
         <div className="accommodation_img_box_main">
           <img src="../images/service022.avif" alt="accommodation" className="img-fluid"/>
         </div>
       </div>
       {/* col end */}

       
         {/* col start */}
         <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         <div className="accommodation_text_box_main">
           
         <ul>
            <li>Adventurous Excursions: Discover the local flora and fauna with guided eco-tours, hiking, or kayaking in the surrounding wilderness. </li>
            <li><b>World-Class Spa and Wellness:</b> Recharge with rejuvenating spa treatments, yoga sessions by the beach, and wellness retreats designed to restore your body and mind.            </li>
            <li><b>Fine Dining and Local Flavors:</b> Savor fresh, locally sourced cuisine in the resort’s exclusive restaurants, offering both international and regional delicacies. Whether it’s a romantic dinner by the ocean or a casual meal by the pool, the dining experience is nothing short of spectacular.            </li>
          </ul>

          <h3>Sustainability and Luxury Combined </h3>
          <p><b>The Darien Resort</b> is committed to sustainability, integrating eco-friendly practices into every aspect of its operations. The resort utilizes solar energy, water conservation methods, and locally sourced building materials, ensuring a minimal environmental footprint while maintaining its luxurious offerings. Guests can rest easy knowing that their stay supports the conservation of the surrounding natural beauty.</p>

          <h3>A Personalized Experience</h3>
          <p>At <b>The Darien Resort</b>, every detail is tailored to you. With personalized concierge services, you can explore the region’s hidden gems, book private excursions, or arrange romantic surprises. Whether you're planning a wedding, a corporate retreat, or a family getaway, the resort ensures that your experience is perfectly suited to your desires.</p>
         </div>
       </div>
       {/* col end */}
 
     </div>
     {/* row end */}
   </div>
   {/* container end */}
 </section>
 {/* accommodation end section */}
         </>
    )
}

export default Discover;
import { Link } from "react-router-dom";

const Rooms = () =>{
    return(
        <>
        {/* pagte titile section start  */}
 <section className="page_title_mkp_section page_title_mkp_section3">
   <div className="page_ti_overly_mkp_div">
     <div className="container">
       <div className="row">
         {/* col start */}
         <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
           <div className="page_title_tax_mkp_main_div">
             <div className="page_title_tax_mkp_div">
               <h1>Rooms at The Darien Resort</h1>
               <ul>
                 <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
                 <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/>  Rooms at The Darien Resort</li>
               </ul>
             </div>
           </div>
         </div>
         {/* col end */}
       </div>
     </div>
   </div>
 </section>
 {/* pagte titile section end */}
 
 
{/* accommodation start section */}
<section className="accommodation_section">
  {/* container start */}
  <div className="container">
    {/* row start */}
    
    <div className="row">

{/* col start */}
<div className="col-lg-4 col-md-6 col-sm-12 col-12">
 <Link to="/executive-room">
 <div className="accommodation_rooms_main_div">
    <div className="accommodation_room_img_box">
      <img src="../images/room01.jpg" alt="room" className='img-fluid'/>
    </div>
    
    <div className="accommodation_room_heading_div">
    <h4>Executive Room</h4>
    <Link to="/contact-us">Book Now</Link>

    </div>
    <p>Designed with the discerning business traveler in mind, our Executive Room offers an elevated level of comfort, style, and functionality. </p>
    <div className="room_icons_accommodation_div">
      <p><img src="../icons/bed.png" alt="" className='img-fluid'/> 1 bed</p>
      <p><img src="../icons/sleep.png" alt="" className='img-fluid'/> 2 sleeps</p>
      <p><img src="../icons/roomservice01.png" alt="" className='img-fluid'/> room service</p>
    </div>
    <div className="price_div_accommodation">
      <p>Price: 5,000/-</p>
    </div>
  </div>
 </Link>
</div>
{/* col end */}


{/* col start */}
<div className="col-lg-4 col-md-6 col-sm-12 col-12">
 <Link to="/superior-room">
 <div className="accommodation_rooms_main_div">
    <div className="accommodation_room_img_box">
      <img src="../images/room02.jpg" alt="room" className='img-fluid'/>
    </div>
   
    <div className="accommodation_room_heading_div">
    <h4>Superior Room</h4>
    <Link to="/contact-us">Book Now</Link>

    </div>
    <p>Elevate your stay with our Superior Room, designed for guests who appreciate extra comfort, space, and style. Offering an enhanced experience. </p>
    <div className="room_icons_accommodation_div">
      <p><img src="../icons/bed.png" alt="" className='img-fluid'/> 1 bed</p>
      <p><img src="../icons/sleep.png" alt="" className='img-fluid'/> 2 sleeps</p>
      <p><img src="../icons/roomservice01.png" alt="" className='img-fluid'/> room service</p>
    </div>
    <div className="price_div_accommodation">
      <p>Price: 7,000/-</p>
    </div>
  </div>
 </Link>
</div>
{/* col end */}



{/* col start */}
<div className="col-lg-4 col-md-6 col-sm-12 col-12">
<Link to="/presidential-room">
<div className="accommodation_rooms_main_div">
    <div className="accommodation_room_img_box">
      <img src="../images/room03.jpg" alt="room" className='img-fluid'/>
    </div>
    <div className="accommodation_room_heading_div">
    <h4>Presidential Room</h4>
    <Link to="/contact-us">Book Now</Link>

    </div>

    <p>Experience the height of luxury and sophistication in our Presidential Suite, designed for those who seek the finest in comfort, style, and exclusivity. </p>
    <div className="room_icons_accommodation_div">
      <p><img src="../icons/bed.png" alt="" className='img-fluid'/> 2 bed</p>
      <p><img src="../icons/sleep.png" alt="" className='img-fluid'/> 4 sleeps</p>
      <p><img src="../icons/roomservice01.png" alt="" className='img-fluid'/> room service</p>
    </div>
    <div className="price_div_accommodation">
      <p>Price: 9,000/-</p>
    </div>
  </div>
</Link>
</div>
{/* col end */}





</div>
    
    {/* row end */}
  </div>
  {/* container end */}
</section>
{/* accommodation end section */}
         </>
    )
}

export default Rooms;
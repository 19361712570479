import React from 'react'
import { Link } from 'react-router-dom';

const Activityarea = () => {
  return (
   
      <>
       {/* pagte titile section start  */}
<section className="page_title_mkp_section page_title_mkp_section3">
  <div className="page_ti_overly_mkp_div">
    <div className="container">
      <div className="row">
        {/* col start */}
        <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
          <div className="page_title_tax_mkp_main_div">
            <div className="page_title_tax_mkp_div">
              <h1>Activity Area</h1>
              <ul>
                <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
                <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/> Activity Area</li>
              </ul>
            </div>
          </div>
        </div>
        {/* col end */}
      </div>
    </div>
  </div>
</section>
{/* pagte titile section end */}


{/* accommodation start section */}
<section className="accommodation_section">
  {/* container start */}
  <div className="container">
    {/* row start */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_img_box_main">
          <img src="../images/bigsizepool.jpg" alt="accommodation" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

        {/* col start */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_text_box_main">
          <h1>Activity Area</h1>
          <p>An activity area is a designated space designed for various recreational, physical, or social activities. These areas are created to facilitate engagement, fun, and exercise, often found in parks, gyms, homes, resorts, or community centers. Activity areas are versatile and can cater to a wide range of interests and age groups, offering opportunities for individual or group activities.</p>

          <h2>Characteristics of an Activity Area: </h2>
          <p><b>1. Purpose:</b></p>

          <ul>
            <li>Activity areas are created with specific purposes in mind, such as fitness, sports, games, relaxation, or socializing.  </li>
            <li>They can be designed for both structured activities (like sports) and unstructured ones (like free play or social gatherings). </li>
          </ul>

          <p><b>2. Design & Features:</b></p>
          <ul>
            <li>Depending on the activity, these areas may include specialized surfaces (e.g., grass, turf, mats), seating arrangements, play equipment, and sometimes shaded spaces. </li>
            <li>For sports, activity areas may include courts (tennis, basketball), fields (soccer, football), or pools (for swimming and water activities).</li>
          </ul>

        </div>
      </div>
      {/* col end */}


    </div>
    {/* row end */}
  </div>
  {/* container end */}
</section>
{/* accommodation end section */}

    </>

  )
}

export default Activityarea;

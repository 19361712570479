import React from 'react'
import { Link } from 'react-router-dom';

const Football = () => {
  return (
    <>
       {/* pagte titile section start  */}
<section className="page_title_mkp_section page_title_mkp_section3">
  <div className="page_ti_overly_mkp_div">
    <div className="container">
      <div className="row">
        {/* col start */}
        <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
          <div className="page_title_tax_mkp_main_div">
            <div className="page_title_tax_mkp_div">
              <h1>Football</h1>
              <ul>
                <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
                <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/> Football</li>
              </ul>
            </div>
          </div>
        </div>
        {/* col end */}
      </div>
    </div>
  </div>
</section>
{/* pagte titile section end */}


{/* accommodation start section */}
<section className="accommodation_section">
  {/* container start */}
  <div className="container">
    {/* row start */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_img_box_main">
          <img src="../images/bigsizepool.jpg" alt="accommodation" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

        {/* col start */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_text_box_main">
          <h1>Football</h1>
          <p>Football, also known as soccer in some countries, is a team sport played between two teams of eleven players on a rectangular field with goals at either end. The objective of the game is to score by getting the ball into the opposing team’s goal. Players primarily use their feet to move the ball, but they can use their head and torso to control it. The game is played with a round ball and is one of the most popular sports worldwide.</p>

          <p>Football matches are typically played in two halves of 45 minutes each, with a 15-minute halftime interval. The team that scores the most goals by the end of the match wins. If the score is tied, the match may end in a draw or, in certain tournament settings, go into extra time or a penalty shootout to determine the winner.</p>
          <p>Football is played on a grass or artificial turf pitch, with a set of boundary lines marking the field. The goal is defended by a goalkeeper, who is the only player allowed to use their hands to touch the ball, but only within the penalty area. The field also includes a penalty box, center circle, and various other markings for fouls and offside rules.</p>

          <p>The game is known for its high pace, teamwork, and skillful play. Players use a combination of passing, dribbling, and shooting to create opportunities to score while defending their goal from the opposing team. Football emphasizes strategy, fitness, and coordination, and it is played at professional, amateur, and recreational levels around the world. Major international tournaments like the FIFA World Cup and domestic leagues such as the English Premier League and La Liga attract millions of fans globally.</p>
        </div>
      </div>
      {/* col end */}


    </div>
    {/* row end */}
  </div>
  {/* container end */}
</section>
{/* accommodation end section */}

    </>
    
  )
}

export default Football;

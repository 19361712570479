import { Link } from "react-router-dom";

const Tariff = () =>{
    return(
        <>
        {/* pagte titile section start  */}
 <section className="page_title_mkp_section page_title_mkp_section3">
   <div className="page_ti_overly_mkp_div">
     <div className="container">
       <div className="row">
         {/* col start */}
         <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
           <div className="page_title_tax_mkp_main_div">
             <div className="page_title_tax_mkp_div">
               <h1>Tariff at The Darien Resort</h1>
               <ul>
                 <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
                 <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/>  Tariff at The Darien Resort</li>
               </ul>
             </div>
           </div>
         </div>
         {/* col end */}
       </div>
     </div>
   </div>
 </section>
 {/* pagte titile section end */}
 
 {/* accommodation start section */}
 <section className="tariff_section">
   {/* container start */}
   <div className="container">
     {/* row start */}
     <div className="row">

       {/* col start */}
       <div className="col-lg-6 col-md-6 col-sm-12 col-12">
        {/* table div start tariff */}
        <div className="tariff_heading_div">
          <h1>Tariff Sheet</h1>
        </div>
        <div className="tariff_main_div_table">
          <table className="table table-striped table-bordered table-responsive">
            <thead>
              <tr>
                <th>Types of Room	</th>
                <th>No. of Rooms</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Executive Room</td>
                <td>11 Rooms</td>
              </tr>
              <tr>
              <td>Superior Room</td>
              <td>4 Rooms</td>
              </tr>
              <tr>
              <td>Presidential Room</td>
              <td>3 Rooms</td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* table div end tariff */}
       </div>
       {/* col end */}

        {/* col start */}
        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
       
         {/* table div start tariff */}
         <div className="tariff_heading_div">
          <h1>Tariff Validity: From 01/04/2024 To 31/03/2025</h1>
        </div>
        <div className="tariff_main_div_table">
          <table className="table table-striped table-bordered table-responsive">
            <thead>
              <tr>
                <th>Room	Types</th>
                <td>Tariff</td>
               
                
              </tr>
            </thead>
            <tbody>

              <tr>
              <th>Executive Room (Exc. GST)</th>
                <td>Rs. 5,000/-</td>
              </tr>

              <tr>
              <th>Superior Room (Exc. GST)</th>
                <td>Rs. 7,000/-</td>
              </tr>

              <tr>
              <th>Presidential Room (Exc. GST)</th>
                <td>Rs. 9,000/-</td>
              </tr>

              
             
            </tbody>
          </table>
        </div>
        {/* table div end tariff */}
        {/*  */}
        </div>
       {/* col end */}
       
     </div>
     {/* row end */}
   </div>
   {/* container end */}
 </section>
 {/* accommodation end section */}

 <section className="tariffrooms_section">
  {/* container start */}
  <div className="container">
    {/* row start */}
    
    <div className="row">

{/* col start */}
<div className="col-lg-4 col-md-6 col-sm-12 col-12">
 <Link to="/executive-room">
 <div className="accommodation_rooms_main_div">
    <div className="accommodation_room_img_box">
      <img src="../images/room01.jpg" alt="room" className='img-fluid'/>
    </div>
    
    <div className="accommodation_room_heading_div">
    <h4>Executive Room + Taxes</h4>
    

    </div>
   
  </div>
 </Link>
</div>
{/* col end */}


{/* col start */}
<div className="col-lg-4 col-md-6 col-sm-12 col-12">
 <Link to="/superior-room">
 <div className="accommodation_rooms_main_div">
    <div className="accommodation_room_img_box">
      <img src="../images/room02.jpg" alt="room" className='img-fluid'/>
    </div>
   
    <div className="accommodation_room_heading_div">
    <h4>Superior Room + Taxes</h4>
    

    </div>
   
  </div>
 </Link>
</div>
{/* col end */}



{/* col start */}
<div className="col-lg-4 col-md-6 col-sm-12 col-12">
<Link to="/presidential-room">
<div className="accommodation_rooms_main_div">
    <div className="accommodation_room_img_box">
      <img src="../images/room03.jpg" alt="room" className='img-fluid'/>
    </div>
    <div className="accommodation_room_heading_div">
    <h4>Presidential Room + Taxes</h4>
    

    </div>
  </div>
</Link>
</div>
{/* col end */}

{/* col start  */}
<div className="col-lg-12 col-md-12 col-sm-12 col-12">
<div className="tariff_note_div">
  <h2>Please Note Additional GST is applicable in respect of hotels in India.  </h2>
  <p><b>Extra adults and children will be charged as per:-</b></p>
  <ul>
    <li> There will be no charge for a child below 5 years of age.</li>
    <li> Child between 5 to 14 years @ Rs.1200/- without an extra bed + Taxes.</li>
    <li> Child between 5 to 14 years @ Rs.1800/- with an extra bed + Taxes.</li>
    <li> Above 14 years @ Rs.2000/- with an extra bed + Taxes.</li>
  </ul>
</div>
</div>
{/* col end */}



</div>
    
    {/* row end */}
  </div>
  {/* container end */}
</section>

         </>
    )
}

export default Tariff;
import React from 'react'
import { Link } from 'react-router-dom'

const Nainital = () => {
  return (
    <>
        {/* pagte titile section start  */}
<section className="page_title_mkp_section page_title_mkp_section3">
  <div className="page_ti_overly_mkp_div">
    <div className="container">
      <div className="row">
        {/* col start */}
        <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
          <div className="page_title_tax_mkp_main_div">
            <div className="page_title_tax_mkp_div">
              <h1>Nainital</h1>
              <ul>
                <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
                <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/> Nainital</li>
              </ul>
            </div>
          </div>
        </div>
        {/* col end */}
      </div>
    </div>
  </div>
</section>
{/* pagte titile section end */}


{/* accommodation start section */}
<section className="accommodation_section">
  {/* container start */}
  <div className="container">
    {/* row start */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_img_box_main">
          <img src="../images/nainital01.jpeg" alt="accommodation" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

        {/* col start */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_text_box_main">
          <h1>Nainital</h1>
          <p>Nainital is a popular hill station located in the Nainital district of Uttarakhand, India. Known for its picturesque landscapes, pleasant weather, and beautiful lakes, Nainital is one of the most sought-after tourist destinations in the Kumaon region. The town is situated at an altitude of approximately 2,084 meters (6,837 feet) above sea level and is surrounded by lush greenery, making it a perfect getaway for nature lovers, honeymooners, and adventure enthusiasts.</p>

          <h2>Key Attractions of Nainital: </h2>
          <p><b>1. Naini Lake:</b></p>
          <ul>
         <li>The heart of Nainital is Naini Lake, a crescent-shaped lake surrounded by hills. The lake offers boating opportunities, where visitors can rent paddle boats or rowboats to enjoy the stunning views of the surrounding hills and forests.         </li>
         <li>Naina Devi Temple, dedicated to Goddess Naina Devi, is situated on the northern shore of the lake and is one of the most visited pilgrimage spots in the region.         </li>
          </ul>

        </div>
      </div>
      {/* col end */}

      
        {/* col start */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_text_box_main">
     
     
<p><b>2. Naina Peak (Cheena Peak):</b></p>
<ul>
    <li>Naina Peak is the highest point in Nainital, offering panoramic views of the surrounding hills, valleys, and the distant snow-capped Himalayas. It can be reached either by a horse ride or a trek from the town.    </li>
    <li>The views from the top are breathtaking, making it a popular spot for nature photography and sightseeing.    </li>
</ul>

<p><b>3. Tiffin Top (Dorothy's Seat):</b></p>
<ul>
    <li>Located at an elevation of 2,292 meters, Tiffin Top offers a stunning 360-degree view of Nainital and its surrounding landscapes. The spot is named after Dorothy Kellet, a British artist, and is an excellent place for a picnic or a nature walk.    </li>
    <li>A horse ride can take visitors to Tiffin Top from the main town.    </li>
</ul>

<p><b>4. The Mall Road:</b></p>
<ul>
    <li>The Mall Road is the main street in Nainital, lined with shops, restaurants, and hotels. It is a great place for leisurely walks, shopping, and enjoying local delicacies like samosas, chaat, and sweets.    </li>
    <li>The Governor's House, a colonial-era building, is located along Mall Road and is open for public visits during certain hours.    </li>
</ul>
        </div>
      </div>
      {/* col end */}

      {/* col start */}
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_img_box_main">
          <img src="../images/nainital02.webp" alt="accommodation" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

    </div>
    {/* row end */}
  </div>
  {/* container end */}
</section>
{/* accommodation end section */}

    </>
  )
}

export default Nainital

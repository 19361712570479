import React from 'react'
import { Link } from 'react-router-dom';

const Ladwafalls = () => {
  return (
    <>
        {/* pagte titile section start  */}
<section className="page_title_mkp_section page_title_mkp_section3">
  <div className="page_ti_overly_mkp_div">
    <div className="container">
      <div className="row">
        {/* col start */}
        <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
          <div className="page_title_tax_mkp_main_div">
            <div className="page_title_tax_mkp_div">
              <h1>Ladwa Falls</h1>
              <ul>
                <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
                <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/> Ladwa Falls</li>
              </ul>
            </div>
          </div>
        </div>
        {/* col end */}
      </div>
    </div>
  </div>
</section>
{/* pagte titile section end */}


{/* accommodation start section */}
<section className="accommodation_section">
  {/* container start */}
  <div className="container">
    {/* row start */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_img_box_main">
          <img src="../images/ladwa01.jpg" alt="accommodation" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

        {/* col start */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_text_box_main">
          <h1>Ladwa Falls</h1>
          <p>Ladwa Falls is a beautiful and lesser-known natural waterfall located in Uttarakhand, India. This picturesque waterfall is situated near Ladwa village in the Khatima region of Udhamsingh Nagar district, and it is a popular spot for nature lovers, trekkers, and those seeking a peaceful retreat amidst the beauty of the Himalayan foothills.</p>

          <h2>Key Features of Ladwa Falls:          </h2>
          <p><b>1. Scenic Beauty:</b></p>
          <ul>
           <li>Ladwa Falls is surrounded by lush greenery, dense forests, and the Himalayan foothills, offering a serene and tranquil atmosphere. The falls are a sight to behold, especially during the monsoon season when the water flow is at its peak.     </li>
           <li>The cascading water of the falls drops from a significant height, creating a beautiful spectacle of nature. The sound of the water flowing over the rocks and the surrounding landscape make it an ideal place for photography, picnics, or simply enjoying the peace and calm of nature.           </li>
          
          </ul>

        </div>
      </div>
      {/* col end */}

      
        {/* col start */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_text_box_main">
     
       <p><b>2. Location and Accessibility:</b></p>
       <ul>
        <li>Ladwa Falls is located in the Khatima region, around 40 km from the city of Haldwani and approximately 15 km from Khatima. It is accessible via road, with a scenic drive through the rural countryside.   </li>
        <li>Visitors can take a taxi or private vehicle from nearby towns like Haldwani or Ramnagar to reach the falls. The path leading to the falls involves a short trek through the forest, making it an exciting adventure for nature enthusiasts.
        </li>
       </ul>

      <p><b>3. Best Time to Visit:</b></p>
      <ul>
        <li><b>Monsoon Season (June to September):</b> The best time to visit Ladwa Falls is during the monsoon season when the water flow is at its fullest, making the falls more impressive and powerful.        </li>
        <li><b>Summer (March to June):</b> If you prefer a less intense flow of water, visiting in the summer months can be a good option, as the weather is pleasant and perfect for a short trek.        </li>
        <li><b>Winter (October to February):</b> The winter months can be a bit cold, but the landscape surrounding the falls remains beautiful, and the weather is ideal for trekking.        </li>
      </ul>
        </div>
      </div>
      {/* col end */}

      {/* col start */}
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_img_box_main">
          <img src="../images/ladwa02.jpg" alt="accommodation" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

    </div>
    {/* row end */}
  </div>
  {/* container end */}
</section>
{/* accommodation end section */}

    </>
  )
}

export default Ladwafalls;

import React from 'react'
import { Link } from 'react-router-dom';

const Schoolgroupevents = () => {
  return (
    <>
        {/* pagte titile section start  */}
<section className="page_title_mkp_section page_title_mkp_section3">
  <div className="page_ti_overly_mkp_div">
    <div className="container">
      <div className="row">
        {/* col start */}
        <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
          <div className="page_title_tax_mkp_main_div">
            <div className="page_title_tax_mkp_div">
              <h1>School Group Events</h1>
              <ul>
                <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
                <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/> School Group Events</li>
              </ul>
            </div>
          </div>
        </div>
        {/* col end */}
      </div>
    </div>
  </div>
</section>
{/* pagte titile section end */}


{/* accommodation start section */}
<section className="accommodation_section">
  {/* container start */}
  <div className="container">
    {/* row start */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_img_box_main">
          <img src="../images/school01.avif" alt="accommodation" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

        {/* col start */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_text_box_main">
          <h1>School Group Events</h1>
          <p>School group events are organized activities or gatherings designed for students within a particular school, such as elementary, middle, or high school. These events can range from academic to recreational, social, or extracurricular, and they aim to foster a sense of community, encourage learning, and provide opportunities for students to build relationships and engage in fun, productive activities. They can be organized by teachers, student councils, parent-teacher associations (PTAs), or other student-led groups.</p>

          <h2>Types of School Group Events:</h2>
          <p><b>1. School Dances and Socials:</b></p>
          <ul>
           
           <li>Events like homecoming dances, prom, or winter formals are popular in high school. These offer students a chance to socialize in a fun and celebratory environment.   </li>
           <li>Casual social nights might include themed dances (e.g., 80s theme), movie nights, or karaoke sessions.</li>
          </ul>

          <p><b>2. Talent Shows:</b></p>
          <ul>
            <li>Students showcase their talents, whether in singing, dancing, comedy, or other performances. Talent shows are a great way for students to express themselves creatively and for the school community to come together for fun. </li>
          </ul>

        
        </div>
      </div>
      {/* col end */}

      
        {/* col start */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_text_box_main">
      <p><b>3. Field Days:</b></p>
      <ul>
        <li>Organized outdoor activities that include games, races, and team-building exercises. These events often happen toward the end of the school year and are a fun way for students to bond and enjoy physical activities.</li>
      </ul>

      <p><b>4. School Picnics or BBQs:</b></p>
      <ul>
        <li>A laid-back event where students and staff can enjoy good food, games, and music in an outdoor setting, often celebrating the start or end of the school year. </li>
      </ul>

      <h3>Academic and Educational Events:</h3>

      <p><b>1. Science Fairs: </b></p>
      <ul>
        <li>Students present science projects they have worked on, showcasing their understanding of scientific concepts and their ability to conduct experiments. These are often open to the public, allowing students to share their work with the broader community.        </li>
        
      </ul>

      <p><b>2. Math and Spelling Bees:</b></p>
      <ul>
        <li>Competitive events where students showcase their math skills or spelling abilities, either individually or as teams.        </li>
      </ul>
        </div>
      </div>
      {/* col end */}

      {/* col start */}
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_img_box_main">
          <img src="../images/school02.avif" alt="accommodation" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

    </div>
    {/* row end */}
  </div>
  {/* container end */}
</section>
{/* accommodation end section */}

    </>
  )
}

export default Schoolgroupevents;

import React from 'react'
import { Link } from 'react-router-dom'

const Trampoline = () => {
  return (
    <>
       {/* pagte titile section start  */}
<section className="page_title_mkp_section page_title_mkp_section3">
  <div className="page_ti_overly_mkp_div">
    <div className="container">
      <div className="row">
        {/* col start */}
        <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
          <div className="page_title_tax_mkp_main_div">
            <div className="page_title_tax_mkp_div">
              <h1>Trampoline</h1>
              <ul>
                <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
                <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/> Trampoline</li>
              </ul>
            </div>
          </div>
        </div>
        {/* col end */}
      </div>
    </div>
  </div>
</section>
{/* pagte titile section end */}


{/* accommodation start section */}
<section className="accommodation_section">
  {/* container start */}
  <div className="container">
    {/* row start */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_img_box_main">
          <img src="../images/bigsizepool.jpg" alt="accommodation" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

        {/* col start */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_text_box_main">
          <h1>Trampoline</h1>
         <p>Trampoline is a sport and recreational activity that involves jumping on a flexible fabric surface, known as a trampoline, which is stretched over a frame and supported by springs. The trampoline allows for high jumps and acrobatic flips, spins, and other aerial maneuvers. Trampoline activities can be done for fun, fitness, or as a competitive sport.</p>

         <p>In recreational trampoline, individuals or groups bounce on the trampoline to enjoy the sensation of flight, improve balance and coordination, or perform tricks. Many people use trampolines in their backyards or at specialized trampoline parks, where there may be various activities like dodgeball, basketball, or synchronized jumping.</p>

         <p>As a competitive sport, trampoline involves athletes performing acrobatic routines in a controlled manner, with judges awarding points for the difficulty of the moves, the execution, and the precision of the landing. In competitions, gymnasts or trampolinists aim to achieve height, control, and perfect form while executing flips, twists, and other aerial movements.</p>

         <p>Trampoline is part of gymnastics, and in the Olympics, athletes perform routines in both individual and synchronized events. The trampoline’s ability to absorb and propel athletes higher into the air requires not only strength and flexibility but also excellent timing and technique.</p>

         <p>Trampoline is enjoyed worldwide and offers numerous physical benefits, including improved cardiovascular health, strength, flexibility, and coordination. It is also a fun and engaging activity for children and adults alike.
</p>
        </div>
      </div>
      {/* col end */}


    </div>
    {/* row end */}
  </div>
  {/* container end */}
</section>
{/* accommodation end section */}

    </>
    
  )
}

export default Trampoline

import React from 'react'
import { Link } from 'react-router-dom';

const Chess = () => {
  return (
    <>
    {/* pagte titile section start  */}
<section className="page_title_mkp_section page_title_mkp_section3">
<div className="page_ti_overly_mkp_div">
 <div className="container">
   <div className="row">
     {/* col start */}
     <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
       <div className="page_title_tax_mkp_main_div">
         <div className="page_title_tax_mkp_div">
           <h1>Chess</h1>
           <ul>
             <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
             <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/> Chess</li>
           </ul>
         </div>
       </div>
     </div>
     {/* col end */}
   </div>
 </div>
</div>
</section>
{/* pagte titile section end */}


{/* accommodation start section */}
<section className="accommodation_section">
{/* container start */}
<div className="container">
 {/* row start */}
 <div className="row">
   {/* col start */}
   <div className="col-lg-6 col-md-12 col-sm-12 col-12">
     <div className="accommodation_img_box_main">
       <img src="../images/bigsizepool.jpg" alt="accommodation" className="img-fluid"/>
     </div>
   </div>
   {/* col end */}

     {/* col start */}
     <div className="col-lg-6 col-md-12 col-sm-12 col-12">
     <div className="accommodation_text_box_main">
       <h1>Chess</h1>
      <p>Chess is a classic board game that has been played for centuries and is known for its deep strategy and complexity. The game is played on an 8x8 grid, known as the chessboard, where each player starts with 16 pieces arranged in a specific order. The pieces include one king, one queen, two rooks, two knights, two bishops, and eight pawns, each of which moves according to specific rules. The goal is to checkmate the opponent's king, which means placing the king in a position where it is under attack and cannot escape capture.</p>

      <p>The game begins with each player setting up their pieces on the back two rows of the board. Players take turns moving their pieces across the board. The pieces are not allowed to pass through other pieces, except for the knight, which can jump over them. The strategic goal of chess involves controlling the center of the board, developing pieces to stronger positions, and anticipating the opponent's moves while trying to avoid making your own pieces vulnerable.</p>

      <p>Chess requires both tactical and strategic thinking. Players must think several moves ahead, often setting traps or trying to anticipate the opponent's moves. The game is known for its infinite variety of positions and combinations, making each game unique. Chess is a mental battle of skill, patience, and foresight.</p>

      <p>There are several ways a game of chess can end: one player checkmates the other’s king, resulting in a victory; the game can end in a stalemate if neither player can make a legal move; or a draw can occur if neither player is able to win under specific conditions. Chess is played worldwide, with formal tournaments, casual games, and online play all contributing to its enduring popularity as one of the most intellectually stimulating games in existence.</p>
     </div>
   </div>
   {/* col end */}


 </div>
 {/* row end */}
</div>
{/* container end */}
</section>
{/* accommodation end section */}

 </>
 
  )
}

export default Chess;



// products home page slider option start

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block"}}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block"}}
        onClick={onClick}
      />
    );
  }

export const options = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    dots: false,
    infinite: true,
    speed: 1200,
    arrows: true, // Corrected typo here
    slidesToShow: 5,
    padding: 40,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
          arrows: true, // Corrected typo here
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
          initialSlide: 1,
          arrows: true, // Corrected typo here
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 1,
          arrows: true, // Corrected typo here
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true, // Corrected typo here
        }
      }
    ]
};
    // products home page slider option end

    
  // blogsslider slider slider option start
  export const eventsslider = {
    autoplayHoverPause:true,
    loop:true,
    margin: 10,
    responsiveClass: true,
    nav: false,
    autoplay: true,
    navText: [
      '<i class="fa fa-angle-left" ></i>',
      '<i class="fa fa-angle-right"></i>',
    ],
    autoplayTimeout: 2000, // 2 seconds
    responsive: {
    0: {
    items: 1,
    nav: false,
    loop:true,
    margin: 10,
    },
    600: {
    items: 1,
    nav: false,
    loop:true,
    margin: 10,
    },
    1000: {
    items: 1,
    nav: false,
    loop: true,
    margin: 30,
    stagePadding: 200,
    }
    },
    };
    // blogsslider slider option end
    
    
import React from 'react'
import { Link } from 'react-router-dom';

const Petfriendly = () => {
  return (
    <>
    {/* pagte titile section start  */}
<section className="page_title_mkp_section page_title_mkp_section3">
<div className="page_ti_overly_mkp_div">
 <div className="container">
   <div className="row">
     {/* col start */}
     <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
       <div className="page_title_tax_mkp_main_div">
         <div className="page_title_tax_mkp_div">
           <h1>Pet Friendly</h1>
           <ul>
             <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
             <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/> Pet Friendly</li>
           </ul>
         </div>
       </div>
     </div>
     {/* col end */}
   </div>
 </div>
</div>
</section>
{/* pagte titile section end */}


{/* accommodation start section */}
<section className="accommodation_section">
{/* container start */}
<div className="container">
 {/* row start */}
 <div className="row">
   {/* col start */}
   <div className="col-lg-6 col-md-12 col-sm-12 col-12">
     <div className="accommodation_img_box_main">
       <img src="../images/bigsizepool.jpg" alt="accommodation" className="img-fluid"/>
     </div>
   </div>
   {/* col end */}

     {/* col start */}
     <div className="col-lg-6 col-md-12 col-sm-12 col-12">
     <div className="accommodation_text_box_main">
       <h1>Pet Friendly</h1>
      <p>Pet friendly refers to spaces or policies that allow pets to be present and enjoy the environment with their owners. These spaces are designed to be welcoming to pets, ensuring their comfort, safety, and convenience while providing a pleasant experience for pet owners. Pet-friendly areas can be found in homes, public spaces, businesses, and accommodations, and they often include specific features and amenities for pets.</p>
      <p>For example, pet-friendly parks might include designated areas for dogs to play off-leash, with secure fences, water stations, and waste disposal facilities. Pet-friendly hotels or vacation rentals allow pets to stay with their owners in rooms or cabins, often providing amenities like pet beds, food bowls, or even pet care services like grooming or walking.</p>
      <p>In restaurants, pet-friendly policies may allow pets to join their owners in outdoor seating areas, with some places offering treats, water bowls, or even a special pet menu. Pet-friendly communities or residential areas might have features such as walking trails, dog parks, or pet-sitting services to make life easier for pet owners.</p>
      <p>The goal of pet-friendly spaces is to create environments where pets are treated with care and respect, while also allowing owners to enjoy activities with their pets by their side.</p>
     </div>
   </div>
   {/* col end */}


 </div>
 {/* row end */}
</div>
{/* container end */}
</section>
{/* accommodation end section */}

 </>
 
  )
}

export default Petfriendly;

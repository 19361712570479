import React from 'react'
import { Link } from 'react-router-dom'

const cricket = () => {
  return (
    <>
       {/* pagte titile section start  */}
<section className="page_title_mkp_section page_title_mkp_section3">
  <div className="page_ti_overly_mkp_div">
    <div className="container">
      <div className="row">
        {/* col start */}
        <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
          <div className="page_title_tax_mkp_main_div">
            <div className="page_title_tax_mkp_div">
              <h1>Cricket</h1>
              <ul>
                <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
                <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/> Cricket</li>
              </ul>
            </div>
          </div>
        </div>
        {/* col end */}
      </div>
    </div>
  </div>
</section>
{/* pagte titile section end */}


{/* accommodation start section */}
<section className="accommodation_section">
  {/* container start */}
  <div className="container">
    {/* row start */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_img_box_main">
          <img src="../images/bigsizepool.jpg" alt="accommodation" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

        {/* col start */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_text_box_main">
          <h1>Cricket</h1>
         <p>Cricket is a bat-and-ball team sport played between two teams of eleven players on a large oval-shaped field. The game involves a bowler delivering the ball to the batsman, who attempts to hit it with a bat to score runs. Cricket is one of the most popular sports globally, particularly in countries like India, England, Australia, and South Africa.</p>
         <p>A typical cricket match consists of several formats, the most common being Test cricket, One Day Internationals (ODIs), and Twenty20 (T20) cricket.</p>
         <p>In Test cricket, two teams play a match over five days, with each team having two innings to bat. In ODIs, each team bats for a set number of overs, typically 50, while in T20 cricket, each team bats for 20 overs. The team with the most runs at the end of the match wins.</p>
         <p>The cricket field is marked with specific areas such as the pitch, a 22-yard rectangular area in the center of the field where the bowler delivers the ball and the batsman faces it. The wickets, consisting of three stumps and two bails, are placed at either end of the pitch, and the bowler’s goal is to hit the wicket to dismiss the batsman.</p>
         <p>The game begins with the toss, where one team bats and the other bowls. The batting team tries to score runs by hitting the ball and running between the wickets or by hitting the ball to the boundary for four or six runs. The bowling team aims to get the batsman out by various means, such as bowling him out (hitting the stumps), catching the ball, or getting him run out.</p>
         <p>Cricket requires a combination of skill, strategy, and teamwork. It is known for its intricate rules, which include methods of dismissing players, scoring runs, and the roles of different types of bowlers and batsmen. The sport is played both professionally and at the amateur level and is celebrated through major tournaments like the ICC Cricket World Cup, the Ashes series, and domestic leagues like the Indian Premier League (IPL).</p>
        </div>
      </div>
      {/* col end */}


    </div>
    {/* row end */}
  </div>
  {/* container end */}
</section>
{/* accommodation end section */}

    </>
    
  )
}

export default cricket

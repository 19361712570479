import React from 'react'
import { Link } from 'react-router-dom';

const Walkwithnature = () => {
  return (
    <>
    {/* pagte titile section start  */}
<section className="page_title_mkp_section page_title_mkp_section3">
<div className="page_ti_overly_mkp_div">
 <div className="container">
   <div className="row">
     {/* col start */}
     <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
       <div className="page_title_tax_mkp_main_div">
         <div className="page_title_tax_mkp_div">
           <h1>Walk With Nature</h1>
           <ul>
             <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
             <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/> Walk With Nature</li>
           </ul>
         </div>
       </div>
     </div>
     {/* col end */}
   </div>
 </div>
</div>
</section>
{/* pagte titile section end */}


{/* accommodation start section */}
<section className="accommodation_section">
{/* container start */}
<div className="container">
 {/* row start */}
 <div className="row">
   {/* col start */}
   <div className="col-lg-6 col-md-12 col-sm-12 col-12">
     <div className="accommodation_img_box_main">
       <img src="../images/bigsizepool.jpg" alt="accommodation" className="img-fluid"/>
     </div>
   </div>
   {/* col end */}

     {/* col start */}
     <div className="col-lg-6 col-md-12 col-sm-12 col-12">
     <div className="accommodation_text_box_main">
       <h1>Walk With Nature</h1>
       <p>Walk with nature refers to the act of strolling or hiking through natural environments, such as forests, parks, trails, or other outdoor settings. This activity encourages people to immerse themselves in the beauty and tranquility of nature, promoting physical well-being and mental relaxation. Walking with nature allows individuals to connect with the natural world, enjoy the fresh air, observe wildlife, and experience the calming effects of the outdoors.</p>
       <p>A walk with nature can take many forms, from leisurely walks through scenic parks to more challenging hikes in forests or mountain trails. The experience may involve the sounds of birds chirping, the sight of lush greenery, or the smell of fresh earth and flowers. For many, walking with nature is not only about physical exercise but also about mindfulness and finding peace away from the stresses of daily life.</p>
       <p>This activity is often used as a form of relaxation, meditation, or escape from urban life, and can be done alone, with friends, or as part of a group. Walking with nature also promotes environmental awareness, as it fosters a deeper appreciation for natural landscapes and the importance of preserving them.</p>
     </div>
   </div>
   {/* col end */}


 </div>
 {/* row end */}
</div>
{/* container end */}
</section>
{/* accommodation end section */}

 </>
 
  )
}

export default Walkwithnature;

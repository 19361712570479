import React from 'react'
const Sidenavbar = () => {
  return (
    <>
    {/* side bar nav main div start */}
    <div className="sidebar_main_div">
  <div className="offcanvas offcanvas-start d-block d-lg-nonee" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="sidenavbar" aria-labelledby="sidenavbarLabel">
    {/* start header */}
 <div className="offcanvas-header"> 
  <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel">
    <a href="" data-bs-toggle="modal" data-bs-target="#login">
    <img src="../logo/logo.png" alt /></a>
    </h5>
  <button type="button" className="fa fa-times" data-bs-dismiss="offcanvas" aria-label="Close" />
</div>
{/* end haeder */}

{/* offcanvas body start */}
    <div className="offcanvas-body">
     {/* sidebar navlink div start */}
     <div className="sidenav_navlink_div">
        <ul>
            <li><a href="/"> <img src="../icons/home.png" alt="home" className='img-fluid sidebarimg'/>  Home</a></li>
            <li><a href="/about-us"> <img src="../icons/aboutus.png" alt="home" className='img-fluid sidebarimg'/> About Us</a></li>
            
 {/*  All services li */}
  <div className="categories_div_main">
     <li><a href="javascript:void(0);"> <img src="../icons/services.png" alt="home" className='img-fluid sidebarimg'/> Services</a> <span data-bs-toggle="collapse" data-bs-target="#categories-menu" className="bx bx-chevron-down" /></li>
        <div className="collapse" id="categories-menu">
        <div className="card card-body categories_card_body">
      <ul className="categories_ul_list">
          {/* submenu start */}
          <li><a href="/rooms"><img src="../icons/sericon04.png" alt="home" className='img-fluid sidebarimg'/>  Rooms</a> <span  data-bs-toggle="collapse" data-bs-target="#submenu01" class="bx bx-plus"></span></li>
      <div class="collapse" id="submenu01">
      <div class="submneu_main_div_sidebar">
        <ul class="product_submenu_ul">
          <li><a href="/executive-room"> <img src="../icons/room01.png" alt="home" className='img-fluid headericonimg'/> Execitive Rooms</a></li>  
          <li><a href="/superior-room"> <img src="../icons/room02.png" alt="home" className='img-fluid headericonimg'/> Superior Rooms</a></li>  
          <li><a href="/presential-room"> <img src="../icons/room03.png" alt="home" className='img-fluid headericonimg'/> Presential Rooms</a></li> 
        </ul>
      </div>
    </div>
            {/* submenu end */}

           <li><a href="/accommodation"><img src="../icons/sericon01.png" alt="home" className='img-fluid sidebarimg'/> Accommodation</a></li>
            <li><a href="/tariff"><img src="../icons/sericon03.png" alt="home" className='img-fluid sidebarimg'/>  Tariff</a></li>
              {/* submenu start */}
              <li><a href=""><img src="../icons/sericon04.png" alt="home" className='img-fluid sidebarimg'/>  Facility</a> <span  data-bs-toggle="collapse" data-bs-target="#submenu02" class="bx bx-plus"></span></li>
      <div class="collapse" id="submenu02">
      <div class="submneu_main_div_sidebar">
        <ul class="product_submenu_ul">
          <li><a  href="/big-size-pool"> <img src="../icons/fs01.png" alt="home" className='img-fluid headericonimg'/> Big Size Pool</a></li>  
          <li><a href="/activity-area"> <img src="../icons/fs02.png" alt="home" className='img-fluid headericonimg'/> Activity Area</a></li>  
          <li><a href="/pet-friendly"> <img src="../icons/fs03.png" alt="home" className='img-fluid headericonimg'/> Pet Friendly</a></li>  
          <li><a href="/valet-parking"> <img src="../icons/fs04.png" alt="home" className='img-fluid headericonimg'/> Valet Parking</a></li>  
          <li><a href="/walk-with-nature"> <img src="../icons/fs05.png" alt="home" className='img-fluid headericonimg'/> Walk With Nature</a></li>  
          <li><a href="/yoga-meditation"> <img src="../icons/fs06.png" alt="home" className='img-fluid headericonimg'/> Yoga Meditation</a></li>  
          <li><a href="/sun-rays-therapy"> <img src="../icons/fs07.png" alt="home" className='img-fluid headericonimg'/> Sun Rays Therapy</a></li>  
          <li><a href="/water-therapy"> <img src="../icons/fs08.png" alt="home" className='img-fluid headericonimg'/> Water Therapy</a></li> 
        </ul>
      </div>
    </div>
            {/* submenu end */}
      </ul>
    </div>
  </div>
  </div>
  {/* All services drop li end */}

           {/*  All services li */}
           <div className="categories_div_main">
     <li><a href="javascript:void(0);"> <img src="../icons/events.png" alt="home" className='img-fluid sidebarimg'/> Events</a> <span data-bs-toggle="collapse" data-bs-target="#categories-menu02" className="bx bx-chevron-down" /></li>
  <div className="collapse" id="categories-menu02">
    <div className="card card-body categories_card_body">
      <ul className="categories_ul_list">
      <li><a href="/destination-wedding"> <img src="../icons/destination.png" alt="home" className='img-fluid headericonimg'/> Destination Wedding</a></li>
            <li><a href="/corporate-meeting-events"> <img src="../icons/corporate.png" alt="home" className='img-fluid headericonimg'/> Corporate Meeting or Events</a></li>
            <li><a href="/pool-party"> <img src="../icons/pooparty.png" alt="home" className='img-fluid headericonimg'/> Pool Party</a></li>
            <li><a href="/college-group-events"> <img src="../icons/groupevent.png" alt="home" className='img-fluid headericonimg'/> College Group Events</a></li>
            <li><a href="/school-group-events"> <img src="../icons/schoolevent.png" alt="home" className='img-fluid headericonimg'/> School Group Events</a></li>
      </ul>
    </div>
  </div>
  </div>
  {/* All services drop li end */}

   {/*  All services li */}
   <div className="categories_div_main">
     <li><a href="javascript:void(0);"> <img src="../icons/safari.png" alt="home" className='img-fluid sidebarimg'/> Safari</a> <span data-bs-toggle="collapse" data-bs-target="#categories-menu03" className="bx bx-chevron-down" /></li>
  <div className="collapse" id="categories-menu03">
    <div className="card card-body categories_card_body">
      <ul className="categories_ul_list">
            
             {/* submenu start */}
             <li><a href=""><img src="../icons/nature.png" alt="home" className='img-fluid sidebarimg'/>  Nature</a> <span  data-bs-toggle="collapse" data-bs-target="#submenu03" class="bx bx-plus"></span></li>
      <div class="collapse" id="submenu03">
      <div class="submneu_main_div_sidebar">
        <ul class="product_submenu_ul">
        <li><a href="/kainchi-dham"> <img src="../icons/kainchidham.png" alt="home" className='img-fluid headericonimg'/> Kainchi Dham</a></li>  
          <li><a href="/garjiya-temple"> <img src="../icons/garjiyatemple.png" alt="home" className='img-fluid headericonimg'/> Garjiya Temple</a></li>  
          <li><a href="/hanuman-dham"> <img src="../icons/hanumandham.png" alt="home" className='img-fluid headericonimg'/> Hanuman Dham</a></li>  
          <li><a href="/corbett-museum"> <img src="../icons/corbettmuseum.png" alt="home" className='img-fluid headericonimg'/> Corbett Museum</a></li>  
          <li><a href="/ladwa-falls"> <img src="../icons/ladwafalls.png" alt="home" className='img-fluid headericonimg'/> Ladwa Falls</a></li>  
          <li><a href="/corbett-falls"> <img src="../icons/corbettfalls.png" alt="home" className='img-fluid headericonimg'/> Corbett Falls</a></li>  
          <li><a href="/nainital"> <img src="../icons/nainital.png" alt="home" className='img-fluid headericonimg'/> Nainital</a></li>  
          <li><a href="/bhimtal"> <img src="../icons/bhimtal.png" alt="home" className='img-fluid headericonimg'/> Bhimtal</a></li>  
          <li><a href="/naukuchiatal"> <img src="../icons/nakuchiatal.png" alt="home" className='img-fluid headericonimg'/> Naukuchiatal</a></li>  
       
        </ul>
      </div>
    </div>
            {/* submenu end */}
         
            {/* submenu start */}
            <li><a href=""><img src="../icons/jungle.png" alt="home" className='img-fluid sidebarimg'/>  Jungle</a> <span  data-bs-toggle="collapse" data-bs-target="#submenu04" class="bx bx-plus"></span></li>
      <div class="collapse" id="submenu04">
      <div class="submneu_main_div_sidebar">
        <ul class="product_submenu_ul">
        <li><a href="/river-side"> <img src="../icons/riverside.png" alt="home" className='img-fluid headericonimg'/> River Side</a></li>  
          <li><a href="/view-points"> <img src="../icons/viewpoints.png" alt="home" className='img-fluid headericonimg'/> View Points</a></li>  
          <li><a href="/side-scenes"> <img src="../icons/sidescenes.png" alt="home" className='img-fluid headericonimg'/> Side Scenes </a></li>  
       
        </ul>
      </div>
    </div>
            {/* submenu end */}


 {/* submenu start */}
 <li><a href=""><img src="../icons/sightseeing.png" alt="home" className='img-fluid sidebarimg'/>  Sightseeing</a> <span  data-bs-toggle="collapse" data-bs-target="#submenu044" class="bx bx-plus"></span></li>
      <div class="collapse" id="submenu044">
      <div class="submneu_main_div_sidebar">
        <ul class="product_submenu_ul">
       <li><a href="/garjiya-devi-temple"> <img src="../icons/Garjia01.png" alt="home" className='img-fluid headericonimg'/>  Garjia Devi temple</a></li>  
                 <li><a href="/hanuman-dham"> <img src="../icons/hanumandham.png" alt="home" className='img-fluid headericonimg'/>  Hanuman Dham</a></li>  
                 <li><a href="/corbett-museum"> <img src="../icons/corbettmuseum.png" alt="home" className='img-fluid headericonimg'/>  Corbett Museum  </a></li>  
       
                 <li><a href="/corbett-falls"> <img src="../icons/corbettfalls.png" alt="home" className='img-fluid headericonimg'/>  Corbett Fall  </a></li>  
       
                 <li><a href="/barati-rau-waterfall"> <img src="../icons/sidescenes.png" alt="home" className='img-fluid headericonimg'/>  Barati Rau Waterfall  </a></li>  
       
                 <li><a href="/nainital"> <img src="../icons/nainital.png" alt="home" className='img-fluid headericonimg'/>  Nanitaal</a></li>  
       
                 <li><a href="/neem-karoli-baba"> <img src="../icons/neem.png" alt="home" className='img-fluid headericonimg'/>  Neem Karoli Baba  </a></li>  
       
        </ul>
      </div>
    </div>
            {/* submenu end */}

      </ul>
    </div>
  </div>
  </div>
  {/* All services drop li end */}

  
   {/*  All services li */}
   <div className="categories_div_main">
     <li><a href="javascript:void(0);"> <img src="../icons/activities.png" alt="home" className='img-fluid sidebarimg'/> Activities</a> <span data-bs-toggle="collapse" data-bs-target="#categories-menu04" className="bx bx-chevron-down" /></li>
  <div className="collapse" id="categories-menu04">
    <div className="card card-body categories_card_body">
      <ul className="categories_ul_list">
          {/* submenu start */}
          <li><a href=""><img src="../icons/indoor.png" alt="home" className='img-fluid sidebarimg'/>  Indoor Activities</a> <span  data-bs-toggle="collapse" data-bs-target="#submenu05" class="bx bx-plus"></span></li>
      <div class="collapse" id="submenu05">
      <div class="submneu_main_div_sidebar">
        <ul class="product_submenu_ul">
        <li><a href="/pool-table"> <img src="../icons/pooltable.png" alt="home" className='img-fluid headericonimg'/> Pool Table</a></li>  
          <li><a href="/carrom"> <img src="../icons/carrom.png" alt="home" className='img-fluid headericonimg'/> Carrom</a></li>  
          <li><a href="/ludo"> <img src="../icons/ludo.png" alt="home" className='img-fluid headericonimg'/> Ludo</a></li>  
          <li><a href="/chess"> <img src="../icons/chess.png" alt="home" className='img-fluid headericonimg'/> Chess</a></li>  
       
        </ul>
      </div>
    </div>
            {/* submenu end */}

             {/* submenu start */}
          <li><a href=""><img src="../icons/outdoor.png" alt="home" className='img-fluid sidebarimg'/>  Outdoor Activities</a> <span  data-bs-toggle="collapse" data-bs-target="#submenu06" class="bx bx-plus"></span></li>
      <div class="collapse" id="submenu06">
      <div class="submneu_main_div_sidebar">
        <ul class="product_submenu_ul">
        <li><a href="/football"> <img src="../icons/football.png" alt="home" className='img-fluid headericonimg'/> Football</a></li>  
          <li><a href="/cricket"> <img src="../icons/cricket.png" alt="home" className='img-fluid headericonimg'/> Cricket</a></li>  
          <li><a href="/pool-volleyball"> <img src="../icons/poolvolleyball.png" alt="home" className='img-fluid headericonimg'/> Pool Volleyball </a></li>  
          <li><a href="/badminton"> <img src="../icons/badminton.png" alt="home" className='img-fluid headericonimg'/> Badminton</a></li>  
          <li><a href="/trampoline"> <img src="../icons/trampoline.png" alt="home" className='img-fluid headericonimg'/> Trampoline</a></li>  
          <li><a href="/mickey-mouse"> <img src="../icons/mickeymouse.png" alt="home" className='img-fluid headericonimg'/> Mickey Mouse (Bouncee)</a></li>  
       
        </ul>
      </div>
    </div>
            {/* submenu end */}


      </ul>
    </div>
  </div>
  </div>
  {/* All services drop li end */}

    
      <li><a href="/contact-us"><img src="../icons/contactus.png" alt="home" className='img-fluid sidebarimg'/>  Contact Us</a></li>
        </ul>
     </div>
     {/* sidebar navlink div end*/}


    </div>
    {/* offcanvas body end */}

  </div>
  </div>
    {/* side bar nav main div end */}

    

    </>
  )
}

export default Sidenavbar

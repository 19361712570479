import React from 'react'
import { Link } from 'react-router-dom';

const Bigsizepool = () => {
  return (
    <>
       {/* pagte titile section start  */}
<section className="page_title_mkp_section page_title_mkp_section3">
  <div className="page_ti_overly_mkp_div">
    <div className="container">
      <div className="row">
        {/* col start */}
        <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
          <div className="page_title_tax_mkp_main_div">
            <div className="page_title_tax_mkp_div">
              <h1>Big Size Pool</h1>
              <ul>
                <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
                <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/> Big Size Pool</li>
              </ul>
            </div>
          </div>
        </div>
        {/* col end */}
      </div>
    </div>
  </div>
</section>
{/* pagte titile section end */}


{/* accommodation start section */}
<section className="accommodation_section">
  {/* container start */}
  <div className="container">
    {/* row start */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_img_box_main">
          <img src="../images/bigsizepool.jpg" alt="accommodation" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

        {/* col start */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_text_box_main">
          <h1>Big Size Pool</h1>
          <p>A big size pool is an expansive water feature designed for various recreational, competitive, or aesthetic purposes. It is typically larger than standard residential pools, offering more space for swimming, relaxation, and social gatherings. These pools come in a wide range of designs and styles, from sleek, geometric shapes to more organic, freeform configurations.</p>
          
          <p>For residential settings, a big size pool can serve as a luxurious addition to the backyard, often integrated with landscaping elements such as lush gardens, stone paths, and outdoor living areas. The pool may be built with materials like concrete, fiberglass, or vinyl, and can include additional features like waterfalls, fountains, or even integrated lighting systems to enhance the visual appeal.</p>
          <p>In larger commercial or resort settings, big pools are designed to accommodate more people and can include amenities such as swim-up bars, hot tubs, children's splash zones, and water slides. Resort pools are often built with a focus on relaxation and leisure, providing spaces for lounging or socializing, and may incorporate natural elements like rock formations or tropical plants.</p>
          <p>Olympic-size pools are a specific example of large pools, designed for competitive swimming and following strict international standards. These pools are typically 50 meters long, 25 meters wide, and can have multiple lanes for swimmers.</p>

        </div>
      </div>
      {/* col end */}


    </div>
    {/* row end */}
  </div>
  {/* container end */}
</section>
{/* accommodation end section */}

    </>
    
  )
}

export default Bigsizepool;
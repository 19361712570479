import React from 'react'
import { Link } from 'react-router-dom'

const Corporatemeetingevents = () => {
  return (
    <>
     {/* pagte titile section start  */}
<section className="page_title_mkp_section page_title_mkp_section3">
  <div className="page_ti_overly_mkp_div">
    <div className="container">
      <div className="row">
        {/* col start */}
        <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
          <div className="page_title_tax_mkp_main_div">
            <div className="page_title_tax_mkp_div">
              <h1>Corporate Meeting or Events</h1>
              <ul>
                <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
                <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/>  Corporate Meeting or Events</li>
              </ul>
            </div>
          </div>
        </div>
        {/* col end */}
      </div>
    </div>
  </div>
</section>
{/* pagte titile section end */}


{/* accommodation start section */}
<section className="accommodation_section">
  {/* container start */}
  <div className="container">
    {/* row start */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_img_box_main">
          <img src="../images/corporate01.avif" alt="accommodation" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

        {/* col start */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_text_box_main">
          <h1>Corporate Meeting or Events</h1>
          <p>Corporate meetings and events are essential for businesses to engage employees, clients, and stakeholders, as well as to promote brand awareness and facilitate networking. These gatherings come in various formats, from small internal team meetings to large-scale conferences or product launches. They can be hosted for a variety of purposes, such as training, networking, team-building, or celebrating milestones.</p>

          <h2>Types of Corporate Meetings and Events:</h2>
          <p><b>1. Conferences:</b> Large events that focus on a specific industry or topic, often with keynote speakers, panel discussions, and networking opportunities.</p>
          <ul>
            <li><b>Purpose:</b> Knowledge-sharing, industry updates, and networking. </li>
            <li><b>Examples:</b> Tech conferences, HR summits, marketing forums.</li>
          </ul>

          <p><b>2. Seminars and Workshops:</b> Smaller educational events where experts provide training on specific topics. These are typically interactive and can range from half-day sessions to multiple-day events.</p>

          <ul>
            <li><b>Purpose:</b> Employee skill development, professional growth, or product training.</li>
            <li><b>Examples:</b> Leadership development seminars, sales training workshops.</li>
          </ul>
        </div>
      </div>
      {/* col end */}

      
        {/* col start */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_text_box_main">
        <p><b>3. Team-building Events:</b> Activities designed to enhance collaboration, communication, and morale among employees. These can be indoor or outdoor and may include games, challenges, or social events.</p>

        <ul>
            <li><b>Purpose:</b> Strengthening team dynamics and improving employee relationships.</li>
            <li><b>Examples:</b> Outdoor adventure activities, escape rooms, charity events. </li>
        </ul>

        <p><b>4. Product Launches:</b> Events dedicated to unveiling new products or services, often with press coverage and customer engagement activities.</p>

        <ul>
            <li><b>Purpose:</b> Generate buzz and awareness for new products or services. </li>
            <li><b>Examples:</b> New tech product unveiling, fashion collection reveals. </li>
        </ul>

        <p><b>5. Corporate Retreats:</b> Typically held away from the office, these events combine leisure and business, often focused on strategy discussions, team building, or leadership development.</p>

        <ul>
            <li><b>Purpose:</b> Strategic planning, employee motivation, and fostering company culture. </li>
            <li><b>Examples:</b> Executive retreats, strategy planning offsite.</li>
        </ul>

        <p><b>6. Trade Shows and Exhibitions:</b> Events where companies showcase their products or services to a targeted audience, often within a specific industry.</p>
        <ul>
            <li><b>Purpose:</b> Marketing, lead generation, and brand awareness.</li>
            <li><b>Examples:</b> Auto shows, tech expos, fashion trade shows.</li>
        </ul>
        </div>
      </div>
      {/* col end */}

      {/* col start */}
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_img_box_main">
          <img src="../images/corporate02.avif" alt="accommodation" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

    </div>
    {/* row end */}
  </div>
  {/* container end */}
</section>
{/* accommodation end section */}
    </>
  )
}

export default Corporatemeetingevents

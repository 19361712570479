import React from 'react'
import { Link } from 'react-router-dom';

const Kainchidham = () => {
  return (
    <>
       {/* pagte titile section start  */}
<section className="page_title_mkp_section page_title_mkp_section3">
  <div className="page_ti_overly_mkp_div">
    <div className="container">
      <div className="row">
        {/* col start */}
        <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
          <div className="page_title_tax_mkp_main_div">
            <div className="page_title_tax_mkp_div">
              <h1>Kainchi Dham</h1>
              <ul>
                <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
                <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/> Kainchi Dham</li>
              </ul>
            </div>
          </div>
        </div>
        {/* col end */}
      </div>
    </div>
  </div>
</section>
{/* pagte titile section end */}


{/* accommodation start section */}
<section className="accommodation_section">
  {/* container start */}
  <div className="container">
    {/* row start */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_img_box_main">
          <img src="../images/kainchi01.avif" alt="accommodation" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

        {/* col start */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_text_box_main">
          <h1>Kainchi Dham</h1>
          <p>Kainchi Dham is a famous Hindu temple located in the Nainital district of Uttarakhand, India. It is dedicated to Neem Karoli Baba (also known as Maharaj-ji), a revered saint and spiritual leader, who had a significant influence on many people in India and abroad. The temple and the ashram are located in a serene and picturesque location, surrounded by hills and the Kosi river, making it a peaceful place for pilgrimage and meditation.</p>

          <h2>Key Features of Kainchi Dham:</h2>
          <p><b>1. Neem Karoli Baba (Maharaj-ji):</b></p>
          <ul>
           <li>Neem Karoli Baba was a saint believed to have had miraculous powers. He attracted thousands of followers, including prominent personalities like Steve Jobs, Mark Zuckerberg, and others. His teachings focused on love, devotion, and surrender to God.</li>
           <li>The temple is dedicated to Neem Karoli Baba, and his teachings continue to inspire many people who visit the place for spiritual solace.</li>
          </ul>

        </div>
      </div>
      {/* col end */}

      
        {/* col start */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_text_box_main">
     
        <p><b>2. Temple and Ashram:</b></p>
          <ul>
            <li>The Kainchi Dham Temple is simple yet spiritual, with a large statue of Neem Karoli Baba. Pilgrims often visit the temple to seek blessings, meditate, and feel a sense of peace.   </li>
            <li>The ashram is an integral part of the Dham, where people can stay for extended periods, participate in spiritual activities, and experience the lifestyle of devotion and simplicity.            </li>
          </ul>

        <p><b>3. Scenic Location:</b></p>
        <ul>
            <li>The temple is situated at the confluence of two rivers, which gives it a unique and tranquil environment. The area is surrounded by lush green hills, offering beautiful views of nature.            </li>
            <li>The location adds to the temple's appeal, making it not only a spiritual center but also a popular destination for nature lovers and trekkers.            </li>
        </ul>

        <p><b>4. Spiritual and Healing Atmosphere:</b></p>
        <ul>
            <li>Kainchi Dham is known for its healing and tranquil atmosphere. Many devotees visit for both spiritual healing and personal transformation, seeking peace and divine intervention in their lives. </li>
            <li>The temple also has a "Langar" (community kitchen), where food is served to all visitors for free, following the tradition of selfless service.            </li>
        </ul>
        </div>
      </div>
      {/* col end */}

      {/* col start */}
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_img_box_main">
          <img src="../images/kainchi02.avif" alt="accommodation" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

    </div>
    {/* row end */}
  </div>
  {/* container end */}
</section>
{/* accommodation end section */}

    </>
  )
}

export default Kainchidham;


import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Header from './layout/Header';
import "./cssfiles/style.css";
import "./cssfiles/responsive.css";
import Footer from './layout/Footer';
import Home from './pages/Home';
import Aboutus from './pages/Aboutus';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from './layout/Scrolltop';
import Contactus from './pages/contact-us';
import Accommodation from './pages/accommodation';
import Discover from './pages/discover';
import Tariff from './pages/tariff';
import Rooms from './pages/rooms';
import Dining from './pages/dining';
import Destinationwedding from './pages/destination-wedding';
import Corporatemeetingevents from './pages/corporate-meeting-events';
import Poolparty from './pages/pool-party';
import Collegegroupevents from './pages/college-group-events';
import Schoolgroupevents from './pages/school-group-events';
import Kainchidham from './pages/kainchi-dham';
import Garjiyatemple from './pages/garjiya-temple';
import Hanumandham from './pages/hanuman-dham';
import Corbettmuseum from './pages/corbett-museum';
import Ladwafalls from './pages/ladwa-falls';
import Corbettfalls from './pages/corbett-falls';
import Nainital from './pages/nainital';
import Bhimtal from './pages/bhimtal';
import naukuchiatal from './pages/naukuchiatal';
import Riverside from './pages/river-side';
import Viewpoints from './pages/view-points';
import Sidescenes from './pages/side-scenes';
import Bigsizepool from './pages/big-size-pool';
import Activityarea from './pages/activity-area';
import Petfriendly from './pages/pet-friendly';
import Valetparking from './pages/valet-parking';
import Walkwithnature from './pages/walk-with-nature';
import Yogameditation from './pages/yoga-meditation';
import Sunraystherapy from './pages/sun-rays-therapy';
import Watertherapy from './pages/water-therapy';
import Pooltable from './pages/pool-table';
import Carrom from './pages/carrom';
import Ludo from './pages/ludo';
import Chess from './pages/chess';
import Football from './pages/football';
import cricket from './pages/cricket';
import Poolvolleyball from './pages/pool-volleyball';
import badminton from './pages/badminton';
import Trampoline from './pages/trampoline';
import Mickeymouse from './pages/mickey-mouse';
import Executiveroom from './pages/executive-room';
import Superiorroom from './pages/superior-room';
import Presentialroom from './pages/presential-room';
import Garjiadevitemple from './pages/garjia-devi-temple';
import Baratirauwaterfall from './pages/barati-rau-waterfall';
import Neemkarolibaba from './pages/neem-karoli-baba';
function App() {
  return (
    <>
  <BrowserRouter>
  <Header/>
  <ScrollToTop/>
   <Routes>

    <Route path='/' Component={Home}></Route>
    <Route path='/about-us' Component={Aboutus}></Route>
    <Route path='/accommodation' Component={Accommodation}></Route>
    <Route path='/discover' Component={Discover}></Route>
    <Route path='/tariff' Component={Tariff}></Route>
    <Route path='/rooms' Component={Rooms}></Route>
    <Route path='/destination-wedding' Component={Destinationwedding}></Route>
    <Route path='/corporate-meeting-events' Component={Corporatemeetingevents}></Route>
    <Route path='/pool-party' Component={Poolparty}></Route>
    <Route path='/college-group-events' Component={Collegegroupevents}></Route>
    <Route path='/school-group-events' Component={Schoolgroupevents}></Route>
    <Route path='/kainchi-dham' Component={Kainchidham}></Route>
    <Route path='/garjiya-temple' Component={Garjiyatemple}></Route>
    <Route path='/hanuman-dham' Component={Hanumandham}></Route>
    <Route path='/corbett-museum' Component={Corbettmuseum}></Route>
    <Route path='/ladwa-falls' Component={Ladwafalls}></Route>
    <Route path='/corbett-falls' Component={Corbettfalls}></Route>
    <Route path='/nainital' Component={Nainital}></Route>
    <Route path='/bhimtal' Component={Bhimtal}></Route>
    <Route path='/naukuchiatal' Component={naukuchiatal}></Route>
    <Route path='/river-side' Component={Riverside}></Route>
    <Route path='/view-points' Component={Viewpoints}></Route>
    <Route path='/side-scenes' Component={Sidescenes}></Route>
    <Route path='/dining' Component={Dining}></Route>
    <Route path='/big-size-pool' Component={Bigsizepool}></Route>
    <Route path='/activity-area' Component={Activityarea}></Route>
    <Route path='/pet-friendly' Component={Petfriendly}></Route>
    <Route path='/valet-parking' Component={Valetparking}></Route>
    <Route path='/walk-with-nature' Component={Walkwithnature}></Route>
    <Route path='/yoga-meditation' Component={Yogameditation}></Route>
    <Route path='/sun-rays-therapy' Component={Sunraystherapy}></Route>
    <Route path='/water-therapy' Component={Watertherapy}></Route>
    <Route path='/pool-table' Component={Pooltable}></Route>
    <Route path='/carrom' Component={Carrom}></Route>
    <Route path='/ludo' Component={Ludo}></Route>
    <Route path='/chess' Component={Chess}></Route>
    <Route path='/football' Component={Football}></Route>
    <Route path='/cricket' Component={cricket}></Route>
    <Route path='/pool-volleyball' Component={Poolvolleyball}></Route>
    <Route path='/badminton' Component={badminton}></Route>
    <Route path='/trampoline' Component={Trampoline}></Route>
    <Route path='/mickey-mouse' Component={Mickeymouse}></Route>
    <Route path='/executive-room' Component={Executiveroom}></Route>
    <Route path='/superior-room' Component={Superiorroom}></Route>
    <Route path='/presential-room' Component={Presentialroom}></Route>
    <Route path='/contact-us' Component={Contactus}></Route>
    <Route path='/garjiya-devi-temple' Component={Garjiadevitemple}></Route>
    <Route path='/barati-rau-waterfall' Component={Baratirauwaterfall}></Route>
    <Route path='/neem-karoli-baba' Component={Neemkarolibaba}></Route>
  
  
  </Routes>
  <Footer/>
  </BrowserRouter>
    </>
  );
}
export default App;

import { Link } from "react-router-dom";
import "../cssfiles/Services.css";

const Accommodation = () =>{
    return(
        <>
       {/* pagte titile section start  */}
<section className="page_title_mkp_section page_title_mkp_section3">
  <div className="page_ti_overly_mkp_div">
    <div className="container">
      <div className="row">
        {/* col start */}
        <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
          <div className="page_title_tax_mkp_main_div">
            <div className="page_title_tax_mkp_div">
              <h1>Accommodation</h1>
              <ul>
                <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
                <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/>  Accommodation</li>
              </ul>
            </div>
          </div>
        </div>
        {/* col end */}
      </div>
    </div>
  </div>
</section>
{/* pagte titile section end */}


{/* accommodation start section */}
<section className="accommodation_section">
  {/* container start */}
  <div className="container">
    {/* row start */}
    
    <div className="row">

{/* col start */}
<div className="col-lg-4 col-md-6 col-sm-12 col-12">
 <Link to="/executive-room">
 <div className="accommodation_rooms_main_div">
    <div className="accommodation_room_img_box">
      <img src="../images/room01.jpg" alt="room" className='img-fluid'/>
    </div>
    
    <div className="accommodation_room_heading_div">
    <h4 className="text-center">Executive Room</h4>
    <span className="fa fa-angle-double-right"></span>
    </div>
    
  </div>
 </Link>
</div>
{/* col end */}


{/* col start */}
<div className="col-lg-4 col-md-6 col-sm-12 col-12">
 <Link to="/superior-room">
 <div className="accommodation_rooms_main_div">
    <div className="accommodation_room_img_box">
      <img src="../images/room02.jpg" alt="room" className='img-fluid'/>
    </div>
   
    <div className="accommodation_room_heading_div">
    <h4 className="text-center">Superior Room</h4>
    <span className="fa fa-angle-double-right"></span>
    </div>
   
  </div>
 </Link>
</div>
{/* col end */}



{/* col start */}
<div className="col-lg-4 col-md-6 col-sm-12 col-12">
<Link to="/presidential-room">
<div className="accommodation_rooms_main_div">
    <div className="accommodation_room_img_box">
      <img src="../images/room03.jpg" alt="room" className='img-fluid'/>
    </div>
    <div className="accommodation_room_heading_div">
    <h4 className="text-center">Presidential Room</h4>
    <span className="fa fa-angle-double-right"></span>
    </div>

   
  </div>
</Link>
</div>
{/* col end */}





</div>
    
    {/* row end */}
  </div>
  {/* container end */}
</section>
{/* accommodation end section */}
        </>
    )
}

export default Accommodation;
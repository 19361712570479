import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import "../cssfiles/Header.css";
import Sidenavbar from './Sidenavbar';

const Header = () => {
  return (
    <>
   
      {/* navbar section start */}
      <nav className="navbar navbar-expand-lg navbar-light sticky-top">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src="../logo/logo.png" className='img-fluid' alt="" />
          </Link>
          {/* phone view icons s */}
          <div className="phone_view_icons_div">
            <Link to="#sidenavbar" className='offcanvas_btn bx bx-menu-alt-right' data-bs-toggle="offcanvas" aria-controls="offcanvasScrolling"></Link>
          </div>
          {/* end */}
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item"><Link className="nav-link" to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/>  Home</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/about-us"> <img src="../icons/aboutus.png" alt="home" className='img-fluid headericonimg'/>  About Us</Link></li>
             

              {/* dropdown start */}
              <li className="nav-item dropdown"><Link className="nav-link dropdown-toggle" data-bs-toggle="dropdown" to="javascript:void(0);"> <img src="../icons/services.png" alt="home" className='img-fluid headericonimg'/>  Services</Link>
              <ul className="dropdown-menu">
                
              {/* sub menu start */}
            <li className="darien-submenu"><Link className="dropdown-item" to="/rooms"> <img src="../icons/sericon04.png" alt="home" className='img-fluid headericonimg'/>  Rooms <i class="fa fa-angle-double-right"></i> </Link>
            <ul className="dropdown-menu "> 
             <li><Link className="dropdown-item" to="/executive-room"> <img src="../icons/room01.png" alt="home" className='img-fluid headericonimg'/> Execitive Rooms</Link></li>  
          <li><Link className="dropdown-item" to="/superior-room"> <img src="../icons/room02.png" alt="home" className='img-fluid headericonimg'/> Superior Rooms</Link></li>  
          <li><Link className="dropdown-item" to="/presential-room"> <img src="../icons/room03.png" alt="home" className='img-fluid headericonimg'/> Presential Rooms</Link></li>  
       
      </ul>
            </li>
            {/* sub menu end */}
            <li><Link className="dropdown-item" to="/accommodation"> <img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/>  Accommodation</Link></li>
            <li><Link className="dropdown-item" to="/tariff"> <img src="../icons/sericon03.png" alt="home" className='img-fluid headericonimg'/>  Tariff</Link></li>


            {/* sub menu start */}
            <li className="darien-submenu"><Link className="dropdown-item" to="javascript:void(0);"> <img src="../icons/facility.png" alt="home" className='img-fluid headericonimg'/>  Facility<i class="fa fa-angle-double-right"></i> </Link>
            <ul className="dropdown-menu "> 
             <li><Link className="dropdown-item" to="/big-size-pool"> <img src="../icons/fs01.png" alt="home" className='img-fluid headericonimg'/> Big Size Pool</Link></li>  
          <li><Link className="dropdown-item" to="/activity-area"> <img src="../icons/fs02.png" alt="home" className='img-fluid headericonimg'/> Activity Area</Link></li>  
          <li><Link className="dropdown-item" to="/pet-friendly"> <img src="../icons/fs03.png" alt="home" className='img-fluid headericonimg'/> Pet Friendly</Link></li>  
          <li><Link className="dropdown-item" to="/valet-parking"> <img src="../icons/fs04.png" alt="home" className='img-fluid headericonimg'/> Valet Parking</Link></li>  
          <li><Link className="dropdown-item" to="/walk-with-nature"> <img src="../icons/fs05.png" alt="home" className='img-fluid headericonimg'/> Walk With Nature</Link></li>  
          <li><Link className="dropdown-item" to="/yoga-meditation"> <img src="../icons/fs06.png" alt="home" className='img-fluid headericonimg'/> Yoga Meditation</Link></li>  
          <li><Link className="dropdown-item" to="/sun-rays-therapy"> <img src="../icons/fs07.png" alt="home" className='img-fluid headericonimg'/> Sun Rays Therapy</Link></li>  
          <li><Link className="dropdown-item" to="/water-therapy"> <img src="../icons/fs08.png" alt="home" className='img-fluid headericonimg'/> Water Therapy</Link></li>  
       
      </ul>
            </li>
            {/* sub menu end */}
             </ul>
              </li>
              {/* dropdown end */}

           

               {/* dropdown start */}
               <li className="nav-item dropdown"><Link className="nav-link dropdown-toggle" data-bs-toggle="dropdown" to="javascript:void(0);"> <img src="../icons/events.png" alt="home" className='img-fluid headericonimg'/>  Events</Link>
              <ul className="dropdown-menu">
            <li><Link className="dropdown-item" to="/destination-wedding"> <img src="../icons/destination.png" alt="home" className='img-fluid headericonimg'/>  Destination Wedding</Link></li>
            <li><Link className="dropdown-item" to="/corporate-meeting-events"> <img src="../icons/corporate.png" alt="home" className='img-fluid headericonimg'/>  Corporate Meeting or Events</Link></li>
            <li><Link className="dropdown-item" to="/pool-party"> <img src="../icons/pooparty.png" alt="home" className='img-fluid headericonimg'/>  Pool Party</Link></li>
            <li><Link className="dropdown-item" to="/college-group-events"> <img src="../icons/groupevent.png" alt="home" className='img-fluid headericonimg'/>  College Group Events</Link></li>
            <li><Link className="dropdown-item" to="/school-group-events"> <img src="../icons/schoolevent.png" alt="home" className='img-fluid headericonimg'/>  School Group Events</Link></li>
             </ul>
              </li>
              {/* dropdown end */}
            

               {/* dropdown start */}
               <li className="nav-item dropdown"><Link className="nav-link dropdown-toggle" data-bs-toggle="dropdown" to="javascript:void(0);"> <img src="../icons/safari.png" alt="home" className='img-fluid headericonimg'/>  Safari</Link>
              <ul className="dropdown-menu">
                  {/* sub menu start */}
            <li className="darien-submenu"><Link className="dropdown-item" to="javascript:void(0);"> <img src="../icons/nature.png" alt="home" className='img-fluid headericonimg'/>  Nature <i class="fa fa-angle-double-right"></i> </Link>
            <ul className="dropdown-menu "> 
             <li><Link className="dropdown-item" to="/kainchi-dham"> <img src="../icons/kainchidham.png" alt="home" className='img-fluid headericonimg'/>  Kainchi Dham</Link></li>  
          <li><Link className="dropdown-item" to="/garjiya-temple"> <img src="../icons/garjiyatemple.png" alt="home" className='img-fluid headericonimg'/>  Garjiya Temple</Link></li>  
          <li><Link className="dropdown-item" to="/hanuman-dham"> <img src="../icons/hanumandham.png" alt="home" className='img-fluid headericonimg'/>  Hanuman Dham</Link></li>  
          <li><Link className="dropdown-item" to="/corbett-museum"> <img src="../icons/corbettmuseum.png" alt="home" className='img-fluid headericonimg'/>  Corbett Museum</Link></li>  
          <li><Link className="dropdown-item" to="/ladwa-falls"> <img src="../icons/ladwafalls.png" alt="home" className='img-fluid headericonimg'/>  Ladwa Falls</Link></li>  
          <li><Link className="dropdown-item" to="/corbett-falls"> <img src="../icons/corbettfalls.png" alt="home" className='img-fluid headericonimg'/>  Corbett Falls</Link></li>  
          <li><Link className="dropdown-item" to="/nainital"> <img src="../icons/nainital.png" alt="home" className='img-fluid headericonimg'/>  Nainital</Link></li>  
          <li><Link className="dropdown-item" to="/bhimtal"> <img src="../icons/bhimtal.png" alt="home" className='img-fluid headericonimg'/>  Bhimtal</Link></li>  
          <li><Link className="dropdown-item" to="/naukuchiatal"> <img src="../icons/nakuchiatal.png" alt="home" className='img-fluid headericonimg'/>  Naukuchiatal</Link></li>  
       
      </ul>
            </li>
            {/* sub menu end */}

             {/* sub menu start */}
             <li className="darien-submenu"><Link className="dropdown-item" to="javascript:void(0);"> <img src="../icons/jungle.png" alt="home" className='img-fluid headericonimg'/>  Jungle<i class="fa fa-angle-double-right"></i> </Link>
            <ul className="dropdown-menu "> 
             <li><Link className="dropdown-item" to="/river-side"> <img src="../icons/riverside.png" alt="home" className='img-fluid headericonimg'/>  River Side</Link></li>  
          <li><Link className="dropdown-item" to="/view-points"> <img src="../icons/viewpoints.png" alt="home" className='img-fluid headericonimg'/>  View Points</Link></li>  
          <li><Link className="dropdown-item" to="/side-scenes"> <img src="../icons/sidescenes.png" alt="home" className='img-fluid headericonimg'/>  Side Scenes </Link></li>  
          
      </ul>
            </li>
            {/* sub menu end */}

              {/* sub menu start */}
              <li className="darien-submenu"><Link className="dropdown-item" to="javascript:void(0);"> <img src="../icons/sightseeing.png" alt="home" className='img-fluid headericonimg'/>  Sightseeing<i class="fa fa-angle-double-right"></i> </Link>
            <ul className="dropdown-menu "> 
             <li><Link className="dropdown-item" to="/garjiya-devi-temple"> <img src="../icons/Garjia01.png" alt="home" className='img-fluid headericonimg'/>  Garjia Devi temple</Link></li>  
          <li><Link className="dropdown-item" to="/hanuman-dham"> <img src="../icons/hanumandham.png" alt="home" className='img-fluid headericonimg'/>  Hanuman Dham</Link></li>  
          <li><Link className="dropdown-item" to="/corbett-museum"> <img src="../icons/corbettmuseum.png" alt="home" className='img-fluid headericonimg'/>  Corbett Museum  </Link></li>  

          <li><Link className="dropdown-item" to="/corbett-falls"> <img src="../icons/corbettfalls.png" alt="home" className='img-fluid headericonimg'/>  Corbett Fall  </Link></li>  

          <li><Link className="dropdown-item" to="/barati-rau-waterfall"> <img src="../icons/sidescenes.png" alt="home" className='img-fluid headericonimg'/>  Barati Rau Waterfall  </Link></li>  

          <li><Link className="dropdown-item" to="/nainital"> <img src="../icons/nainital.png" alt="home" className='img-fluid headericonimg'/>  Nanitaal</Link></li>  

          <li><Link className="dropdown-item" to="/neem-karoli-baba"> <img src="../icons/neem.png" alt="home" className='img-fluid headericonimg'/>  Neem Karoli Baba  </Link></li>  
          
      </ul>
            </li>
            {/* sub menu end */}
            
            </ul>
            </li>
               {/* dropdown end */}

               {/* dropdown start */}
               <li className="nav-item dropdown"><Link className="nav-link dropdown-toggle" data-bs-toggle="dropdown" to="javascript:void(0);"> <img src="../icons/activities.png" alt="home" className='img-fluid headericonimg'/>  Activities</Link>
              <ul className="dropdown-menu">
                  {/* sub menu start */}
            <li className="darien-submenu"><Link className="dropdown-item" to="javascript:void(0);"> <img src="../icons/indoor.png" alt="home" className='img-fluid headericonimg'/>  Indoor Activities<i class="fa fa-angle-double-right"></i> </Link>
            <ul className="dropdown-menu "> 
             <li><Link className="dropdown-item" to="/pool-table"> <img src="../icons/pooltable.png" alt="home" className='img-fluid headericonimg'/>  Pool Table</Link></li>  
          <li><Link className="dropdown-item" to="/carrom"> <img src="../icons/carrom.png" alt="home" className='img-fluid headericonimg'/>  Carrom</Link></li>  
          <li><Link className="dropdown-item" to="/ludo"> <img src="../icons/ludo.png" alt="home" className='img-fluid headericonimg'/>  Ludo</Link></li>  
          <li><Link className="dropdown-item" to="/chess"> <img src="../icons/chess.png" alt="home" className='img-fluid headericonimg'/>  Chess</Link></li>  
       
       </ul>
            </li>
            {/* sub menu end */}

             {/* sub menu start */}
             <li className="darien-submenu"><Link className="dropdown-item" to="javascript:void(0);"> <img src="../icons/outdoor.png" alt="home" className='img-fluid headericonimg'/>  Outdoor Activities<i class="fa fa-angle-double-right"></i> </Link>
            <ul className="dropdown-menu "> 
             <li><Link className="dropdown-item" to="/football"> <img src="../icons/football.png" alt="home" className='img-fluid headericonimg'/>  Football</Link></li>  
          <li><Link className="dropdown-item" to="/cricket"> <img src="../icons/cricket.png" alt="home" className='img-fluid headericonimg'/>  Cricket</Link></li>  
          <li><Link className="dropdown-item" to="/pool-volleyball"> <img src="../icons/poolvolleyball.png" alt="home" className='img-fluid headericonimg'/>  Pool Volleyball </Link></li>  
          <li><Link className="dropdown-item" to="/badminton"> <img src="../icons/badminton.png" alt="home" className='img-fluid headericonimg'/>  Badminton</Link></li>  
          <li><Link className="dropdown-item" to="/trampoline"> <img src="../icons/trampoline.png" alt="home" className='img-fluid headericonimg'/>  Trampoline</Link></li>  
          <li><Link className="dropdown-item" to="/mickey-mouse"> <img src="../icons/mickeymouse.png" alt="home" className='img-fluid headericonimg'/>  Mickey Mouse (Bouncee)</Link></li>  
       
      </ul>
            </li>
            {/* sub menu end */}
            
            </ul>
            </li>
               {/* dropdown end */}

            
              <li className="nav-item"><Link className="nav-link" to="contact-us"> <img src="../icons/contactus.png" alt="home" className='img-fluid headericonimg'/>  Contact Us</Link></li>
            </ul>

            {/* book now btn start */}
            <ul className="ms-auto navbar_btn_booknow">
            <li><Link className="nav-link" to="/contact-us">Book Now</Link></li>
            </ul>
            {/* book now btn start */}
           
          </div>
        </div>
      </nav>
      {/* end navr section */}

{/*  */}
<Sidenavbar/>
{/*  */}
      

    </>
  );
}

export default Header;

import React from 'react'
import { Link } from 'react-router-dom';

const Collegegroupevents = () => {
  return (
    <>
     {/* pagte titile section start  */}
<section className="page_title_mkp_section page_title_mkp_section3">
  <div className="page_ti_overly_mkp_div">
    <div className="container">
      <div className="row">
        {/* col start */}
        <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
          <div className="page_title_tax_mkp_main_div">
            <div className="page_title_tax_mkp_div">
              <h1>College Group Events</h1>
              <ul>
                <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
                <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/> College Group Events</li>
              </ul>
            </div>
          </div>
        </div>
        {/* col end */}
      </div>
    </div>
  </div>
</section>
{/* pagte titile section end */}


{/* accommodation start section */}
<section className="accommodation_section">
  {/* container start */}
  <div className="container">
    {/* row start */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_img_box_main">
          <img src="../images/college01.avif" alt="accommodation" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

        {/* col start */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_text_box_main">
          <h1>College Group Events</h1>
          <p>College group events are social, academic, or recreational activities organized for groups of students to promote engagement, networking, and a sense of community. These events can range from informal gatherings to formal functions and often reflect the interests and goals of the group, club, or organization behind them. College events are an excellent way to meet new people, relax, learn something new, or just have fun. They can also serve as a way to bond with peers and make lasting memories during your college years.</p>

          <h2>Types of College Group Events:</h2>
          <p><b>1. Social Gatherings:</b></p>
          <ul>
           <li><b>Theme Parties:</b> Often organized by student organizations or dorms, theme parties can range from costume parties (like Halloween) to decade parties (like the '90s). </li>
           <li><b>BBQs & Picnic Events:</b> Informal outdoor gatherings with food, games, and music, where students can relax and socialize. </li>
           <li><b>Game Nights:</b> Organize trivia, board games, or video game competitions to bring students together for a night of friendly competition. </li>
          </ul>

        
        </div>
      </div>
      {/* col end */}

      
        {/* col start */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_text_box_main">
       <p><b>2. Academic and Professional Events:</b></p>
       <ul>
        <li><b>Workshops and Seminars:</b> These events offer students opportunities to learn new skills, such as resume building, interview prep, or academic writing workshops. </li>
        <li><b>Guest Speakers and Panels:</b> Inviting professionals, alumni, or experts in a specific field to speak about their experiences or provide advice.</li>
        <li><b>Networking Events:</b> These are perfect for students to meet potential mentors, future employers, or alumni in a professional setting, often paired with refreshments or mixers. </li>
       </ul>

       <p><b>3. Cultural Events:</b></p>
       <ul>
        <li><b>International Food Festivals:</b> Students can share and celebrate their culture by cooking and presenting traditional dishes from different countries or regions. </li>
        <li><b>Multicultural Fairs:</b> Showcase performances, art, music, and dance from various cultures, highlighting the diversity within the student body.</li>
        <li><b>Movie Nights/Film Screenings:</b> Often themed around specific genres or topics (e.g., a documentary night, film festivals, or foreign-language films).</li>
       </ul>
        </div>
      </div>
      {/* col end */}

      {/* col start */}
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_img_box_main">
          <img src="../images/college02.avif" alt="accommodation" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

    </div>
    {/* row end */}
  </div>
  {/* container end */}
</section>
{/* accommodation end section */}

    </>
  )
}

export default Collegegroupevents;

import { Link } from "react-router-dom";

const Dining = () =>{
    return(
        <>
        {/* pagte titile section start  */}
 <section className="page_title_mkp_section page_title_mkp_section3">
   <div className="page_ti_overly_mkp_div">
     <div className="container">
       <div className="row">
         {/* col start */}
         <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
           <div className="page_title_tax_mkp_main_div">
             <div className="page_title_tax_mkp_div">
               <h1>Dining at The Darien Resort</h1>
               <ul>
                 <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
                 <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/>  Dining at The Darien Resort</li>
               </ul>
             </div>
           </div>
         </div>
         {/* col end */}
       </div>
     </div>
   </div>
 </section>
 {/* pagte titile section end */}
 
 {/* accommodation start section */}
 <section className="accommodation_section">
   {/* container start */}
   <div className="container">
     {/* row start */}
     <div className="row">
       {/* col start */}
       <div className="col-lg-6 col-md-12 col-sm-12 col-12">
         <div className="accommodation_img_box_main">
           <img src="../images/service05.avif" alt="accommodation" className="img-fluid"/>
         </div>
       </div>
       {/* col end */}
 
         {/* col start */}
         <div className="col-lg-6 col-md-12 col-sm-12 col-12">
         <div className="accommodation_text_box_main">
           <h1>Dining</h1>
           <p>At <b>The Darien Resort</b>, dining is more than just a meal—it’s an experience that combines exquisite cuisine with the beauty of nature. With a range of dining options, guests can savor the flavors of the world, all while enjoying the stunning surroundings and exceptional service. Whether you’re looking for a romantic dinner by the beach or a casual lunch by the pool, each venue offers something unique.</p>
 
           <h2>1. The Oceanview Restaurant</h2>
          <p>Cuisine: Gourmet International & Local Fusion</p>
          <p>Setting: Oceanfront with panoramic views</p>
          <p>The Oceanview Restaurant is the resort’s signature dining experience, offering a breathtaking view of the ocean with each meal. Here, guests can indulge in a carefully curated menu of gourmet international dishes with a twist of local flavors. The chefs use only the freshest ingredients, many sourced directly from local farmers and the sea, ensuring an unforgettable dining experience. </p>

            
          
         </div>
       </div>
       {/* col end */}
 
       
         {/* col start */}
         <div className="col-lg-6 col-md-12 col-sm-12 col-12">
         <div className="accommodation_text_box_main">
           
         
        <h3>Highlights:</h3>
        <ul>
            <li>Fresh seafood dishes such as grilled lobster and ceviche.</li>
            <li>Signature cocktails made with tropical fruits and local spirits.</li>
            <li>A sophisticated wine list featuring local and international wines. </li>
        </ul>

        <h3>2. The Tropical Garden Café</h3>
        <p><b>Cuisine:</b> Light & Healthy Fare, Mediterranean-inspired</p>
        <p><b>Setting:</b> Surrounded by lush tropical gardens</p>
        <p>For a more casual and laid-back dining experience, The Tropical Garden Café offers a serene atmosphere where you can enjoy fresh, healthy dishes. Inspired by Mediterranean cuisine, the café offers light fare like salads, sandwiches, and fresh fruit bowls, all made from the finest ingredients. It’s the perfect place to relax and refresh after a morning of exploration or enjoy a light lunch in nature’s embrace.        </p>

        <h3>Highlights:</h3>
        <ul>
            <li>Freshly prepared salads with local, organic ingredients.            </li>
            <li>Smoothie bowls and fruit platters.            </li>
            <li>Vegan and vegetarian options, made to order.            </li>
        </ul>
         </div>
       </div>
       {/* col end */}
 
       {/* col start */}
       <div className="col-lg-6 col-md-12 col-sm-12 col-12">
         <div className="accommodation_img_box_main">
           <img src="../images/service055.avif" alt="accommodation" className="img-fluid"/>
         </div>
       </div>
       {/* col end */}

       
         {/* col start */}
         <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         <div className="accommodation_text_box_main">
           
        <h3>3. The Jungle Bar & Grill </h3>
        <p>Cuisine: Barbecue & Grill Specialties        </p>
        <p>Setting: Outdoor patio amidst the jungle, perfect for sunset dining</p>
        <p>For a more adventurous dining experience, the Jungle Bar & Grill provides a fun and relaxed atmosphere where you can enjoy barbecue and grilled specialties. Located under the shade of towering trees, it’s the ideal spot to unwind with a cocktail and savor delicious grilled meats, fresh fish, and flavorful sides. This vibrant venue offers a true taste of the resort’s natural surroundings.        </p>

        <h3>Highlights:</h3>
        <ul>
            <li>Grilled steaks, ribs, and seafood skewers.            </li>
            <li>Wood-fired pizzas with fresh toppings.            </li>
            <li>A wide range of craft beers and tropical cocktails.            </li>
        </ul>

        <h3>4. The Wine & Dine Lounge        </h3>
        <p>Cuisine: Fine Dining & Tasting Menus        </p>
        <p>Setting: Intimate, elegant lounge with a cozy fireplace</p>
        <p>For those seeking a more refined culinary experience, The Wine & Dine Lounge is the perfect spot. This intimate space offers a fine dining experience with multi-course tasting menus paired perfectly with exquisite wines. Guests can indulge in the flavors of expertly crafted dishes while enjoying a carefully curated wine selection, making it the ideal setting for special occasions, celebrations, or romantic dinners.        </p>

        <h3>Highlights:</h3>
        <ul>
            <li>Seasonal tasting menus with expertly paired wines.            </li>
            <li>Private wine cellar tours with a sommelier.            </li>
            <li>Signature desserts and handcrafted cocktails.            </li>
        </ul>
         </div>
       </div>
       {/* col end */}
 
     </div>
     {/* row end */}
   </div>
   {/* container end */}
 </section>
 {/* accommodation end section */}
         </>
    )
}

export default Dining;
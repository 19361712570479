import React from 'react'
import { Link } from 'react-router-dom'

const Poolvolleyball = () => {
  return (
    <>
    {/* pagte titile section start  */}
<section className="page_title_mkp_section page_title_mkp_section3">
<div className="page_ti_overly_mkp_div">
 <div className="container">
   <div className="row">
     {/* col start */}
     <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
       <div className="page_title_tax_mkp_main_div">
         <div className="page_title_tax_mkp_div">
           <h1>Pool Volleyball</h1>
           <ul>
             <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
             <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/> Pool Volleyball</li>
           </ul>
         </div>
       </div>
     </div>
     {/* col end */}
   </div>
 </div>
</div>
</section>
{/* pagte titile section end */}


{/* accommodation start section */}
<section className="accommodation_section">
{/* container start */}
<div className="container">
 {/* row start */}
 <div className="row">
   {/* col start */}
   <div className="col-lg-6 col-md-12 col-sm-12 col-12">
     <div className="accommodation_img_box_main">
       <img src="../images/bigsizepool.jpg" alt="accommodation" className="img-fluid"/>
     </div>
   </div>
   {/* col end */}

     {/* col start */}
     <div className="col-lg-6 col-md-12 col-sm-12 col-12">
     <div className="accommodation_text_box_main">
       <h1>Pool Volleyball</h1>
       <p>Pool Volleyball is a variant of the traditional volleyball game played in a swimming pool, combining elements of volleyball with the fun of water-based activities. The game is typically played in a shallow area of a pool, with players on either side of a net. The rules and basic principles of regular volleyball remain the same, but the water adds an extra layer of challenge and excitement.</p>
       <p>In Pool Volleyball, each team aims to send the volleyball over the net and into the opponent's side of the pool, with the goal of scoring points by ensuring the ball lands in the opponent's area or if the opponent fails to return the ball within the rules. Players can use the same volleyball techniques, such as serving, passing, setting, and spiking, but the water resistance affects how they move and react to the ball.</p>
       <p>Typically, the pool is divided into two sides by a net, and players on each team take turns hitting the ball back and forth. The team that wins a point earns the right to serve. Pool volleyball can be played with two, four, or more players per side, with the most common setup being teams of four. The game can be played for fun in casual settings or in a more competitive manner in organized tournaments, with rules adapted to fit the water environment.</p>
       <p>The game is especially popular during the summer months and at resorts or pool parties, as it allows for active gameplay while staying cool in the water. The water element makes it a fun and refreshing alternative to traditional volleyball, requiring coordination, teamwork, and good swimming skills.</p>
     </div>
   </div>
   {/* col end */}


 </div>
 {/* row end */}
</div>
{/* container end */}
</section>
{/* accommodation end section */}

 </>
 
  )
}

export default Poolvolleyball

import React from 'react'
import { Link } from 'react-router-dom';

const Garjiadevitemple = () => {
  return (
     <>
           {/* pagte titile section start  */}
    <section className="page_title_mkp_section page_title_mkp_section3">
      <div className="page_ti_overly_mkp_div">
        <div className="container">
          <div className="row">
            {/* col start */}
            <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
              <div className="page_title_tax_mkp_main_div">
                <div className="page_title_tax_mkp_div">
                  <h1>Garjiya Devi temple</h1>
                  <ul>
                    <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
                    <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/> Garjiya Devi temple</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* col end */}
          </div>
        </div>
      </div>
    </section>
    {/* pagte titile section end */}
    
    
    {/* accommodation start section */}
    <section className="accommodation_section">
      {/* container start */}
      <div className="container">
        {/* row start */}
        <div className="row">
          {/* col start */}
          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="accommodation_img_box_main">
              <img src="../images/garjiya01.webp" alt="accommodation" className="img-fluid"/>
            </div>
          </div>
          {/* col end */}
    
            {/* col start */}
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="accommodation_text_box_main">
              <h1>Garjiya Devi temple</h1>
              <p>Garjiya Devi temple is a renowned Hindu temple located in the Nainital district of Uttarakhand, India. It is dedicated to Goddess Garjiya, a form of Goddess Parvati, who is worshipped here by many devotees. The temple is situated on the banks of the Kosi River and is famous for its serene location, spiritual significance, and the breathtaking natural beauty surrounding it. Garjiya Devi temple attracts pilgrims and tourists from across India, especially during festive seasons.</p>
    
              <h2>Key Features of Garjiya Devi temple:</h2>
              <p><b>Location and Scenic Beauty:</b></p>
              <ul>
               <li>The Garjiya Devi temple is perched on a small hilltop, overlooking the Kosi River. The temple is surrounded by lush green forests and offers stunning views of the river and the surrounding hills. The location itself is peaceful, making it an ideal spot for both spiritual reflection and nature lovers.           </li>
              
              </ul>
    
            </div>
          </div>
          {/* col end */}
    
          
            {/* col start */}
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="accommodation_text_box_main">
         
            <ul>
            <li>The temple is approximately 14 km from Ramnagar and about 30 km from Nainital. The serene environment, combined with the scenic beauty of the region, adds to the temple's charm.</li>
            </ul>
    
            <p><b>2. Deity and Significance:</b></p>
            <ul>
                <li>The primary deity worshipped in the Garjiya Devi temple is Goddess Garjiya, a manifestation of Goddess Parvati. Devotees believe that worshipping Goddess Garjiya brings blessings of prosperity, health, and happiness. The temple is especially popular among those seeking the divine blessings of the goddess for family welfare and success.  </li>
                <li>It is also believed that the goddess protects devotees from evil and fulfills their wishes. The temple holds a special place in the hearts of devotees, especially women, who visit to pray for a blissful married life, fertility, and children.            </li>
            </ul>
    
            <p><b>3. Temple Architecture:</b></p>
            <ul>
                <li>The temple structure is simple yet beautiful, reflecting traditional Himalayan temple architecture. It is built with stone and has a serene and calm atmosphere conducive to meditation and prayer.</li>
                <li>A small shrine houses the idol of Goddess Garjiya, and the surroundings are often adorned with flowers and offerings from devotees. </li>
            </ul>
            </div>
          </div>
          {/* col end */}
    
          {/* col start */}
          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="accommodation_img_box_main">
              <img src="../images/garjiya02.jpg" alt="accommodation" className="img-fluid"/>
            </div>
          </div>
          {/* col end */}
    
        </div>
        {/* row end */}
      </div>
      {/* container end */}
    </section>
    {/* accommodation end section */}
    
        </>
  )
}

export default  Garjiadevitemple;

import React from 'react'
import { Link } from 'react-router-dom'

const badminton = () => {
  return (
    <>
    {/* pagte titile section start  */}
<section className="page_title_mkp_section page_title_mkp_section3">
<div className="page_ti_overly_mkp_div">
 <div className="container">
   <div className="row">
     {/* col start */}
     <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
       <div className="page_title_tax_mkp_main_div">
         <div className="page_title_tax_mkp_div">
           <h1>Badminton</h1>
           <ul>
             <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
             <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/> Badminton</li>
           </ul>
         </div>
       </div>
     </div>
     {/* col end */}
   </div>
 </div>
</div>
</section>
{/* pagte titile section end */}


{/* accommodation start section */}
<section className="accommodation_section">
{/* container start */}
<div className="container">
 {/* row start */}
 <div className="row">
   {/* col start */}
   <div className="col-lg-6 col-md-12 col-sm-12 col-12">
     <div className="accommodation_img_box_main">
       <img src="../images/bigsizepool.jpg" alt="accommodation" className="img-fluid"/>
     </div>
   </div>
   {/* col end */}

     {/* col start */}
     <div className="col-lg-6 col-md-12 col-sm-12 col-12">
     <div className="accommodation_text_box_main">
       <h1>Badminton</h1>
    <p>Badminton is a fast-paced racket sport played either individually (singles) or in teams of two (doubles). The game is played on a rectangular court divided by a net, with players or teams aiming to score points by hitting a shuttlecock over the net and into the opponent’s side of the court. The shuttlecock is a lightweight projectile made of feathers or plastic, designed to fly with a distinctive trajectory.</p>
    <p>The game begins with a serve, where the server must hit the shuttlecock underhand from behind the service line into the opponent's side. The opponent must then return the shuttlecock, and the rally continues until one side fails to return the shuttlecock within the rules. Points are awarded when the shuttlecock lands in the opponent's side of the court, or if the opponent commits a fault (such as failing to hit the shuttlecock over the net, hitting it out of bounds, or making illegal touches).</p>

    <p>Badminton requires quick reflexes, agility, and excellent hand-eye coordination. Players use a variety of shots, including smashes, clears, drops, and drives, to outmaneuver their opponents. The shuttlecock’s unique flight characteristics, with its fast pace and ability to decelerate quickly, make badminton an exciting and dynamic game.</p>

    <p>Matches are typically played to a set number of points (usually 21), and players must win by a margin of at least two points. A match is generally played in a best-of-three format. Badminton is played at both recreational and competitive levels, with major international tournaments such as the BWF World Championships and the Olympic Games showcasing top players. The sport is particularly popular in countries such as China, Indonesia, India, and Denmark.</p>
     </div>
   </div>
   {/* col end */}


 </div>
 {/* row end */}
</div>
{/* container end */}
</section>
{/* accommodation end section */}

 </>
 
  )
}

export default badminton

import React from 'react'
import { Link } from 'react-router-dom';

const Viewpoints = () => {
  return (
    <>
        {/* pagte titile section start  */}
<section className="page_title_mkp_section page_title_mkp_section3">
  <div className="page_ti_overly_mkp_div">
    <div className="container">
      <div className="row">
        {/* col start */}
        <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
          <div className="page_title_tax_mkp_main_div">
            <div className="page_title_tax_mkp_div">
              <h1>View Points</h1>
              <ul>
                <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
                <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/> View Points</li>
              </ul>
            </div>
          </div>
        </div>
        {/* col end */}
      </div>
    </div>
  </div>
</section>
{/* pagte titile section end */}


{/* accommodation start section */}
<section className="accommodation_section">
  {/* container start */}
  <div className="container">
    {/* row start */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_img_box_main">
          <img src="../images/view01.jpg" alt="accommodation" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

        {/* col start */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_text_box_main">
          <h1>View Points</h1>
          <p>A viewpoint is a location offering breathtaking, often panoramic views of the surrounding landscape. Typically situated at higher elevations, viewpoints provide expansive vistas of natural features such as mountains, valleys, rivers, lakes, and forests. These spots are perfect for sightseeing, photography, or simply appreciating the beauty of nature. Many viewpoints are accessible after a hike or trek, adding an element of adventure to the experience. Some offer particularly stunning views during sunrise or sunset, where the shifting light creates a magical atmosphere. The peaceful ambiance, combined with the stunning scenery, makes viewpoints ideal for relaxation, meditation, or enjoying moments of solitude.</p>

          <p>At these locations, visitors often have the opportunity to capture memorable photographs, whether it's of sweeping mountain ranges, vibrant cityscapes, or serene bodies of water. The air is usually fresh and crisp, and the surroundings are often teeming with wildlife or lush vegetation, making it an ideal setting for nature lovers. Additionally, viewpoints in areas like national parks or hills offer a unique opportunity to witness rare bird species and diverse ecosystems.</p>

        </div>
      </div>
      {/* col end */}

      
    </div>
    {/* row end */}
  </div>
  {/* container end */}
</section>
{/* accommodation end section */}

    </>
  )
}

export default Viewpoints;

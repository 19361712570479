import React from 'react'
import { Link } from 'react-router-dom';

const Corbettfalls = () => {
  return (
    <>
       {/* pagte titile section start  */}
<section className="page_title_mkp_section page_title_mkp_section3">
  <div className="page_ti_overly_mkp_div">
    <div className="container">
      <div className="row">
        {/* col start */}
        <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
          <div className="page_title_tax_mkp_main_div">
            <div className="page_title_tax_mkp_div">
              <h1>Corbett Falls</h1>
              <ul>
                <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
                <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/> Corbett Falls</li>
              </ul>
            </div>
          </div>
        </div>
        {/* col end */}
      </div>
    </div>
  </div>
</section>
{/* pagte titile section end */}


{/* accommodation start section */}
<section className="accommodation_section">
  {/* container start */}
  <div className="container">
    {/* row start */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_img_box_main">
          <img src="../images/corbettfall01.jpg" alt="accommodation" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

        {/* col start */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_text_box_main">
          <h1>Corbett Falls</h1>
          <p>Corbett Falls is a picturesque waterfall located in the Jim Corbett National Park in Uttarakhand, India. Situated around 25 km from Ramnagar, the falls are nestled amidst the beautiful sal forests and lush greenery of the park, making it a popular spot for nature lovers, wildlife enthusiasts, and tourists visiting the park. The falls are named after Jim Corbett, the famous hunter-turned-conservationist who played a key role in the establishment of the park.</p>

          <h2>Key Features of Corbett Falls: </h2>
          <p><b>Scenic Beauty:</b></p>
          <ul>
        <li>Corbett Falls is known for its natural beauty, with the water cascading down from a height of approximately 66 feet into a small pool. The falls are surrounded by dense forest, making it an ideal spot for those looking to enjoy the tranquility and serenity of nature. </li>
        <li>The sound of water flowing and the lush green environment surrounding the falls makes it a peaceful and refreshing place to visit.   </li>
          
          </ul>

        </div>
      </div>
      {/* col end */}

      
        {/* col start */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_text_box_main">
     
      <p><b>2. Location:</b></p>
      <ul>
        <li>The falls are located within the Jim Corbett National Park, specifically near the Dhela Range, around 25 km from the town of Ramnagar.        </li>
        <li>Visitors to Corbett Falls can enjoy a short trek through the park's forested areas, providing an opportunity to experience the diverse flora and fauna of the park.        </li>
      </ul>

      <p><b>3. Trekking and Adventure:</b></p>
      <ul>
        <li>Getting to Corbett Falls requires a short trek through the park's sal forests. The trek is easy and accessible, making it suitable for people of all ages. Along the way, trekkers can enjoy the lush forest environment and, with a bit of luck, spot wildlife like deer, monkeys, and various bird species.        </li>
        <li>The area is perfect for nature walks and offers the opportunity for visitors to relax, have a picnic, or simply enjoy the beauty of the surroundings.        </li>
      </ul>

      <p><b>4. Wildlife and Birdwatching:</b></p>
      <ul>
        <li>The Jim Corbett National Park is home to a rich variety of wildlife, including tigers, leopards, elephants, and bears. While visiting Corbett Falls, trekkers may also encounter some of these animals, particularly around the forested areas.        </li>
        <li>The region is also great for birdwatching, as Corbett National Park is home to a wide variety of bird species. Early morning and evening visits are ideal for spotting birds like the Indian Peafowl, hornbills, and various species of raptors and waterfowl.        </li>
      </ul>
        </div>
      </div>
      {/* col end */}

      {/* col start */}
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_img_box_main">
          <img src="../images/corbettfall02.jpg" alt="accommodation" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

    </div>
    {/* row end */}
  </div>
  {/* container end */}
</section>
{/* accommodation end section */}

    </>
  )
}

export default Corbettfalls;

import React from 'react'
import { Link } from 'react-router-dom';

const Hanumandham = () => {
  return (
    <>
       {/* pagte titile section start  */}
<section className="page_title_mkp_section page_title_mkp_section3">
  <div className="page_ti_overly_mkp_div">
    <div className="container">
      <div className="row">
        {/* col start */}
        <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
          <div className="page_title_tax_mkp_main_div">
            <div className="page_title_tax_mkp_div">
              <h1>Hanuman Dham</h1>
              <ul>
                <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
                <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/> Hanuman Dham</li>
              </ul>
            </div>
          </div>
        </div>
        {/* col end */}
      </div>
    </div>
  </div>
</section>
{/* pagte titile section end */}


{/* accommodation start section */}
<section className="accommodation_section">
  {/* container start */}
  <div className="container">
    {/* row start */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_img_box_main">
          <img src="../images/hanuman01.jpg" alt="accommodation" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

        {/* col start */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_text_box_main">
          <h1>Hanuman Dham</h1>
          <p>Hanuman Dham is a prominent Hindu temple dedicated to Lord Hanuman, located in Nainital district, Uttarakhand, India. The temple is a significant religious site for devotees of Lord Hanuman and attracts many pilgrims seeking blessings for strength, courage, and protection. Hanuman, a central figure in Hindu mythology and a symbol of devotion, loyalty, and bravery, is widely worshipped at this temple.</p>

          <h2>Key Features of Hanuman Dham: </h2>
          <p><b>1. Temple Location and Scenic Beauty:</b></p>
          <ul>
           <li>Hanuman Dham is situated in the picturesque Nainital district of Uttarakhand, surrounded by the Himalayan hills. The temple's location offers stunning views of the lush greenery, hills, and valleys, making it a peaceful and serene place to visit.   </li>
           <li>The temple complex is nestled amidst natural beauty, which adds to the spiritual and peaceful atmosphere, offering both spiritual solace and a great experience for nature lovers.           </li>
          
          </ul>

        </div>
      </div>
      {/* col end */}

      
        {/* col start */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_text_box_main">
     
       <p>2. Deity Worshipped:</p>
       <ul>
        <li>The primary deity in Hanuman Dham is Lord Hanuman, the Hindu god who symbolizes strength, devotion, and protection. Lord Hanuman is particularly revered for his loyalty to Lord Rama, his courage, and his selfless service.        </li>
        <li>The temple houses a large, beautifully crafted idol of Lord Hanuman in a sitting posture, and devotees come to pray for strength, overcoming obstacles, and protection from evil.        </li>
       </ul>

       <p><b>3. Spiritual Importance:</b></p>
       <ul>
        <li>Hanuman Dham is considered a sacred site where devotees can offer their prayers and seek blessings for physical, mental, and spiritual strength. The temple is particularly revered by those facing challenges or difficulties in life, as Lord Hanuman is believed to help devotees overcome adversities.        </li>
        <li>It is also a place where many people come to perform special pujas, recite Hanuman Chalisa, and seek divine intervention in various aspects of their lives.
        </li>
       </ul>
        </div>
      </div>
      {/* col end */}

      {/* col start */}
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_img_box_main">
          <img src="../images/hanuman02.jpg" alt="accommodation" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

    </div>
    {/* row end */}
  </div>
  {/* container end */}
</section>
{/* accommodation end section */}

    </>
  )
}

export default Hanumandham;

import React from 'react'
import { Link } from 'react-router-dom';

const Carrom = () => {
  return (
    <>
    {/* pagte titile section start  */}
<section className="page_title_mkp_section page_title_mkp_section3">
<div className="page_ti_overly_mkp_div">
 <div className="container">
   <div className="row">
     {/* col start */}
     <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
       <div className="page_title_tax_mkp_main_div">
         <div className="page_title_tax_mkp_div">
           <h1>Carrom</h1>
           <ul>
             <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
             <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/> Carrom</li>
           </ul>
         </div>
       </div>
     </div>
     {/* col end */}
   </div>
 </div>
</div>
</section>
{/* pagte titile section end */}


{/* accommodation start section */}
<section className="accommodation_section">
{/* container start */}
<div className="container">
 {/* row start */}
 <div className="row">
   {/* col start */}
   <div className="col-lg-6 col-md-12 col-sm-12 col-12">
     <div className="accommodation_img_box_main">
       <img src="../images/bigsizepool.jpg" alt="accommodation" className="img-fluid"/>
     </div>
   </div>
   {/* col end */}

     {/* col start */}
     <div className="col-lg-6 col-md-12 col-sm-12 col-12">
     <div className="accommodation_text_box_main">
       <h1>Carrom</h1>
       <p>Carrom is a fast-paced indoor game that combines skill, precision, and strategy, typically played on a flat, square wooden board. The objective is to use a striker, a larger disc, to flick smaller wooden discs called carrom men into one of the four corner pockets. Players take turns striking the discs using their fingers. The game is usually played with two or four players, with two teams of two in the doubles format.</p>
       <p>The game starts with the carrom men arranged in the center of the board, with the queen (a red piece) placed in the middle. Players take turns trying to pocket the carrom men of their color (black or white), and the player or team that pockets all their pieces, including the queen, first, wins. The queen is a special piece that needs to be pocketed and "covered" by sinking one of the player's own carrom men immediately afterward to secure the win.</p>
       <p>In singles, two players compete individually, while in doubles, two teams of two players face off, with each team member alternating turns. Carrom requires careful strategy, as players must think ahead, not only aiming to pocket their own pieces but also trying to block their opponent's shots and maintain control of the board.</p>
       <p>The game involves various techniques, including flicking, striking (using the striker to hit the carrom men), and positioning. Players often use skillful moves to "set up" future shots, redirecting discs with the striker to create opportunities for their next turn.</p>
       <p>Carrom is enjoyed casually by families and friends, and it is also played professionally in tournaments worldwide. It has a strong cultural presence, particularly in countries like India, Sri Lanka, and parts of Southeast Asia. The game promotes critical thinking, hand-eye coordination, and social interaction, making it a fun and engaging activity for all ages.</p>
     </div>
   </div>
   {/* col end */}


 </div>
 {/* row end */}
</div>
{/* container end */}
</section>
{/* accommodation end section */}

 </>
 
  )
}

export default Carrom;

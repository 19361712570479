import React from 'react'
import { Link } from 'react-router-dom';

const Ludo = () => {
  return (
    <>
    {/* pagte titile section start  */}
<section className="page_title_mkp_section page_title_mkp_section3">
<div className="page_ti_overly_mkp_div">
 <div className="container">
   <div className="row">
     {/* col start */}
     <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
       <div className="page_title_tax_mkp_main_div">
         <div className="page_title_tax_mkp_div">
           <h1>Ludo</h1>
           <ul>
             <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
             <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/> Ludo</li>
           </ul>
         </div>
       </div>
     </div>
     {/* col end */}
   </div>
 </div>
</div>
</section>
{/* pagte titile section end */}


{/* accommodation start section */}
<section className="accommodation_section">
{/* container start */}
<div className="container">
 {/* row start */}
 <div className="row">
   {/* col start */}
   <div className="col-lg-6 col-md-12 col-sm-12 col-12">
     <div className="accommodation_img_box_main">
       <img src="../images/bigsizepool.jpg" alt="accommodation" className="img-fluid"/>
     </div>
   </div>
   {/* col end */}

     {/* col start */}
     <div className="col-lg-6 col-md-12 col-sm-12 col-12">
     <div className="accommodation_text_box_main">
       <h1>Ludo</h1>
       <p>Ludo is a traditional board game that is enjoyed by people of all ages, typically played by 2 to 4 players. The game is played on a square board, which is divided into a grid of squares, with each player assigned a color (usually red, yellow, green, or blue). The objective of the game is to move all four of a player's pieces around the board and into the home area, following a designated path, before the other players do.</p>
       
     <p>Each player starts with their pieces in the "start" area corresponding to their color. Players take turns rolling a single die, and the number rolled determines how far they can move their pieces along the track. A player must roll a 6 to move a piece out of their starting area and onto the board. If a player rolls a 6, they get another turn, which can be a strategic advantage.</p>

     <p>The board features a central area known as the home column, which is where pieces must be moved to after completing a full rotation around the board. To win, a player must get all four of their pieces into the home area before any of their opponents.</p>

     <p>A key element of the game is the ability to land on an opponent's piece, sending it back to its starting position. This adds an element of strategy, as players must decide whether to advance quickly or wait to avoid being sent back.</p>

     <p>Ludo is a game of chance and strategy, requiring players to plan their moves carefully while also relying on the luck of the die roll. Its simple rules make it easy to learn, while the competitive nature ensures that it remains engaging and fun for players of all ages.</p>

     </div>
   </div>
   {/* col end */}


 </div>
 {/* row end */}
</div>
{/* container end */}
</section>
{/* accommodation end section */}

 </>
 
  )
}

export default Ludo;

import { Link } from "react-router-dom";

const Destinationwedding = () =>{
    return(
        <>
        {/* pagte titile section start  */}
<section className="page_title_mkp_section page_title_mkp_section3">
  <div className="page_ti_overly_mkp_div">
    <div className="container">
      <div className="row">
        {/* col start */}
        <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
          <div className="page_title_tax_mkp_main_div">
            <div className="page_title_tax_mkp_div">
              <h1>Destination Wedding</h1>
              <ul>
                <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
                <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/>  Destination Wedding</li>
              </ul>
            </div>
          </div>
        </div>
        {/* col end */}
      </div>
    </div>
  </div>
</section>
{/* pagte titile section end */}


{/* accommodation start section */}
<section className="accommodation_section">
  {/* container start */}
  <div className="container">
    {/* row start */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_img_box_main">
          <img src="../images/wedding.avif" alt="accommodation" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

        {/* col start */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_text_box_main">
          <h1>Destination Wedding</h1>
          <p>A destination wedding is a wedding that takes place away from the couple's hometown, often in a beautiful or exotic location. These weddings are typically held in picturesque locations such as beaches, resorts, mountains, or historic cities. Couples often choose destination weddings for a variety of reasons, including the desire for a more intimate ceremony, stunning photography opportunities, or the chance to combine their wedding with a vacation.</p>

          <h2>Benefits of a Destination Wedding: </h2>
          <p><b>Scenic Locations:</b> Destination weddings offer stunning backdrops, whether it’s a beach, garden, or a scenic mountain view.</p>
          <p><b>Intimate Celebration:</b> With fewer guests, destination weddings tend to have a more intimate atmosphere.</p>
         <p><b>Vacation Vibe:</b> The wedding can double as a vacation for both the couple and their guests. </p>
         <p><b>Simplified Planning:</b> Many resorts offer wedding packages that include everything from the ceremony to the reception, which simplifies planning. </p>
         <p><b>Memorable Experience:</b> A destination wedding can provide a unique and unforgettable experience for both the couple and their guests. </p>
        </div>
      </div>
      {/* col end */}

      

    </div>
    {/* row end */}
  </div>
  {/* container end */}
</section>
{/* accommodation end section */}

        </>
    )
}

export default Destinationwedding;
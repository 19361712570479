import React from 'react'
import { Link } from 'react-router-dom';

const Pooltable = () => {
  return (
    <>
    {/* pagte titile section start  */}
<section className="page_title_mkp_section page_title_mkp_section3">
<div className="page_ti_overly_mkp_div">
 <div className="container">
   <div className="row">
     {/* col start */}
     <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
       <div className="page_title_tax_mkp_main_div">
         <div className="page_title_tax_mkp_div">
           <h1>Pool Table</h1>
           <ul>
             <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
             <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/> Pool Table</li>
           </ul>
         </div>
       </div>
     </div>
     {/* col end */}
   </div>
 </div>
</div>
</section>
{/* pagte titile section end */}


{/* accommodation start section */}
<section className="accommodation_section">
{/* container start */}
<div className="container">
 {/* row start */}
 <div className="row">
   {/* col start */}
   <div className="col-lg-6 col-md-12 col-sm-12 col-12">
     <div className="accommodation_img_box_main">
       <img src="../images/pooltable.jpg" alt="accommodation" className="img-fluid"/>
     </div>
   </div>
   {/* col end */}

     {/* col start */}
     <div className="col-lg-6 col-md-12 col-sm-12 col-12">
     <div className="accommodation_text_box_main">
       <h1>Pool Table</h1>
      <p>A pool table is a specialized piece of furniture designed for the game of pool, also known as billiards. It consists of a rectangular frame with a smooth, flat playing surface typically covered in felt or cloth. The table features six pockets—one at each corner and one at the center of each long side—into which players aim to sink the balls. The surface is usually made from durable materials such as slate, ensuring a level and stable playing field.</p>

      <p>Pool tables come in various sizes, with the most common being 7 feet, 8 feet, and 9 feet in length. A standard set of pool balls includes 15 colored balls (numbered 1 through 15) and a white cue ball. The game is played with a cue stick, which is used to strike the cue ball, sending it into the other balls to sink them into the pockets.</p>

      <p>Pool tables are designed for different types of pool games, such as eight-ball, nine-ball, and straight pool. In eight-ball, players attempt to sink all their designated balls (stripes or solids) and then the 8-ball. In nine-ball, the goal is to sink the balls in numerical order, with the 9-ball being the final ball. Straight pool involves sinking as many balls as possible to reach a predetermined score.</p>

      <p>Pool tables are often found in homes, game rooms, bars, and recreation centers, offering a space for both recreational and competitive play. The table's size, quality, and design can vary, with some featuring intricate woodwork and premium cloth for professional play.</p>
     </div>
   </div>
   {/* col end */}


 </div>
 {/* row end */}
</div>
{/* container end */}
</section>
{/* accommodation end section */}

 </>
 
  )
}

export default Pooltable;

import React from 'react'
import { Link } from 'react-router-dom';

const Presentialroom = () => {
  return (
    <>
    {/* pagte titile section start  */}
<section className="page_title_mkp_section page_title_mkp_section3">
 <div className="page_ti_overly_mkp_div">
   <div className="container">
     <div className="row">
       {/* col start */}
       <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
         <div className="page_title_tax_mkp_main_div">
           <div className="page_title_tax_mkp_div">
             <h1>Presential Rooms</h1>
             <ul>
               <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
               <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/>  Presential Rooms</li>
             </ul>
           </div>
         </div>
       </div>
       {/* col end */}
     </div>
   </div>
 </div>
</section>
{/* pagte titile section end */}


{/* roominner start section */}
<section className="roominner_section">
{/* container start */}
<div className="container">
  {/* row start */}
  <div className="row">
    {/* col start */}
    <div className="col-lg-5 col-md-6 col-sm-12 col-12">
      <div className="roominner_img_box_main">
        <img src="../images/room03.jpg" alt="roominner" className="img-fluid"/>
      </div>
    </div>
    {/* col end */}

      {/* col start */}
      <div className="col-lg-7 col-md-6 col-sm-12 col-12">
      <div className="roominner_table_box_main">
      <table className="table table-striped table-bordered table-responsive">
          <thead>
            <tr>
              <th colSpan={3}>Presential Rooms</th>
            </tr>
          </thead>
          <tbody>

            <tr>
            <th>Presential Room (Exc. GST)</th>
              <td>Rs. 9,000/-</td>
               <td><Link to="/contact-us">Book Now</Link></td>
              
            </tr>


            
           
          </tbody>
        </table>
      </div>

<div className="accommodation_text_box_main">
 <p>Presidential Rooms are the most luxurious and exclusive accommodations in a hotel, designed to offer a supreme level of comfort, privacy, and high-end amenities. These rooms are typically spacious and elegantly furnished, with multiple living areas, including a large bedroom, a separate sitting or dining area, and sometimes even a private office.</p>
 <p>Presidential rooms often feature top-tier amenities such as a king-sized bed with premium linens, luxury bathrooms with separate showers and bathtubs, high-end toiletries, and advanced technology such as flat-screen TVs, sound systems, and smart controls for lighting and temperature. Many presidential suites also come with additional perks, including access to private lounges, personal butler service, and exclusive concierge services. These rooms are often designed for both relaxation and entertainment, featuring stunning views, lavish decor, and high-end finishes to ensure a royal experience for guests.</p>
</div>

    </div>
    {/* col end */}

    

  </div>
  {/* row end */}
</div>
{/* container end */}
</section>
{/* roominner end section */}


  </>
  )
}

export default Presentialroom;

import React from 'react'
import "../cssfiles/Footer.css";
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <>
<footer className="footer">
  <div className="footer_overlay">
    <div className="container">
      <div className="row">
        {/* col start */}
        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
          <div className="footer_about_div">
            <div className="footer_logo_div">
              <a href="index"><img src="../logo/logo.png" alt className="img-fluid" /></a>
            </div>
            <p>Welcome to the most beautiful stay in Darien Resort the essence of the name is collected from the Old Persian roots. With the help of our resort and rest, you can find hospitality and the most excellent guide when you ensure your stay in the wilderness of the Corbett National Park. <a href="">Read More</a></p>
           
          </div>
        </div>
        {/* col end */}
        {/* col start */}
        <div className="col-lg-2 col-md-6 col-sm-12 col-12">
          <div className="footer_headti">
            <h5>Quick Links</h5>
            <ul>
              <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/>  Home</Link></li>
              <li><Link to="/about-us"> <img src="../icons/aboutus.png" alt="home" className='img-fluid headericonimg'/>  About Us</Link></li>
            
              <li><Link to="/river-side"> <img src="../icons/safari.png" alt="home" className='img-fluid headericonimg'/>  Safari</Link></li>
              <li><Link to="/contact-us"> <img src="../icons/contactus.png" alt="home" className='img-fluid headericonimg'/>  Contact Us</Link></li>
            </ul>
          </div>
        </div>
        {/* col end */}
        {/* col start */}
        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
          <div className="footer_headti">
            <h5>Events</h5>
            <ul>
            <li><Link to="/destination-wedding"> <img src="../icons/destination.png" alt="home" className='img-fluid headericonimg'/> Destination Wedding</Link></li>
            <li><Link to="/corporate-meeting-events"> <img src="../icons/corporate.png" alt="home" className='img-fluid headericonimg'/> Corporate Meeting or Events</Link></li>
            <li><Link to="/pool-party"> <img src="../icons/pooparty.png" alt="home" className='img-fluid headericonimg'/> Pool Party</Link></li>
            <li><Link to="/college-group-events"> <img src="../icons/groupevent.png" alt="home" className='img-fluid headericonimg'/> College Group Events</Link></li>

            </ul>
          </div>
        </div>
        {/* col end */}
        {/* col start */}
        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
          <div className="footer_headti">
            <h5>Contact Us</h5>
            {/* media div start */}
            <div className="d-flex">
              <div className="flex-shrink-0">
                <i className="bx bx-map" />
              </div>
              <div className="flex-grow-1 ms-2">
                <div className="footer_num_div">
                  <p>Jim Corbett National Park, Ramnagar - Nainital Road Bailparao, Mankanth Pur, Ramnagar, Uttarakhand - 263140</p>
                </div>
              </div>
            </div>
            {/* media div end */}
            {/* media div start */}
            <div className="d-flex">
              <div className="flex-shrink-0">
                <i className="bx bxs-phone-call" />
              </div>
              <div className="flex-grow-1 ms-2">
                <div className="footer_num_div">
                  <a href="tel: +91 9999845322">  +91-9999845322</a>
                </div>
              </div>
            </div>
            {/* media div end */}
            {/* media div start */}
            <div className="d-flex">
              <div className="flex-shrink-0">
                <i className="bx bx-envelope-open" />
              </div>
              <div className="flex-grow-1 ms-2">
                <div className="footer_num_div">
                  <a href="mailto:info@thedarienresort.com">  info@thedarienresort.com</a>
                </div>
              </div>
            </div>
            {/* media div end */}
            {/* social icon div */}
            <div className="footer_icon_div">
              <b>Follow Us:</b>
              <a href="https://www.facebook.com/share/19hiC7WPjz/?mibextid=qi2Omg" className="bx bxl-facebook" target='_blank'/>
              <a href="https://www.instagram.com/thedarienresort?igsh=YzljYTk1ODg3Zg==" className="bx bxl-instagram" target='_blank'/>
              <a href="https://youtube.com/@thedarienresort?si=0MJwGBGPN5aIGThc" class="bx bxl-youtube" target='_blank'></a>
            </div>
            {/* social icon div  end*/}
          </div>
        </div>
        {/* col end */}
      </div>
    </div>
  </div>
 
</footer>

{/* ===================================copyright section start================= */}
<section className="copyright_section">
  <div className="container">
    <div className="row">
      {/* col start  */}
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <p>Copyright © 2024 Darien Resort. All Right Reserved.</p>
      </div>
      {/* col end */}
    </div>
  </div>
</section>

    </>
  )
}

export default Footer

import React from 'react'
import { Link } from 'react-router-dom';

const Neemkarolibaba = () => {
  return (
    <>
          {/* pagte titile section start  */}
   <section className="page_title_mkp_section page_title_mkp_section3">
     <div className="page_ti_overly_mkp_div">
       <div className="container">
         <div className="row">
           {/* col start */}
           <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
             <div className="page_title_tax_mkp_main_div">
               <div className="page_title_tax_mkp_div">
                 <h1>Neem Karoli Baba</h1>
                 <ul>
                   <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
                   <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/> Neem Karoli Baba</li>
                 </ul>
               </div>
             </div>
           </div>
           {/* col end */}
         </div>
       </div>
     </div>
   </section>
   {/* pagte titile section end */}
   
   
   {/* accommodation start section */}
   <section className="accommodation_section">
     {/* container start */}
     <div className="container">
       {/* row start */}
       <div className="row">
         {/* col start */}
         <div className="col-lg-6 col-md-12 col-sm-12 col-12">
           <div className="accommodation_img_box_main">
             <img src="../images/neemkaroli01.png" alt="accommodation" className="img-fluid"/>
           </div>
         </div>
         {/* col end */}
   
           {/* col start */}
           <div className="col-lg-6 col-md-12 col-sm-12 col-12">
           <div className="accommodation_text_box_main">
             <h1>Neem Karoli Baba</h1>
             <p>Neem Karoli Baba, also known as Maharaj-ji, was a revered Indian saint and spiritual leader, who gained widespread recognition both in India and the West. He is most famous for his simple yet profound teachings on love, service, and devotion to God, which transcended religious boundaries.</p>
   
            <p>Neem Karoli Baba was born in the late 19th century (around 1900) in the village of Akbarpur in Uttar Pradesh, India, though details about his birth and early life are somewhat unclear. He became widely known for his deep compassion, miraculous abilities, and his focus on devotion (bhakti) towards God. His followers considered him an incarnation of Lord Hanuman, and he had a unique way of guiding people to the path of love and selfless service, often through his actions and simple words.</p>

            <p>One of the most well-known aspects of Neem Karoli Baba’s legacy is his connection to the West. </p>
   
           </div>
         </div>
         {/* col end */}
   
         
           {/* col start */}
           <div className="col-lg-6 col-md-12 col-sm-12 col-12">
           <div className="accommodation_text_box_main">
        
        <p>In the 1960s, a few American spiritual seekers, including the famous spiritual teacher Ram Dass (born Richard Alpert), visited him in India and were deeply influenced by his wisdom. Ram Dass, in his book Be Here Now, described his transformative experiences with Neem Karoli Baba, which helped bring Baba's teachings to the Western world. His followers, both in India and abroad, view him as a spiritual guide and mentor who transcended the traditional boundaries of time, space, and culture.</p>

        <p>Neem Karoli Baba did not leave behind written texts or formal teachings; instead, his presence and actions spoke volumes to his followers. Many of his devotees recall him as a loving and mystical figure who would often perform miracles, like appearing in different places at the same time or providing comfort and insight when needed.</p>

        <p>His ashrams are located in various places, the most famous being in Kainchi, near Nainital in Uttarakhand, which remains a popular pilgrimage site for his followers. The Kainchi Dham ashram is especially significant because of its serene atmosphere and the fact that Neem Karoli Baba spent a considerable amount of time there, offering blessings to visitors.</p>
           </div>
         </div>
         {/* col end */}
   
         {/* col start */}
         <div className="col-lg-6 col-md-12 col-sm-12 col-12">
           <div className="accommodation_img_box_main">
             <img src="../images/neemkaroli02.jpg" alt="accommodation" className="img-fluid"/>
           </div>
         </div>
         {/* col end */}
   
       </div>
       {/* row end */}
     </div>
     {/* container end */}
   </section>
   {/* accommodation end section */}
    
       </>
  )
}

export default Neemkarolibaba;

import React from 'react'
import { Link } from 'react-router-dom';

const Superiorroom = () => {
  return (
    <>
      {/* pagte titile section start  */}
 <section className="page_title_mkp_section page_title_mkp_section3">
   <div className="page_ti_overly_mkp_div">
     <div className="container">
       <div className="row">
         {/* col start */}
         <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
           <div className="page_title_tax_mkp_main_div">
             <div className="page_title_tax_mkp_div">
               <h1>Superior Rooms</h1>
               <ul>
                 <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
                 <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/>  Superior Rooms</li>
               </ul>
             </div>
           </div>
         </div>
         {/* col end */}
       </div>
     </div>
   </div>
 </section>
 {/* pagte titile section end */}


{/* roominner start section */}
<section className="roominner_section">
  {/* container start */}
  <div className="container">
    {/* row start */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-5 col-md-6 col-sm-12 col-12">
        <div className="roominner_img_box_main">
          <img src="../images/room02.jpg" alt="roominner" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

        {/* col start */}
        <div className="col-lg-7 col-md-6 col-sm-12 col-12">
        <div className="roominner_table_box_main">
        <table className="table table-striped table-bordered table-responsive">
            <thead>
              <tr>
                <th colSpan={3}>Superior Rooms</th>
              </tr>
            </thead>
            <tbody>

              <tr>
              <th>Superior Room (Exc. GST)</th>
                <td>Rs. 7,000/-</td>
                <td><Link to="/contact-us">Book Now</Link></td>
              </tr>


              
             
            </tbody>
          </table>
        </div>

<div className="accommodation_text_box_main">
    <p>Superior Rooms are enhanced hotel accommodations that offer greater comfort and upgraded amenities compared to standard rooms. These rooms typically feature more spacious layouts, larger beds, better views, and additional in-room amenities such as upgraded linens, premium toiletries, and a seating area. They may also include conveniences like a minibar, flat-screen TV, and complimentary Wi-Fi. Superior rooms provide a more comfortable and stylish experience for guests, making them ideal for travelers seeking extra comfort without the cost of premium suites.</p>
</div>

      </div>
      {/* col end */}

      

    </div>
    {/* row end */}
  </div>
  {/* container end */}
</section>
{/* roominner end section */}


    </>
  )
}

export default Superiorroom;

import { Link } from "react-router-dom";
import "../cssfiles/Contactus.css";

const Contactus = () => {
    return(
        <>
{/* pagte titile section start  */}
<section className="page_title_mkp_section">
  <div className="page_ti_overly_mkp_div">
    <div className="container">
      <div className="row">
        {/* col start */}
        <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
          <div className="page_title_tax_mkp_main_div">
            <div className="page_title_tax_mkp_div">
              <h1>Contact Us</h1>
              <ul>
                <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
                <li><img src="../icons/contactus.png" alt="home" className='img-fluid headericonimg'/>  Contact Us</li>
              </ul>
            </div>
          </div>
        </div>
        {/* col end */}
      </div>
    </div>
  </div>
</section>
{/* pagte titile section end */}
<section className="contact_us_page_section">
	<div className="container">
		<div className="row justify-content-center">
			<div className="col-lg-5 col-md-6 col-sm-12 col-12">
				<div className="main_div_contactus_page">
					<h1>Get in touch with us</h1>
					<p>
						If you have any further questions or queries please do not hesitate to get in touch.
					</p>

					<div className="contact_us_flx_div_main">
						<div className="d-flex">
							<div className="flex-shrink-0">
								<img src="../icons/location.svg" alt="location" className="img-fluid" />
							</div>
							<div className="flex-grow-1 ms-3">
								<div className="contact_page_num_div">
									<b>Address</b>
									<p>Jim Corbett National Park, Ramnagar - Nainital Road Bailparao, Mankanth Pur, Ramnagar, Uttarakhand - 263140</p>
								</div>
							</div>
						</div>
					</div>

					<div className="contact_us_flx_div_main">
						<div className="d-flex">
							<div className="flex-shrink-0">
								<img src="../icons/email.svg" alt="location" className="img-fluid" />
							</div>
							<div className="flex-grow-1 ms-3">
								<div className="contact_page_num_div">
									<b>Email Address</b>
									<a href="mailto:info@thedarienresort.com"> info@thedarienresort.com </a>
								</div>
							</div>
						</div>
					</div>

					<div className="contact_us_flx_div_main">
						<div className="d-flex">
							<div className="flex-shrink-0">
								<img src="../icons/call.svg" alt="location" className="img-fluid" />
							</div>
							<div className="flex-grow-1 ms-3">
								<div className="contact_page_num_div">
									<b>mobile Number</b>
									<a href="tel:+91 9999845322"> +91-9999845322</a>
								</div>
							</div>
						</div>
					</div>

					<div>
					<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3477.7718231702597!2d79.2244416!3d29.347682200000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390a6d76fa5b2a61%3A0x4e2176c92601caa2!2sThe%20Darien%20Resort!5e0!3m2!1sen!2sin!4v1733310353204!5m2!1sen!2sin" width="100%" height="180" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
					</div>
					
				</div>
			</div>

			<div className="col-lg-5 col-md-6 col-sm-12 col-12">
				<div className="contact_page_form">
					<h2>Feel free to connect</h2>
					<form method="POST" className="contact-form" onSubmit="{handleSubmit}" autocomplete="off">
						<div className="row">
							<div className="col-lg-12 col-md-12 col-sm-12 col-12">
								<label htmlFor="">Full Name</label>
								<div className="input-group">
									<input type="text" name="name" className="form-control" placeholder="Enter your full name*" required />
								</div>
							</div>
							<div className="col-lg-12 col-md-12 col-sm-12 col-12">
								<label htmlFor="">Email</label>
								<div className="input-group">
									<input type="email" className="form-control" name="email" placeholder="Enter your email*" required/>
								</div>
							</div>

							<div className="col-lg-12 col-md-12 col-sm-12 col-12">
								<label htmlFor="">Mobile</label>
								<div className="input-group">
									<input type="tel" className="form-control" minlength="{10}" maxlength="{10}" name="mobile" placeholder="Enter your mobile number*" required/>
								</div>
							</div>

							<div className="col-lg-12 col-md-12 col-sm-12 col-12">
								<label htmlFor="">Subject</label>
								<div className="input-group">
									<input type="text" className="form-control" name="subject" placeholder="Enter a subject*" required/>
								</div>
							</div>
							<div className="col-lg-12 col-md-12 col-sm-12 col-12">
								<label htmlFor="">Message</label>
								<div className="input-group">
									<textarea name="message" className="form-control" placeholder="Enter your message here*" required/>
								</div>
							</div>
							<div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-2">
								<div className="conatct_page_btn_div d-grid">
									<button type="submit" className="btn btn-md">
										Send Message
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		<br />
		<div className="col-lg-12"></div>
	</div>
</section>


        </>
    )
}

export default Contactus;
import React from 'react'
import { Link } from 'react-router-dom';

const Corbettmuseum = () => {
  return (
    <>
       {/* pagte titile section start  */}
<section className="page_title_mkp_section page_title_mkp_section3">
  <div className="page_ti_overly_mkp_div">
    <div className="container">
      <div className="row">
        {/* col start */}
        <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
          <div className="page_title_tax_mkp_main_div">
            <div className="page_title_tax_mkp_div">
              <h1>Corbett Museum</h1>
              <ul>
                <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
                <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/> Corbett Museum</li>
              </ul>
            </div>
          </div>
        </div>
        {/* col end */}
      </div>
    </div>
  </div>
</section>
{/* pagte titile section end */}


{/* accommodation start section */}
<section className="accommodation_section">
  {/* container start */}
  <div className="container">
    {/* row start */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_img_box_main">
          <img src="../images/corbett01.avif" alt="accommodation" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

        {/* col start */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_text_box_main">
          <h1>Corbett Museum</h1>
          <p>Corbett Museum is a prominent museum located in Kaladhungi, a small town near Jim Corbett National Park in Uttarakhand, India. The museum is dedicated to Jim Corbett, one of India’s most famous naturalists, conservationists, and wildlife photographers, and it provides a deep insight into his life and work. Jim Corbett is widely known for his role in establishing the Jim Corbett National Park, the first national park in India, which was later renamed in his honor. The museum is a must-visit destination for wildlife enthusiasts, nature lovers, and anyone interested in the history of conservation in India.</p>

          <h2>Key Features of Corbett Museum:          </h2>
          <p><b>1. Historical Significance:</b></p>
          <ul>
          <li>The museum is housed in Jim Corbett’s former residence, which is located in Kaladhungi, where Corbett spent his later years. The house has been preserved as a museum to honor his contributions to wildlife conservation and his deep connection to the region's flora and fauna.  </li>
         
          
          </ul>

        </div>
      </div>
      {/* col end */}

      
        {/* col start */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_text_box_main">
     
       
       <ul>
       <li>Jim Corbett is famous for his work in the Indian jungle and his efforts to protect wildlife, particularly the tiger. He was also known for his books, such as "Man-Eaters of Kumaon" and "Jungle Lore," which documented his adventures and experiences in the wild.  </li>
       </ul>

     <p><b>2. Artifacts and Exhibits:</b></p>
     <ul>
      <li>The museum showcases various artifacts related to Jim Corbett's life and work. Visitors can find photographs, personal belongings, hunting equipment, and books written by Corbett. The exhibits give a glimpse into his life as a hunter, conservationist, and photographer.      </li>
      <li>There are tiger skins and other preserved animal parts on display, but these are primarily there to reflect Corbett’s role in tracking and capturing man-eaters, as well as to illustrate his knowledge of the local wildlife.      </li>
      <li>The museum also includes exhibits on local wildlife, with information about the species found in Jim Corbett National Park, such as tigers, leopards, elephants, and various bird species.      </li>
     </ul>
        </div>
      </div>
      {/* col end */}

      {/* col start */}
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_img_box_main">
          <img src="../images/corbett02.jpg" alt="accommodation" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

    </div>
    {/* row end */}
  </div>
  {/* container end */}
</section>
{/* accommodation end section */}
 
    </>
  )
}

export default Corbettmuseum;

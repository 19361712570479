import React from 'react'
import { Link } from 'react-router-dom'

const Bhimtal = () => {
  return (
    <>
        {/* pagte titile section start  */}
<section className="page_title_mkp_section page_title_mkp_section3">
  <div className="page_ti_overly_mkp_div">
    <div className="container">
      <div className="row">
        {/* col start */}
        <div className="col-xxl-12 colxl-12 col-lg-12 col-sm-12 col-12">
          <div className="page_title_tax_mkp_main_div">
            <div className="page_title_tax_mkp_div">
              <h1>Bhimtal</h1>
              <ul>
                <li><Link to="/"> <img src="../icons/home.png" alt="home" className='img-fluid headericonimg'/> Home</Link></li>
                <li><img src="../icons/sericon01.png" alt="home" className='img-fluid headericonimg'/> Bhimtal</li>
              </ul>
            </div>
          </div>
        </div>
        {/* col end */}
      </div>
    </div>
  </div>
</section>
{/* pagte titile section end */}


{/* accommodation start section */}
<section className="accommodation_section">
  {/* container start */}
  <div className="container">
    {/* row start */}
    <div className="row">
      {/* col start */}
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_img_box_main">
          <img src="../images/bhimtal01.webp" alt="accommodation" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

        {/* col start */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_text_box_main">
          <h1>Bhimtal</h1>
          <p>Bhimtal is a charming and serene town located in the Nainital district of Uttarakhand, India. It is situated around 22 kilometers from the popular hill station of Nainital and is known for its peaceful atmosphere, scenic landscapes, and a large, beautiful lake. The town is named after Bhim, one of the Pandava brothers from the epic Mahabharata, who is said to have visited this place.</p>

          <h2>Key Attractions of Bhimtal:          </h2>
          <p><b>1. Bhimtal Lake:</b></p>
          <ul>
           <li>Bhimtal Lake is the main attraction of the town and is larger and less commercialized compared to Nainital’s lake. The lake is surrounded by lush greenery and hills, offering a tranquil and scenic environment.    </li>
           <li>Visitors can enjoy boating on the lake, either by paddle boats or rowboats. A small island in the center of the lake has a stone temple dedicated to Lord Shiva and is accessible by boat, making for a unique boating experience.           </li>
          
          </ul>

        </div>
      </div>
      {/* col end */}

      
        {/* col start */}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_text_box_main">
     
       <p>2. Bhimtal Island and Temple:</p>
       <ul>
        <li>Situated in the middle of Bhimtal Lake, the island is a peaceful and secluded spot, home to an ancient temple dedicated to Lord Shiva. The temple adds a spiritual touch to the area, and visiting the island is a popular activity for tourists coming to Bhimtal.        </li>
       </ul>

       <p><b>3. Victoria Dam:</b></p>
       <ul>
        <li>The Victoria Dam is located near the Bhimtal Lake and is a beautiful structure that controls the water flow to the lake. It was built during the British era, and its surroundings are peaceful, offering picturesque views of the lake and nearby hills.</li>
        <li>The dam area is perfect for a leisure walk, photography, or a quiet time enjoying the natural beauty.        </li>
       </ul>

       <p><b>4. Hidimba Parvat:</b></p>
       <ul>
        <li>A small hill near Bhimtal, Hidimba Parvat offers great panoramic views of the town, the lake, and the surrounding valleys. The hill is named after Hidimba, a character from the Mahabharata. The place is ideal for trekking, nature walks, and photography.
        </li>
       </ul>
        </div>
      </div>
      {/* col end */}

      {/* col start */}
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="accommodation_img_box_main">
          <img src="../images/bhimtal02.webp" alt="accommodation" className="img-fluid"/>
        </div>
      </div>
      {/* col end */}

    </div>
    {/* row end */}
  </div>
  {/* container end */}
</section>
{/* accommodation end section */}

    </>
  )
}

export default Bhimtal
